import { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Comment } from '@dots/uikit'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { OrderBox } from '@entities/order'
import {
  Dialog,
  Form,
  FormSelectControl,
  FormSwitchControl,
  FormTextareaControl,
  MessageBox,
  Stack,
} from '@share/components/ui'

import { useOrderCancelForm } from '../../../hooks'
import { ORDER_CANCEL_MODAL_ID, OrderCancelDialogProps } from '../../../libs'
import { OrderActionDialog } from '../../molecules'

export const OrderCancelDialog = NiceModal.create<OrderCancelDialogProps>(
  ({ orderName, orderBreadcrumbs, cancelReasons, onSubmit, loading, lastComment }) => {
    const { t } = useTranslation()
    const modalMethods = useModal(ORDER_CANCEL_MODAL_ID)

    const { control, handleSubmit } = useOrderCancelForm({ cancelReasons })
    const cancelReason = useWatch({ control, name: 'cancelReason' })

    const SlotFooter = useMemo(
      () => (
        <Dialog.Footer
          buttonOkProps={{
            children: t('orders.modals.cancel.actions.submit'),
            onClick: handleSubmit(onSubmit),
            loading,
          }}
        />
      ),
      [t, loading, handleSubmit, onSubmit],
    )

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.cancel.title')}
        onOpenChange={modalMethods.remove}
        withNoScroll
        slotFooter={SlotFooter}
        slotMessage={
          <MessageBox type='error' title={t('orders.modals.cancel.message-box.title')} />
        }
        slotSubheader={<OrderBox title={orderName} breadcrumbs={orderBreadcrumbs} />}
      >
        <Form.Root style={{ minHeight: '200px' }}>
          <Stack gap={16}>
            <FormSelectControl
              label={t('orders.modals.cancel.reason')}
              placeholder={t('orders.modals.cancel.reason-placeholder')}
              control={control}
              name='cancelReason'
              list={cancelReasons}
            />

            {cancelReason === 'OTHER' && (
              <FormTextareaControl
                control={control}
                minRows={3}
                maxRows={5}
                name='cancelDescription'
                label={t('orders.modals.cancel.description')}
                placeholder={t('orders.modals.cancel.description-placeholder')}
              />
            )}
            {lastComment && (
              <>
                <FormSwitchControl
                  control={control}
                  name='comment.keepPrevious'
                  text='Keep previous order comment'
                />
                <Comment
                  type='info'
                  title={lastComment.title}
                  content={lastComment.content}
                  slotAction={
                    <Button size='small' variant='transparent'>
                      Learn more
                    </Button>
                  }
                />
              </>
            )}
          </Stack>
        </Form.Root>
      </OrderActionDialog>
    )
  },
)
