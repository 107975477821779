import { NotDefinable } from '@dots/uikit'
import HtmlReactParser, { HTMLReactParserOptions } from 'html-react-parser'

export const asHtml = (html: string, options?: HTMLReactParserOptions) =>
  HtmlReactParser(html, options) as string | JSX.Element

export const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const asString = (value: unknown): string => {
  if (typeof value === 'string') return value
  if (typeof value === 'number') return value.toString()

  return ''
}

export const stringFuse = <S extends string = string, P extends React.ReactNode = string>(
  string: NotDefinable<S>,
  placeholder: P = '-' as P,
): S | P => string || placeholder
