import { FC } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export const RouterError: FC = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <div>You have found a secret place.</div>
        <div color='dimmed'>{error.statusText}</div>
      </>
    )
  }

  return (
    <>
      <div>Oops! Page not found.</div>
      <div color='dimmed'>Sorry the route you are looking for does not exist.</div>
    </>
  )
}
