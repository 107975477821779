import * as yup from 'yup'

import { companyStaffModelSchema } from '../company'
import { countryModelSchema, stateModelSchema } from '../country'
import { cityModelSchema } from '../country/city.schemas'
import { officeCompanyModelSchema } from '../office'
import { warehouseModelSchema } from '../warehouse'

import { possibleAssigneeTypeSchema } from './delivery-info.schemas'

const deliveryCountryModelSchema = countryModelSchema
  .pick(['id', 'name', 'flag', 'alphaCode2'])
  .defined()

export const deliveryOrderPickupInfoSchema = yup.object().shape({
  id: yup.string().defined(),
  office: officeCompanyModelSchema.pick(['id', 'name', 'address', 'image']).defined().nullable(),
  warehouse: warehouseModelSchema.pick(['id', 'name']).defined().nullable(),
  staff: companyStaffModelSchema.pick(['id', 'fullName', 'avatar', 'address']).defined().nullable(),
  address: yup.object().shape({
    addressLine1: yup.string().defined(),
    addressLine2: yup.string().defined().nullable(),
    city: cityModelSchema.pick(['id', 'name']).defined().nullable(),
    state: stateModelSchema.pick(['id', 'name']).defined().nullable(),
    zipCode: yup.string().defined(),
    country: deliveryCountryModelSchema.defined(),
  }),
  contactInformation: yup.object().shape({
    staff: companyStaffModelSchema
      .pick(['id', 'fullName', 'avatar', 'address'])
      .defined()
      .nullable(),
    fullName: yup.string().defined(),
    email: yup.string().defined(),
    privateEmail: yup.string().defined().nullable(),
    phone: yup.string().defined(),
    identifier: yup.string().nullable().defined(),
  }),
  notes: yup.string().defined().nullable(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  actualPickUpDate: yup
    .tuple([yup.string().defined(), yup.string().optional()])
    .nullable()
    .defined(),
  assigneeType: possibleAssigneeTypeSchema.defined(),
})

export type DeliveryOrderPickupInfoModel = yup.InferType<typeof deliveryOrderPickupInfoSchema>

export const deliveryPickupInfoRequestSchema = yup.object({
  addressLine1: yup.string().defined(),
  addressLine2: yup.string().defined().nullable(),
  city: yup.string().defined(),
  state: yup.string().defined().nullable(),
  zipCode: yup.string().defined(),
  contactInformation: yup
    .object()
    .shape({
      fullName: yup.string().defined(),
      email: yup.string().email().defined(),
      privateEmail: yup.string().email().defined().nullable(),
      phone: yup.string().defined(),
    })
    .nullable()
    .defined(),
})

export type DeliveryPickupInfoRequest = yup.InferType<typeof deliveryPickupInfoRequestSchema>
