import { array, InferType, mixed, number, object, string } from 'yup'

export const possibleOrderStatusLogStages = ['completed', 'pending', 'current', 'refused'] as const

export type OrdersStatusLogStage = (typeof possibleOrderStatusLogStages)[number]

export const orderStatusLogModelSchema = object({
  id: string().nullable().defined(),
  index: number().optional(),
  orderId: string().defined(),
  status: string().defined(),
  stage: mixed<OrdersStatusLogStage>().defined().oneOf(possibleOrderStatusLogStages),
  changedBy: string().nullable().defined(),
  createdAt: string().nullable().defined(),
  updatedAt: string().nullable().defined(),
})

export const orderStatusesLogModelSchema = array().of(orderStatusLogModelSchema)

export type OrderStatusLogModel = InferType<typeof orderStatusLogModelSchema>
export type OrderStatusesLogModel = InferType<typeof orderStatusesLogModelSchema>
