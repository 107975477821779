import { forwardRef } from 'react'
import { Stack } from '@share/components/ui/atoms'
import cn from 'classnames'

import { FileUpload, FileUploadProps } from '../../molecules'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface FileFieldProps extends FileUploadProps {
  className?: string
  inline?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
}

export const FileField = forwardRef<HTMLInputElement, FileFieldProps>(
  (
    {
      messageType = 'error',
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      className,
      invalid,
      disabled,
      ...dropzoneProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.errored]: invalid,
        [s.inline]: inline,
        [s.disabled]: disabled,
      },
      className,
    )

    return (
      <div className={rootClassName}>
        {label && <TextFieldLabel label={label} supportingText={supportingText} />}

        <Stack gap={6} className={s.container}>
          <FileUpload ref={ref} invalid={invalid} disabled={disabled} {...dropzoneProps} />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      </div>
    )
  },
)

FileField.displayName = 'FileField'
