import * as yup from 'yup'

import { assetSpecsModelSchema } from '../asset'
import { brandModelSchema } from '../brand'

import { productCategoryModelSchema } from './product-category.schemas'

export const productIceCatModelSchema = yup.object({
  category: productCategoryModelSchema.defined(),
  image: yup.string().defined(),
  model: yup.string().defined(),
  name: yup.string().defined(),
  price: yup.number().defined(),
  productCode: yup.string().defined(),
  source: yup.string().defined(),
  brand: brandModelSchema.nullable().defined(),
  brandId: yup.string().nullable().defined(),
  specs: assetSpecsModelSchema.defined(),
  subcategoryId: yup.string().defined(),
})

export type ProductIceCatModel = yup.InferType<typeof productIceCatModelSchema>

export const productIceCatParsedModelSchema = yup.object({
  description: yup.array(yup.string().defined()).defined(),
  parsed: productIceCatModelSchema.defined(),
})

export type ProductIceCatParsedModel = yup.InferType<typeof productIceCatParsedModelSchema>
