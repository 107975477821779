import { useCallback, useMemo, useState } from 'react'

export const useImageErrorState = () => {
  const [imageError, setImageError] = useState(false)

  const handleErrorImageState = useCallback(() => {
    setImageError(true)
  }, [])

  return useMemo(
    () => ({
      imageError,
      handleErrorImageState,
    }),
    [handleErrorImageState, imageError],
  )
}
