import { boolean, InferType, object, string } from 'yup'

export const orderCommentModelSchema = object({
  comment: object({
    title: string().optional(),
    content: string().optional(),
    keepPrevious: boolean().defined(),
    notifyClient: boolean().defined().optional(),
  }),
})

export type OrderCommentModel = InferType<typeof orderCommentModelSchema>
