import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowDownIcon from '@assets/icons/arrow-down.svg'
import LogoutIcon from '@assets/icons/logout.svg'
import { DropdownMenu, DropdownMenuProps, Icon } from '@share/components/ui'
import { useLogout } from '@share/hooks'

import s from './user-action-menu.module.scss'

export const UserActionMenu: React.FC = () => {
  const { t } = useTranslation()
  const [onLogout] = useLogout()

  const items: DropdownMenuProps['items'] = useMemo(
    () => [
      {
        type: 'button',
        label: t('sidebar.logout'),
        icon: LogoutIcon,
        onClick: onLogout,
      },
    ],
    [onLogout, t],
  )

  return (
    <DropdownMenu
      items={items}
      align='end'
      sideOffset={18}
      collisionPadding={50}
      trigger={
        <button className={s.button}>
          <Icon className={s.icon} icon={ArrowDownIcon} />
        </button>
      }
    />
  )
}
