import * as yup from 'yup'

const possibleDraftDeliverySteps = [
  'PICK_UP',
  'ASSETS',
  'DELIVERY',
  'SHIPPING',
  'BOXES',
  'PAYMENT_AND_BILLING',
  'CONFIRMATION',
] as const

export type DraftDeliverySteps = (typeof possibleDraftDeliverySteps)[number]

export const draftDeliveryStepsSchema = yup
  .mixed<DraftDeliverySteps>()
  .oneOf(possibleDraftDeliverySteps)
