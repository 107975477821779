import * as yup from 'yup'

import {
  cityModelSchema,
  countryModelSchema,
  regionModelSchema,
  stateModelSchema,
} from '../country'

import { companyStaffRoleModelSchema } from './company-staff-role.schemas'
import { companyStaffSourcesSchema } from './company-staff-sources.schemas'
import { companyStaffStatusesSchema } from './company-staff-statuses.schemas'
import { companyStaffTeamModelSchema } from './company-staff-team.schemas'

const companyStaffCountryModel = countryModelSchema.omit(['minDeliveryDate'])

export const companyStaffModelSchema = yup
  .object()
  .shape({
    fullName: yup.string().defined(),
    email: yup.string().defined(),
    phone: yup.string().defined().nullable(),
    address: yup.string().defined(),
    addressLine2: yup.string().defined(),
    lat: yup.number().defined().nullable(),
    long: yup.number().defined().nullable(),
    postalCode: yup.string().nullable().defined(),
    companyId: yup.string().defined(),
    activeAffectedDate: yup.string().nullable().defined(),
    avatar: yup.string().defined().nullable(),
    customRole: yup.string().nullable().defined(),
    staffSource: companyStaffSourcesSchema.defined(),
    assets: yup.array().defined(),
    country: companyStaffCountryModel.defined().nullable(),
    state: stateModelSchema.defined().nullable(),
    zipCode: yup.string().defined().nullable(),
    countryId: yup.string().defined().nullable(),
    city: cityModelSchema.nullable().defined(),
    cityId: yup.string().nullable().defined(),
    region: regionModelSchema.nullable().defined(),
    role: companyStaffRoleModelSchema.defined().nullable(),
    roleId: yup.string().nullable().defined(),
    team: companyStaffTeamModelSchema.nullable().defined(),
    assetsCount: yup.number().defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),
    deletedAt: yup.string().nullable().defined(),
    status: companyStaffStatusesSchema,
    id: yup.string().defined(),
    privateEmail: yup.string().defined().nullable(),
  })
  .noUnknown()

export const companyMutationStaffModelSchema = companyStaffModelSchema.omit(['country', 'city'])

export const companyStaffsModelSchema = yup.array().of(companyStaffModelSchema).defined()

export type CompanyStaffModel = yup.InferType<typeof companyStaffModelSchema>
export type CompanyStaffsModel = yup.InferType<typeof companyStaffsModelSchema>
export type CompanyStaffCountryModel = yup.InferType<typeof companyStaffCountryModel>
export type CompanyMutationStaffModel = yup.InferType<typeof companyMutationStaffModelSchema>
