import * as yup from 'yup'

import { companiesSchema } from '../company'
import { countryModelSchema } from '../country'

export const warehouseModelSchema = yup.object({
  id: yup.string().defined(),
  contactPersonName: yup.string().defined(),
  contactPersonEmail: yup.string().defined(),
  contactPersonPhone: yup.string().defined(),
  name: yup.string().defined(),
  country: countryModelSchema.defined(),
  countryId: yup.string().defined(),
  companies: companiesSchema.defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
})

export const warehousesModelSchema = yup.array().of(warehouseModelSchema).defined()

export type WarehouseModel = yup.InferType<typeof warehouseModelSchema>
export type WarehousesModel = yup.InferType<typeof warehousesModelSchema>
