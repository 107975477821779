import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import type { DraftDeliveryModel } from '@share/schemas'
import { draftDeliverySchema } from '@share/schemas'
import { emptyStringToNull } from '@share/utils'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const invoiceNonAssetSchema = yup.object({
  ids: yup.array().of(yup.string().defined()),
  name: yup.string().label('Name').defined().required(),
  price: yup
    .number()
    .label('Price')
    .positive()
    .min(1)
    .defined()
    .required()
    .typeError('Price must be a valid number and greater than or equal to 1'),
  quantity: yup.number().label('Quantity').positive().min(1).defined().required(),
  subcategoryId: yup.string().label('Subcategory').defined().required(),
})

const invoiceAssetItemSchema = yup.object({
  id: yup.string().defined(),
  price: yup.number().when((_, schema, options) => {
    const { deliveryOrderType, deliveryType } = options.context

    if (deliveryOrderType === 'INTERNATIONAL' || deliveryType === 'STOCKING') {
      return schema
        .positive()
        .min(1)
        .defined()
        .required()
        .typeError('Price must be a valid number and greater than or equal to 1')
    }

    return schema.notRequired()
  }),
  serialNumber: yup
    .string()
    .when((_, schema, options) => {
      const { deliveryType } = options.context
      const { serialNumberEditable } = options.parent

      if (deliveryType === 'STOCKING' && serialNumberEditable) {
        return schema.defined().required()
      }

      return schema.notRequired()
    })
    .transform(emptyStringToNull)
    .nullable(),
  assetId: yup.string().defined(),
})

export const deliveryInvoiceItemsRequestSchema = yup.object({
  invoiceAssetItems: yup
    .array()
    .of(invoiceAssetItemSchema)
    .required()
    .test(
      'at-least-one',
      'Must provide at least one of invoiceAssetItems or invoiceNonAssetItems',
      (invoiceAssetItems, context) => {
        const { invoiceNonAssetItems } = context.parent
        return invoiceAssetItems.length > 0 || invoiceNonAssetItems.length > 0
      },
    ),
  invoiceNonAssetItems: yup
    .array(invoiceNonAssetSchema)
    .required()
    .test(
      'at-least-one',
      'Must provide at least one of invoiceAssetItems or invoiceNonAssetItems',
      (invoiceNonAssetItems, context) => {
        const { invoiceAssetItems } = context.parent
        return invoiceNonAssetItems.length > 0 || invoiceAssetItems.length > 0
      },
    ),
})

export type UpdateDeliveryInvoiceInfoRequest = yup.InferType<
  typeof deliveryInvoiceItemsRequestSchema
>

const updateDeliveryInvoiceInfoRequest = ({
  id,
  data,
}: {
  id: string
  data: UpdateDeliveryInvoiceInfoRequest
}) =>
  apiRequest<DraftDeliveryModel>({
    method: 'PATCH',
    url: API_LIST.order.delivery.slug.invoiceInfo(id),
    data,
    responseSchema: draftDeliverySchema,
  })

export const useUpdateDeliveryOrderInvoiceInfo = () => useMutation(updateDeliveryInvoiceInfoRequest)
