import type { GroupProps } from '@dots/uikit'
import { Group } from '@dots/uikit'
import cn from 'classnames'

import s from './root.module.scss'

export interface RootProps extends GroupProps {
  reversed?: boolean
  withBorder?: boolean
  disabled?: boolean
}

export const Root: React.FC<RootProps> = ({
  className,
  reversed,
  children,
  withBorder,
  disabled,
  gap = 8,
  ...rest
}) => (
  <Group
    gap={gap}
    align='middle'
    className={cn(
      s.root,
      { [s.reversed]: reversed, [s.bordered]: withBorder, [s.disabled]: disabled },
      className,
    )}
    {...rest}
  >
    {children}
  </Group>
)
