import { AssetsStatuses } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { assetModelSchema, assetStatusSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

interface GetAssetStockRequest {
  dpn: string
  locationId?: string
  status?: AssetsStatuses
}

const responseSchema = yup.object({
  assetName: yup.string().defined(),
  locationName: yup.string().defined(),
  statuses: yup.array().of(assetStatusSchema.defined()).defined(),
  totalCount: yup.number().defined(),
  result: yup.array().of(assetModelSchema.defined()).defined(),
})

type GetAssetStockResponse = yup.InferType<typeof responseSchema>

const getAssetStockRequest = (params: GetAssetStockRequest) =>
  apiRequest<GetAssetStockResponse>({
    method: 'GET',
    url: API_LIST.asset.assetStock,
    params,
    responseSchema,
  })

export const useGetAssetStock = (params: GetAssetStockRequest) =>
  useQuery<GetAssetStockResponse, Error>({
    queryKey: [API_TAGS.assetStock, params],
    queryFn: () => getAssetStockRequest(params),
    keepPreviousData: true,
  })
