import CheckboxEmptyIcon from '@assets/icons/checkbox-round-empty.svg'
import CheckmarkFilledIcon from '@assets/icons/checkmark-filled.svg'
import CloseFilledIcon from '@assets/icons/close-filled.svg'
import DotSmIcon from '@assets/icons/dot-sm.svg'
import { Nullable } from '@dots/uikit'
import { OrderModel } from '@share/@types'
import cn from 'classnames'

import { Group, Icon, Typography } from '../../atoms'

import s from './stepline.module.scss'

const Icons = {
  completed: CheckmarkFilledIcon,
  pending: DotSmIcon,
  refused: CloseFilledIcon,
  current: CheckboxEmptyIcon,
}

export interface StepLineItemProps {
  label: string
  caption: string
  changedBy: Nullable<string>
  status: OrderModel['statusLog'][number]['stage']
}

export const StepLineItem: React.FC<StepLineItemProps> = ({
  label,
  status = 'pending',
  caption,
  changedBy,
}) => (
  <Group className={cn(s.root, [s[status]])} gap={12} position='apart'>
    <Icon icon={Icons[status] ?? Icons.pending} className={s.icon} />
    <Typography variant='b3' className={s.label}>
      {label}
    </Typography>
    <Typography variant='b3' className={s.caption}>
      {changedBy && `${changedBy}, `}
      {caption}
    </Typography>
  </Group>
)
