import { PaginatedResponse, PartialPaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { officeModelSchema, paginationResponseSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import { InferType } from 'yup'

type GetCompanyOfficeLocationsRequest = PartialPaginationState & {
  country?: string
  companyId: string
  withAssets?: boolean
}
type GetCompanyOfficeLocationsResponse = PaginatedResponse<CompanyOfficeModel>

const responseSchema = paginationResponseSchema(officeModelSchema)
export type CompanyOfficeModel = InferType<typeof officeModelSchema>

const getCompanyOfficeLocationsRequest = async (params?: GetCompanyOfficeLocationsRequest) =>
  apiRequest<GetCompanyOfficeLocationsResponse>({
    url: API_LIST.company.officeLocations,
    params,
    responseSchema,
  })

export const useGetCompanyOfficeLocations = (params?: GetCompanyOfficeLocationsRequest) =>
  useQuery<GetCompanyOfficeLocationsResponse, Error>({
    queryKey: [API_TAGS.companyOfficeLocations, params],
    queryFn: () => getCompanyOfficeLocationsRequest(params),
    keepPreviousData: true,
  })
