import { API_LIST } from '@share/constants'
import { post } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'

export type UploadDPNFileDataRequest = FormData
export type UploadDPNFileDataResponse = { file: string }

export const uploadDPNFileRequest = async (requestData: UploadDPNFileDataRequest) => {
  const { data } = await post<UploadDPNFileDataResponse>(API_LIST.dpnFile, requestData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  return data
}

export const useUploadDPNFile = () => useMutation(uploadDPNFileRequest)
