import * as yup from 'yup'

import { assetStatusSchema } from './asset-status.schemas'

export const assetStockStatusModelSchema = yup.object({
  status: assetStatusSchema.defined(),
  value: yup.number().defined(),
  percentage: yup.number().defined(),
})

export const assetStockStatusesModelSchema = yup.array().of(assetStockStatusModelSchema.defined())

export type AssetStockStatusModel = yup.InferType<typeof assetStockStatusModelSchema>
export type AssetStockStatusesModel = yup.InferType<typeof assetStockStatusesModelSchema>
