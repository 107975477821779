import EmployeeIcon from '@assets/icons/large/employee.svg'

import { Icon } from '../icon/icon'

import s from './avatar.module.scss'

export const AvatarFallback: React.FC = () => (
  <div className={s.fallback}>
    <Icon size='large' icon={EmployeeIcon} />
  </div>
)
