import { Categories } from '@share/@types'
import { AssetPreview } from '@share/components/ui'

interface ProductPreviewProps {
  image: string
  category?: Categories | 'grouped'
}

export const ProductPreview: React.FC<ProductPreviewProps> = ({ image, category }) => (
  <AssetPreview image={image} category={category} />
)
