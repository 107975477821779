import * as yup from 'yup'

import { assetModelSchema } from '../asset/asset.schemas'
import { categoryModelSchema } from '../categories/category.schemas'
import { subcategoryModelSchema } from '../categories/subcategory.schemas'

const possibleDeliveryInvoiceItemType = ['ASSET', 'NON_ASSET'] as const
export type DeliveryInvoiceItemType = (typeof possibleDeliveryInvoiceItemType)[number]

const possibleDeliveryInvoiceItemTypeSchema = yup
  .mixed<DeliveryInvoiceItemType>()
  .oneOf(possibleDeliveryInvoiceItemType)

export const deliveryOrderInvoiceItemSchema = yup.object().shape({
  id: yup.string().defined(),
  assetId: yup.string().defined().nullable(),
  name: yup.string().defined(),
  price: yup.number().defined(),
  productCode: yup.string().defined().nullable(),
  serialNumber: yup.string().defined().nullable(),
  type: possibleDeliveryInvoiceItemTypeSchema.defined(),
  image: yup.string().defined().nullable(),
  category: categoryModelSchema.defined(),
  subcategory: subcategoryModelSchema.defined(),
})

export const deliveryOrderInvoiceItemsSchema = yup
  .array()
  .of(deliveryOrderInvoiceItemSchema.defined())
  .defined()

export type DeliveryOrderInvoiceItemModel = yup.InferType<typeof deliveryOrderInvoiceItemSchema>
export type DeliveryOrderInvoiceItemsModel = yup.InferType<typeof deliveryOrderInvoiceItemsSchema>
export const deliveryOrderInvoiceAssetSchema = assetModelSchema
  .pick([
    'id',
    'image',
    'name',
    'category',
    'subcategory',
    'condition',
    'hasIssues',
    'activeOrder',
    'price',
    'serialNumber',
  ])
  .concat(
    yup.object().shape({
      assetId: yup.string().defined(),
      serialNumberEditable: yup.boolean().defined(),
    }),
  )
export const deliveryOrderInvoiceAssetsSchema = yup
  .array()
  .of(deliveryOrderInvoiceAssetSchema.defined())
  .defined()

export type DeliveryOrderInvoiceAssetModel = yup.InferType<typeof deliveryOrderInvoiceAssetSchema>
export type DeliveryOrderInvoiceAssetsModel = yup.InferType<typeof deliveryOrderInvoiceAssetsSchema>

export const deliveryOrderInvoiceNonAssetSchema = yup.object().shape({
  ids: yup.array().of(yup.string().defined()).defined(),
  name: yup.string().defined(),
  price: yup.number().defined(),
  quantity: yup.number().defined(),
  subcategory: subcategoryModelSchema.defined(),
})

export const deliveryOrderInvoiceNonAssetsSchema = yup
  .array()
  .of(deliveryOrderInvoiceNonAssetSchema.defined())
  .defined()

export type DeliveryOrderInvoiceNonAssetModel = yup.InferType<
  typeof deliveryOrderInvoiceNonAssetSchema
>
export type DeliveryOrderInvoiceNonAssetsModel = yup.InferType<
  typeof deliveryOrderInvoiceNonAssetsSchema
>
