import * as yup from 'yup'

export const assetsFilterModelSchema = yup
  .object({
    specDetailId: yup.string().defined().nullable(),
    name: yup.string().defined(),
    outputName: yup.string().defined(),
    valueType: yup.mixed<string | number>().defined().nullable(),
    unitOfMeasure: yup.string().defined().nullable(),
    specDetailValues: yup.array(yup.string().defined()).defined(),
  })
  .noUnknown()

export const assetsFiltersModelSchema = yup.array().of(assetsFilterModelSchema.defined()).defined()

export type AssetsFilterModel = yup.InferType<typeof assetsFilterModelSchema>
export type AssetsFiltersModel = yup.InferType<typeof assetsFiltersModelSchema>
