import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { Group } from '../group/group'
import { Icon, IconProps } from '../icon/icon'
import { Typography } from '../typography'

import s from './label.module.scss'

export interface LabelProps extends Children, ClassName {
  required?: boolean
  disabled?: boolean
  icon?: IconProps['icon']
}

export const Label: React.FC<LabelProps> = ({ children, className, icon, required, disabled }) => (
  <Group gap={8}>
    {icon && <Icon icon={icon} size='medium' />}
    <Typography
      variant='c3'
      component='label'
      className={cn(s.root, { [s.required]: required, [s.disabled]: disabled }, className)}
    >
      {children}
    </Typography>
  </Group>
)
