import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const purchaseDeliverInfoRequestSchema = yup
  .object({
    delivery: yup
      .object({
        addressLine1: yup.string().defined(),
        addressLine2: yup.string().defined().optional().nullable(),
        city: yup.string().defined(),
        state: yup.string().optional().nullable(),
        zipCode: yup.string().defined(),
      })
      .defined(),
    contactInformation: yup
      .object({
        fullName: yup.string().defined(),
        email: yup.string().defined(),
        privateEmail: yup.string().defined().nullable(),
        phone: yup.string().defined(),
      })
      .defined(),
  })
  .defined()
  .noUnknown()

export type PurchaseDeliveryInfoRequest = yup.InferType<typeof purchaseDeliverInfoRequestSchema>

export interface UpdatePurchaseOrderDeliveryRequest {
  id: string
  requestData: PurchaseDeliveryInfoRequest
}

const updatePurchaseOrderDeliveryInfoRequest = ({
  id,
  requestData,
}: UpdatePurchaseOrderDeliveryRequest) =>
  apiRequest({
    method: 'PATCH',
    url: API_LIST.order.purchase.slug.updateDelivery(id),
    data: requestData,
  })

export const useUpdatePurchaseOrderDeliveryInfoRequest = () =>
  useMutation(updatePurchaseOrderDeliveryInfoRequest)
