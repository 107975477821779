import React, { useCallback, useState } from 'react'
import AlertIcon from '@assets/icons/alert.svg'
import AlertTriangleIcon from '@assets/icons/alert-triangle.svg'
import CloseIcon from '@assets/icons/close.svg'
import InfoGeneralIcon from '@assets/icons/info-general.svg'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { ActionIcon, Group, Icon, IconHolder, Stack, Typography } from '../../atoms'

import s from './message-box.module.scss'

const ICONS = {
  default: InfoGeneralIcon,
  info: InfoGeneralIcon,
  warn: AlertTriangleIcon,
  error: AlertIcon,
}

interface InfoMessageBoxProps extends ClassName {
  title: string
  message?: string
  type?: 'default' | 'info' | 'warn' | 'error'
  link?: React.ReactNode
  topSeparator?: React.ReactNode
  bottomSeparator?: React.ReactNode
}

export const MessageBox: React.FC<InfoMessageBoxProps> = ({
  title,
  message,
  type = 'default',
  className,
  topSeparator,
  bottomSeparator,
}) => {
  const [showMessage, setShowMessage] = useState<boolean>(true)

  const onMessageClose = useCallback(() => {
    setShowMessage(false)
  }, [])

  return (
    <>
      {showMessage && (
        <Stack>
          {topSeparator}
          <Group position='apart' className={cn(s.root, { [s[type]]: type }, className)}>
            <Group gap={12} align='middle' className={s.message}>
              <IconHolder size='medium' className={s.icon}>
                <Icon icon={ICONS[type]} />
              </IconHolder>

              <Stack gap={2}>
                <Typography variant='b2'>{title}</Typography>
                {message && (
                  <Typography variant='b3' className='fcSecondary'>
                    {message}
                  </Typography>
                )}
              </Stack>
            </Group>

            <ActionIcon variant='transparent' icon={CloseIcon} onClick={onMessageClose} />
          </Group>
          {bottomSeparator}
        </Stack>
      )}
    </>
  )
}
