import i18n from '@assets/locales/i18n'
import type { IconProps } from '@dots/uikit'
import MalfunctionItemIcon from '@dots/uikit/icons/malfunction-item.svg'
import NewItemIcon from '@dots/uikit/icons/new-item.svg'
import RefurbishedItemIcon from '@dots/uikit/icons/refurbished-item.svg'

import type { AssetStateTooltipsMap } from './types'

export const MAX_ALLOWED_TITLE_SYMBOLS = 100

export const ASSET_STATE_ICONS: Record<string, IconProps['icon']> = {
  NEW: NewItemIcon,
  REFURBISHED: RefurbishedItemIcon,
  MALFUNCTIONING: MalfunctionItemIcon,
}

export const ASSET_STATE_TOOLTIPS: AssetStateTooltipsMap = {
  NEW: {
    title: i18n.t('asset.tooltips.new.title'),
    content: i18n.t('asset.tooltips.new.content'),
  },
  REFURBISHED: {
    title: i18n.t('asset.tooltips.refurbished.title'),
    content: i18n.t('asset.tooltips.refurbished.content'),
  },
  MALFUNCTIONING: {
    title: i18n.t('asset.tooltips.malfunctioning.title'),
    content: i18n.t('asset.tooltips.malfunctioning.content'),
  },
}
