export const DIALOG_ASSIGN_TO_ID = 'dialog-assign-to'
export const DIALOG_REASSIGN_TO_ID = 'dialog-reassign-to'
export const DIALOG_LOCATION_CHANGE_ID = 'dialog-location-change'
export const DIALOG_ASSET_DISTRIBUTE_ID = 'dialog-asset-distribute'
export const DIALOG_ASSET_MOVE_TO_STORAGE_ID = 'dialog-asset-move-to-storage'
export const DIALOG_ASSET_ARCHIVE_ID = 'dialog-asset-archive'
export const DIALOG_ONBOARDING_ID = 'dialog-onboarding'
export const DIALOG_DISCARD_CHANGES_ID = 'dialog-discard-changes'

export const DIALOG_CONFIRM_DELETE_LOCATION_ID = 'dialog-confirm-delete-location'
export const DIALOG_CONFIRM_DELETE_EMPLOYEE_ID = 'dialog-confirm-delete-employee'

export const CHANGE_DETAILS_MODAL = 'change-details-modal'
export const BAMBOO_CONNECT_MODAL = 'bamboo-connect-modal'

export const ORDER_EDIT_ALERT_MODAL_ID = 'order-edit-alert-modal'
export const CONFIRM_EVENT_MODAL_ID = 'confirm-event-modal'
export const DELETE_EVENT_MODAL_ID = 'delete-event-modal'
