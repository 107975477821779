import * as yup from 'yup'

import { officeCompanySpaceModelSchema, officeModelSchema } from '../office'

export const spaceModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  officeId: yup.string().defined(),
  office: officeModelSchema.optional(),
  assetCount: yup.number().defined(),
  image: yup.string().defined().nullable(),
})

export const spacesModelSchema = yup.array(spaceModelSchema.defined()).defined()

export type SpaceModel = yup.InferType<typeof spaceModelSchema>
export type SpacesModel = yup.InferType<typeof spacesModelSchema>

export const spaceGetCompanyOfficeModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  officeId: yup.string().defined(),
  image: yup.string().defined().nullable(),
  office: officeCompanySpaceModelSchema.defined(),
  deletedAt: yup.string().defined().nullable(),
})

export const spaceCompanyOfficeModelSchema = spaceModelSchema.omit(['office', 'assetCount'])

export type SpaceCompanyOfficeModel = yup.InferType<typeof spaceCompanyOfficeModelSchema>
export type SpaceGetCompanyOfficeModel = yup.InferType<typeof spaceGetCompanyOfficeModelSchema>
