import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { create, useModal } from '@ebay/nice-modal-react'
import { Dialog, Form, FormTextareaControl, Stack } from '@share/components/ui'

import { useOrderNotesEditForm } from '../../../hooks'
import { ORDER_NOTES_EDIT_DIALOG_ID, OrderNotesEditDialogProps } from '../../../libs'
import { OrderActionDialog } from '../../molecules'

export const OrderNotesEditDialog = create<OrderNotesEditDialogProps>(
  ({ formValues, onSubmit, loading }) => {
    const { t } = useTranslation()
    const modalMethods = useModal(ORDER_NOTES_EDIT_DIALOG_ID)

    const { control, formState, handleSubmit } = useOrderNotesEditForm(formValues)

    const SlotFooter = useMemo(
      () => (
        <Dialog.Footer
          buttonOkProps={{
            children: t('orders.modals.order-notes.actions.submit'),
            onClick: handleSubmit(onSubmit),
            disabled: !formState.isDirty,
            loading,
          }}
        />
      ),
      [t, formState.isDirty, loading, handleSubmit, onSubmit],
    )

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.order-notes.title')}
        subTitle={t('orders.modals.order-notes.subtitle')}
        onOpenChange={modalMethods.remove}
        slotFooter={SlotFooter}
      >
        <Form.Root style={{ minHeight: '200px' }}>
          <Stack gap={16}>
            <FormTextareaControl
              control={control}
              minRows={5}
              maxRows={8}
              name='notes'
              label={t('orders.modals.order-notes.notes.label')}
              placeholder={t('orders.modals.order-notes.notes.placeholder')}
            />
          </Stack>
        </Form.Root>
      </OrderActionDialog>
    )
  },
)
