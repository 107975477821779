import { forwardRef } from 'react'
import { RadioGroupProps, Root } from '@radix-ui/react-radio-group'
import cn from 'classnames'

import { RadioButtonLabel, RadioButtonLabelProps } from './radio-button'

import s from './radio-group.module.scss'

export interface IRadioGroupProps extends Omit<RadioGroupProps, 'children' | 'asChild'> {
  className?: string
  component?: React.ElementType
  list: Array<RadioButtonLabelProps>
}

export const RadioGroup = forwardRef<HTMLDivElement, IRadioGroupProps>(
  ({ className, list, component: Component = RadioButtonLabel, ...rest }, ref) => (
    <Root className={cn(s.root, className)} {...rest} ref={ref}>
      {list.map((item) => (
        <Component key={item.value} {...item} />
      ))}
    </Root>
  ),
)

RadioGroup.displayName = 'RadioGroup'
