import { useCallback } from 'react'
import { NiceModalHandler } from '@ebay/nice-modal-react'
import { orderCommentValidationSchema } from '@entities/order/order-comment-form'
import {
  UpdateOrderActualEtaRequest,
  useCreateOrderComment,
  useUpdateOrderActualEta,
} from '@share/hooks/api'

import { useUpdateOrderActualEtaForm } from '../hooks/use-update-order-actual-eta-form'
import { adaptOrderActualEtaFormValuesToRequest, OrderActualEtaFormValues } from '../libs'

interface UseUpdateOrderActualEtaController {
  orderId: string
  modalMethods: NiceModalHandler
  onAfterUpdate: () => Promise<void>
}

export const useUpdateOrderActualEtaController = ({
  orderId,
  modalMethods,
  onAfterUpdate,
}: UseUpdateOrderActualEtaController) => {
  const updateOrderActualEta = useUpdateOrderActualEta()
  const createOrderComment = useCreateOrderComment()
  const updateOrderActualEtaFormMethods = useUpdateOrderActualEtaForm(orderCommentValidationSchema)

  const onUpdateOrderActualEta = useCallback(
    async (formValues: OrderActualEtaFormValues) => {
      const requestData: UpdateOrderActualEtaRequest = {
        id: orderId,
        ...adaptOrderActualEtaFormValuesToRequest(formValues),
      }
      const { comment } = formValues

      if (comment?.title && comment.content) {
        await createOrderComment.mutateAsync({
          orderId,
          title: comment.title,
          content: comment.content,
          notifyClient: comment.notifyClient,
        })
      }

      return updateOrderActualEta
        .mutateAsync(requestData)
        .then(modalMethods.remove)
        .then(onAfterUpdate)
    },
    [createOrderComment, modalMethods.remove, onAfterUpdate, orderId, updateOrderActualEta],
  )

  return {
    updateOrderActualEtaFormMethods,
    onUpdateOrderActualEta: updateOrderActualEtaFormMethods.handleSubmit(onUpdateOrderActualEta),
    loading: updateOrderActualEta.isLoading || createOrderComment.isLoading,
  }
}
