import * as yup from 'yup'

import { deliveryOrderCommercialOfferModelSchema } from './delivery-commercial-offer.schemas'

export const deliveryOrderTableSchema = yup
  .object({
    commercialOffer: deliveryOrderCommercialOfferModelSchema.nullable().defined(),
  })
  .nullable()
  .defined()
