import * as yup from 'yup'

export const productFilterModelSchema = yup.object({
  specDetailId: yup.string().defined(),
  outputName: yup.string().defined(),
  name: yup.string().defined(),
  valueType: yup.string().defined(),
  unitOfMeasure: yup.string().defined(),
  specDetailValues: yup.array(yup.string().defined()).defined(),
})

export const productFiltersModelSchema = yup.array(productFilterModelSchema).defined()

export type ProductFilterModel = yup.InferType<typeof productFilterModelSchema>

export type ProductFiltersModel = yup.InferType<typeof productFiltersModelSchema>
