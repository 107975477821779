import { PartialPaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import {
  OrderStatus,
  OrderStatuses,
  paginationResponseSchema,
  tableOrderModelSchema,
} from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import { InferType } from 'yup'

interface GetOrdersTableRequest extends PartialPaginationState {
  search?: string
  sortBy?: string
  sortOrder?: string
  status?: OrderStatus | OrderStatuses
}

const responseSchema = paginationResponseSchema(tableOrderModelSchema)
type GetOrdersTableResponse = InferType<typeof responseSchema>

const getOrdersTable = (params?: GetOrdersTableRequest) =>
  apiRequest<GetOrdersTableResponse>({
    method: 'GET',
    url: API_LIST.order.root.table,
    params,
    responseSchema,
  })

export const useGetOrdersTable = (params?: GetOrdersTableRequest) =>
  useQuery<GetOrdersTableResponse, Error>({
    queryKey: [API_TAGS.ORDERS.root.table, params],
    queryFn: () => getOrdersTable(params),
    keepPreviousData: true,
  })

export const invalidateOrdersTable = () =>
  queryClient.invalidateQueries([API_TAGS.ORDERS.root.table])
