import { LoadingOverlay, Show } from '@dots/uikit'
import { Dialog } from '@share/components/ui'

interface OrderActionDialogProps extends Dialog.HeaderProps {
  open: boolean
  withNoScroll?: boolean
  size?: Dialog.ContentProps['size']
  slotFooter: React.ReactNode
  slotMessage?: React.ReactNode
  slotSubheader?: React.ReactNode
  loading?: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const OrderActionDialog: React.FC<OrderActionDialogProps> = ({
  open,
  title,
  subTitle,
  children,
  slotMessage,
  slotFooter,
  slotSubheader,
  withNoScroll,
  loading,
  size,
  onOpenChange,
}) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Container>
      <Dialog.Header title={title} subTitle={subTitle}>
        {slotSubheader && <Dialog.Subheader>{slotSubheader}</Dialog.Subheader>}
      </Dialog.Header>

      <Dialog.Content withNoScroll={withNoScroll} size={size}>
        <Show when={loading}>
          <div className='relative' style={{ height: '12.5rem' }}>
            <LoadingOverlay variant='naked' />
          </div>
        </Show>
        <Show when={!loading}>
          <>{children}</>
        </Show>
      </Dialog.Content>

      {slotMessage && <Dialog.SubContent>{slotMessage}</Dialog.SubContent>}

      {slotFooter}
    </Dialog.Container>
  </Dialog.Root>
)
