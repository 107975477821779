import { useTranslation } from 'react-i18next'
import CompaniesIcon from '@assets/icons/company.svg'
import AlertIcon from '@dots/uikit/icons/alert.svg'
import AssetDetailsIcon from '@dots/uikit/icons/asset-details.svg'
import BrandsIcon from '@dots/uikit/icons/brand.svg'
import CatalogIcon from '@dots/uikit/icons/cart.svg'
import CategoriesIcon from '@dots/uikit/icons/category.svg'
import OrdersIcon from '@dots/uikit/icons/orders.svg'
import CompaniesAdminsIcon from '@dots/uikit/icons/space.svg'
import SubcategoriesIcon from '@dots/uikit/icons/subcategory.svg'
import VendorsIcon from '@dots/uikit/icons/vendor.svg'
import { NavigationLink } from '@share/components/ui'
import { ROUTES } from '@share/constants'

import { useSidebarStore } from '../../../model'
import { Area } from '../../atoms'

import s from './midbar.module.scss'

export const MidBar: React.FC = () => {
  const { t } = useTranslation()
  const { isCollapsed } = useSidebarStore()

  return (
    <div className={s.root}>
      <Area title={t('sidebar.area1.title')}>
        <NavigationLink
          to={ROUTES.ORDERS.root}
          label={t('sidebar.orders')}
          icon={OrdersIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.vendors}
          label={t('sidebar.vendors')}
          icon={VendorsIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.catalog}
          label={t('sidebar.catalog')}
          icon={CatalogIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.categories.root}
          label={t('sidebar.categories')}
          icon={CategoriesIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.subcategories.root}
          label={t('sidebar.subcategories')}
          icon={SubcategoriesIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.brands.root}
          label={t('sidebar.brands')}
          icon={BrandsIcon}
          collapsed={isCollapsed}
        />
      </Area>
      <Area title={t('sidebar.area2.title')}>
        <NavigationLink
          to={ROUTES.companies}
          label={t('sidebar.companies')}
          icon={CompaniesIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.companiesAdmins}
          label={t('sidebar.companiesAdmins')}
          icon={CompaniesAdminsIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.updates.root}
          label={t('sidebar.updates')}
          icon={AlertIcon}
          collapsed={isCollapsed}
        />
        <NavigationLink
          to={ROUTES.reports.root}
          label={t('sidebar.reports')}
          icon={AssetDetailsIcon}
          collapsed={isCollapsed}
        />
      </Area>
    </div>
  )
}
