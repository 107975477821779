import * as yup from 'yup'

import { attachmentV2Schema } from '../files'

import { deliveryOrderInvoiceItemsSchema } from './delivery-invoice.schemas'

const possibleMeasurementSystem = ['METRIC', 'IMPERIAL'] as const
type MeasurementSystem = (typeof possibleMeasurementSystem)[number]
export const measurementSystemSchema = yup
  .mixed<MeasurementSystem>()
  .defined()
  .oneOf(possibleMeasurementSystem)

export const possibleBoxesPackingTypes = ['DOTS_PACKING', 'SELF_PACKING'] as const
export type BoxesPackingType = (typeof possibleBoxesPackingTypes)[number]
export const possibleBoxesPackingTypesSchema = yup
  .mixed<BoxesPackingType>()
  .oneOf(possibleBoxesPackingTypes)

const batterySchema = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().defined(),
})

const deliveryBoxSizeSchema = yup.object().shape({
  depth: yup.string().required(),
  width: yup.string().required(),
  height: yup.string().required(),
})

const deliveryBoxSchema = yup.object().shape({
  id: yup.string().defined(),
  size: deliveryBoxSizeSchema.defined().required(),
  actualBoxWeight: yup.string().defined(),
  description: yup.string().defined().nullable(),
  volumetricWeight: yup.string().defined(),
  invoiceItems: deliveryOrderInvoiceItemsSchema.defined(),
  attachments: yup.array().of(attachmentV2Schema).defined(),
})

const deliveryBoxesSchema = yup.array().of(deliveryBoxSchema)

export const deliveryBoxConfigModelSchema = yup.object().shape({
  id: yup.string().defined(),
  type: possibleBoxesPackingTypesSchema.defined(),
  batteries: yup.array().of(batterySchema).defined(),
  noBatteries: yup.boolean().defined(),
  measurementSystem: measurementSystemSchema,
  boxes: deliveryBoxesSchema.defined(),
  note: yup.string().defined().nullable(),
  attachments: yup.array().of(attachmentV2Schema).defined(),
})

export type DeliveryBoxSizeModel = yup.InferType<typeof deliveryBoxSizeSchema>
export type DeliveryBoxModel = yup.InferType<typeof deliveryBoxSchema>
export type DeliveryBoxesModel = yup.InferType<typeof deliveryBoxesSchema>
export type DeliveryBoxConfigModel = yup.InferType<typeof deliveryBoxConfigModelSchema>
