import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormValues } from './types'

const validationSchema: yup.Schema<FormValues> = yup.object().shape({
  notes: yup.string().label('Order notes').required('This field is required'),
})

export const formConfig = {
  defaultValues: { notes: '' },
  resolver: yupResolver(validationSchema),
}
