import { forwardRef, useId } from 'react'
import { Typography } from '@dots/uikit'
import * as RadixSwitch from '@radix-ui/react-switch'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Group } from '../group/group'

import s from './switch.module.scss'

export interface SwitchProps
  extends ClassName,
    Pick<
      RadixSwitch.SwitchProps,
      'checked' | 'value' | 'defaultChecked' | 'onCheckedChange' | 'disabled' | 'name'
    > {
  text?: string
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ className = '', text, ...rest }, ref) => {
    const id = useId()

    return (
      <Group className={cn(s.root, className)} align='middle' gap={10}>
        <RadixSwitch.Root className={s.element} {...rest} ref={ref} id={id}>
          <RadixSwitch.Thumb className={s.thumb} />
        </RadixSwitch.Root>
        {text && (
          <label htmlFor={id} className='pointer'>
            <Typography component='p' variant='b2'>
              {text}
            </Typography>
          </label>
        )}
      </Group>
    )
  },
)

Switch.displayName = 'Switch'
