import { useTranslation } from 'react-i18next'
import TableIcon from '@assets/icons/table.svg'
import UploadIcon from '@assets/icons/upload.svg'

import { Button, Icon, IconProps, Stack, Typography } from '../../atoms'

export type DropzoneInitialProps = {
  loading?: boolean
  disabled?: boolean
  title: string
  subtitle?: string
  icon?: IconProps['icon']
  onClick: () => void
}

export const DropzoneInitial: React.FC<DropzoneInitialProps> = ({
  title,
  subtitle,
  loading,
  disabled,
  icon,
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <Stack gap={16} align='center'>
      <Icon icon={icon ?? TableIcon} />
      <Stack gap={6} align='center'>
        <Typography align='center' variant='h3'>
          {title}
        </Typography>
        <Typography align='center' variant='b2' className='fcSecondary'>
          {subtitle ?? t('dropzone.initial.subtitle')}
        </Typography>
      </Stack>
      <Button icon={UploadIcon} loading={loading} disabled={disabled} onClick={onClick}>
        {t('dropzone.initial.action')}
      </Button>
    </Stack>
  )
}
