import { useCallback } from 'react'
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseFieldArrayProps,
} from 'react-hook-form'
import { useController, useFieldArray } from 'react-hook-form'

import type { AttachmentsFieldProps } from '../fields'
import { AttachmentsField } from '../fields'

interface FormAttachmentsControlProps
  extends Omit<
    AttachmentsFieldProps,
    'invalid' | 'message' | 'ref' | 'items' | 'onRemove' | 'onAppend'
  > {
  onRemoveCallback?: () => void
}

export const FormAttachmentsControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  onRemoveCallback,
  ...rest
}: UseControllerProps<TFieldValues, TName> & FormAttachmentsControlProps) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: name as UseFieldArrayProps<TFieldValues>['name'],
  })

  const onRemoveMemoized = useCallback(() => {
    remove()

    if (onRemoveCallback) {
      onRemoveCallback()
    }
  }, [onRemoveCallback, remove])

  return (
    <AttachmentsField
      invalid={fieldState.invalid}
      message={fieldState.error?.message}
      items={fields as never}
      onAppend={append as never}
      onRemove={onRemoveMemoized}
      ref={field.ref}
      {...rest}
    />
  )
}
