import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { CompanyLegalEntitiesModel, companyLegalEntitiesSchema } from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

type GetCompanyLegalEntityRequest = {
  companyId: string
}

type GetCompanyLegalEntityResponse = {
  legalEntities: CompanyLegalEntitiesModel
}

const responseSchema = yup.object({
  legalEntities: companyLegalEntitiesSchema.defined(),
})

const getCompanyLegalEntityRequest = (params: GetCompanyLegalEntityRequest) =>
  apiRequest<GetCompanyLegalEntityResponse>({
    url: API_LIST.company.legalEntities.root,
    params,
    responseSchema,
  })

export const useGetCompanyLegalEntity = (
  params: GetCompanyLegalEntityRequest,
  options?: UseQueryOptions<GetCompanyLegalEntityResponse>,
) =>
  useQuery<GetCompanyLegalEntityResponse>({
    queryKey: [API_TAGS.company.legalEntities.root, params],
    queryFn: () => getCompanyLegalEntityRequest(params),
    ...options,
  })
