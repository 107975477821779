import * as yup from 'yup'

import { possibleStaffStatuses, StaffStatuses } from './staff-statuses.schemas'

export const staffModelSchema = yup.object({
  id: yup.string().defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  firstName: yup.string().defined(),
  lastName: yup.string().defined(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined(),
  status: yup.mixed<StaffStatuses>().oneOf(possibleStaffStatuses).defined(),
  email: yup.string().defined(),
  phone: yup.string().defined(),
  address: yup.string().defined(),
  notes: yup.string().defined(),
  companyPosition: yup.string().defined(),
  companyId: yup.string().defined(),
  deletedAt: yup.string().nullable().defined(),
})

export type StaffModel = yup.InferType<typeof staffModelSchema>
