import { useCallback } from 'react'
import { PaginatedResponse, PaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import {
  CompanyStaffModel,
  companyStaffModelSchema,
  paginationResponseSchema,
} from '@share/schemas'
import { useQuery } from '@tanstack/react-query'

interface GetCompanyStaffRequest extends Partial<PaginationState> {
  search?: string
  sort?: string
  sortDirection?: string
  country?: string
  withAssets?: boolean
  companyId: string
}

type GetCompanyStaffResponse = PaginatedResponse<CompanyStaffModel>

const responseSchema = paginationResponseSchema(companyStaffModelSchema.defined())

const getCompanyStaffRequest = (params?: GetCompanyStaffRequest) =>
  apiRequest<GetCompanyStaffResponse>({
    url: API_LIST.company.staff,
    params,
    responseSchema,
  })

export const useGetCompanyStaff = (params?: GetCompanyStaffRequest) =>
  useQuery<GetCompanyStaffResponse, Error>({
    queryKey: [API_TAGS.companyStaff, params],
    queryFn: () => getCompanyStaffRequest(params),
    keepPreviousData: true,
  })

export const useInvalidateCompanyStaff = () =>
  useCallback(() => queryClient.invalidateQueries([API_TAGS.companyStaff]), [])
