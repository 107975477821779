import { forwardRef } from 'react'
import cn from 'classnames'

import { Icon } from '../icon/icon'

import { Loading } from './loading'
import { BaseButtonProps } from './types'

import s from './button.module.scss'

export type ButtonProps = BaseButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, buttonRef) => {
  const {
    type = 'button',
    className,
    children,
    loading = false,
    disabled = false,
    fullWidth,
    size = 'medium',
    iconSize = 'medium',
    variant = 'filled',
    color = '',
    icon,
    iconPosition = 'before',
    ...rest
  } = props
  const rootClassName = cn(
    s.root,
    {
      [s[size]]: size,
      [s[variant]]: variant,
      [s[color]]: color,
      [s.fullWidth]: fullWidth,
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className,
  )

  return (
    <button
      ref={buttonRef}
      type={type}
      className={rootClassName}
      disabled={disabled || loading}
      {...rest}
    >
      <span className={s.inner}>
        {loading && <Loading />}

        {!loading && (
          <>
            {icon && iconPosition === 'before' && (
              <Icon size={iconSize} className={s.icon} icon={icon} />
            )}
            <span className={s.label}>{children}</span>
            {icon && iconPosition === 'after' && (
              <Icon size={iconSize} className={s.icon} icon={icon} />
            )}
          </>
        )}
      </span>
    </button>
  )
})

Button.displayName = 'Button'
