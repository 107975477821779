import cn from 'classnames'

import s from './dialog.module.scss'

interface ContentProps {
  children: React.ReactNode
  className?: string
  withNoScroll?: boolean
  size?: 'small' | 'medium' | 'large' | 'xlarge'
}

export const Content: React.FC<ContentProps> = ({
  children,
  withNoScroll,
  size = 'default',
  className,
}) => (
  <div className={cn(s.content, { [s.noScroll]: withNoScroll, [s[size]]: size }, className)}>
    {children}
  </div>
)
