import CloseIcon from '@assets/icons/close.svg'
import DownloadIcon from '@assets/icons/download.svg'
import ErrorIcon from '@assets/icons/error.svg'
import FileIcon from '@assets/icons/large/file.svg'
import FileDamagedIcon from '@assets/icons/large/file-damaged.svg'
import cn from 'classnames'

import { ActionIcon } from '../../action-icon'
import { Group } from '../../group'
import { Icon } from '../../icon'
import { Loader } from '../../loader'
import { Stack } from '../../stack'
import { Typography } from '../../typography'

import s from './attachment-file.module.scss'

export interface AttachmentFileProps {
  title: string
  subtitle?: string
  invalid?: boolean
  message?: string
  fileUrl?: string
  loading?: boolean
  preview?: boolean
  onRemove?: () => void
}

export const AttachmentFile: React.FC<AttachmentFileProps> = ({
  title,
  subtitle,
  message,
  invalid,
  fileUrl,
  loading,
  preview,
  onRemove,
}) => (
  <Group gap={16} className={cn(s.root, { [s.invalid]: invalid })} align='middle'>
    <div className={s.preview}>
      {loading && <Loader />}
      {!loading && <Icon size='large' icon={invalid ? FileDamagedIcon : FileIcon} />}
    </div>

    <Stack>
      <Typography variant='c2'>{title}</Typography>
      {subtitle && (
        <Typography variant='b3' className='fcSecondary'>
          {subtitle}
        </Typography>
      )}
      {message && !invalid && <Typography variant='b3'>{message}</Typography>}
      {message && invalid && (
        <Group gap={4} align='middle' className={s.errorMessage}>
          <Icon icon={ErrorIcon} />
          <Typography variant='b3'>{message}</Typography>
        </Group>
      )}
    </Stack>

    <Group gap={8} className={s.controls} align='middle'>
      {fileUrl && preview && (
        <a
          className={s.actionLink}
          href={fileUrl}
          download={title}
          target='_blank'
          rel='noreferrer'
        >
          <Icon icon={DownloadIcon} />
        </a>
      )}
      {fileUrl && !preview && (
        <ActionIcon size='small' icon={CloseIcon} variant='transparent' onClick={onRemove} />
      )}
    </Group>
  </Group>
)
