import { InferType, mixed, object, string } from 'yup'

import { categoryModelSchema } from './category.schemas'

const possibleSubcategoryCodes = [
  'RW',
  'AI',
  'WR',
  'LB',
  'SB',
  'HH',
  'KB',
  'DS',
  'NV',
  'DW',
  'GC',
  'PC',
  'SP',
  'TT',
  'KM',
  'LP',
  'OT',
  'CG',
  'CA',
  'SM',
  'CO',
  'LF',
  'TV',
  'PJ',
  'IH',
  'MC',
  'SC',
  'CH',
  'WC',
  'UG',
  'MO',
  'TB',
  'MP',
  'NT',
] as const

export type SubcategoryCodeTypes = (typeof possibleSubcategoryCodes)[number]

const possibleSubcategoryInternalNames = [
  'routers',
  'all_in_one_pc_workstations',
  'wired_routers',
  'laptop_stands',
  'sound_bars',
  'interface_hubs',
  'keyboard_n_mouses',
  'consoles',
  'network_video_adapters',
  'docking_stations',
  'headphones_n_headsets',
  'pcs_n_workstations',
  'speakers',
  'touch_pads',
  'label_printers',
  'laptop_batteries',
  'other',
  'cables_general',
  'cameras',
  'smartphones',
  'monitors',
  'printers',
  'tvs',
  'projectors',
  'keyboards',
  'microphones',
  'scanners',
  'chargers',
  'webcams',
  'usb_graphic_adapters',
  'mouses',
  'tablets',
  'multifunction_printers',
  'notebooks',
] as const

export type SubcategoryInternalNameTypes = (typeof possibleSubcategoryInternalNames)[number]

export const subcategoryModelSchema = object({
  id: string().defined(),
  name: string().defined(),
  icecatCategoryId: string().defined().nullable(),
  category: categoryModelSchema.defined(),
  categoryId: string().defined(),
  code: mixed<SubcategoryCodeTypes>().defined().oneOf(possibleSubcategoryCodes),
  internalName: mixed<SubcategoryInternalNameTypes>()
    .defined()
    .oneOf(possibleSubcategoryInternalNames),
})

export type SubcategoryModel = InferType<typeof subcategoryModelSchema>
