import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@assets/icons/download.svg'
import PlusSvg from '@assets/icons/plus.svg'
import UploadIcon from '@assets/icons/upload.svg'
import { useDownloadDpnTemplate } from '@features/dpn/download-dpn-template'
import { useUploadDPN } from '@features/dpn/upload-dpn'
import { ActionIcon, DropdownMenu, DropdownMenuProps } from '@share/components/ui'

export const SiteActionMenu: React.FC = () => {
  const { t } = useTranslation()

  const downloadDpnTemplate = useDownloadDpnTemplate()
  const uploadDpnFile = useUploadDPN()

  const items: DropdownMenuProps['items'] = useMemo(
    () => [
      {
        label: t('dpn.download-template'),
        icon: DownloadIcon,
        onClick: downloadDpnTemplate.onDownloadCSVTemplate,
      },
      {
        label: t('dpn.upload'),
        icon: UploadIcon,
        onClick: uploadDpnFile.onUploadDPN,
        disabled: uploadDpnFile.isLoading,
      },
    ],
    [
      downloadDpnTemplate.onDownloadCSVTemplate,
      t,
      uploadDpnFile.isLoading,
      uploadDpnFile.onUploadDPN,
    ],
  )

  return (
    <DropdownMenu
      items={items}
      align='start'
      trigger={<ActionIcon size='medium' icon={PlusSvg} />}
    />
  )
}
