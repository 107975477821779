import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  DEFAULT_ORDER_DISPUTE_FORM_VALUES,
  OrderDisputeFormValues,
  orderDisputeValidationSchema,
} from '../libs'

export const useOrderDisputeForm = (values?: OrderDisputeFormValues) =>
  useForm<OrderDisputeFormValues>({
    values,
    defaultValues: DEFAULT_ORDER_DISPUTE_FORM_VALUES,
    mode: 'onChange',
    resolver: yupResolver(orderDisputeValidationSchema),
  })
