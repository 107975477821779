import type { OrderAttachment } from '@share/@types'
import { InferType, object, Schema, string } from 'yup'

export const orderAttachmentModelSchema: Schema<OrderAttachment> = object({
  name: string().required().defined(),
  url: string().required().defined(),
})

export type OrderAttachmentModel = InferType<typeof orderAttachmentModelSchema>
export type OrderAttachmentsModel = OrderAttachmentModel[]
