import StatusOnlineIcon from '@assets/icons/status-online.svg'
import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { Icon } from '../icon/icon'

import s from './indicator.module.scss'

export interface IndicatorProps extends ClassName, Children {
  position?: 'bottomLeft' | 'bottomRight'
  image?: string
}

export const Indicator: React.FC<IndicatorProps> = ({
  className,
  children,
  position = 'bottomRight',
  image,
}) => (
  <div className={cn(s.root, className)}>
    {image && <img className={cn(s.image, s[position])} src={image} alt='' loading='lazy' />}
    {!image && <Icon size='medium' className={cn(s.icon, s[position])} icon={StatusOnlineIcon} />}
    {children}
  </div>
)
