import { FC } from 'react'
import { Link } from 'react-router-dom'
import LogoSvg from '@assets/icons/logo.svg'
import { ROUTES } from '@share/constants'
import cn from 'classnames'

import s from './logo.module.scss'

interface IProps {
  className?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
}

const sizes = {
  xsmall: {
    width: 57,
    height: 24,
  },
  small: {
    width: 76,
    height: 32,
  },
  medium: {
    width: 85,
    height: 36,
  },
  large: {
    width: 95,
    height: 40,
  },
  xlarge: {
    width: 114,
    height: 48,
  },
}

export const Logo: FC<IProps> = ({ className = '', size = 'xsmall' }) => (
  <Link to={ROUTES.root} className={cn(s.root, { [s[size]]: size }, className)}>
    <LogoSvg {...sizes[size]} />
  </Link>
)
