import { forwardRef, useCallback } from 'react'
import { useDeleteFile, useUploadFile } from '@share/hooks/api'

import { Dropzone, DropzoneProps } from '../../molecules'

type DropzoneEntities = 'asset' | 'employee' | 'location' | 'space'

export interface DropzoneConnectedToFilesProps
  extends Omit<DropzoneProps, 'onChange' | 'loading' | 'onCancel'> {
  entityType?: DropzoneEntities
  onChange: (file: string) => void
}

export const DropzoneConnectedToFiles = forwardRef<HTMLInputElement, DropzoneConnectedToFilesProps>(
  (props, ref) => {
    const { entityType = 'asset', value, accept, disabled, icon, title, subtitle, onChange } = props
    const { isLoading, mutateAsync: uploadFile, isError, reset: onCancel } = useUploadFile()
    const { isLoading: isDeleting, mutateAsync: deleteFile } = useDeleteFile()

    const handleOnChange = useCallback(
      async (file: File) => {
        const formData = new FormData()

        formData.append('entity', entityType)
        formData.append('file', file)

        const response = await uploadFile(formData)

        onChange(response.file)
      },
      [entityType, uploadFile, onChange],
    )

    const onDeleteFile = useCallback(async () => {
      if (value) {
        await deleteFile({ url: value })
      }

      onChange('')
    }, [onChange, deleteFile, value])

    return (
      <Dropzone
        ref={ref}
        accept={accept}
        disabled={disabled}
        icon={icon}
        loading={isLoading}
        deleting={isDeleting}
        showError={isError}
        value={value}
        title={title}
        subtitle={subtitle}
        onCancel={onCancel}
        onEdit={onDeleteFile}
        onChange={handleOnChange}
      />
    )
  },
)

DropzoneConnectedToFiles.displayName = 'DropzoneConnectedToFiles'
