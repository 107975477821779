import * as yup from 'yup'

export const companyOfficeFilterSchema = yup.object({
  name: yup.string().defined(),
  outputName: yup.string().defined(),
  values: yup.array(yup.string().defined()).defined(),
})

export const companyOfficeFiltersSchema = yup.array(companyOfficeFilterSchema).required().defined()

export type CompanyOfficeFilterModel = yup.InferType<typeof companyOfficeFilterSchema>
export type CompanyOfficeFiltersModel = yup.InferType<typeof companyOfficeFiltersSchema>
