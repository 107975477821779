import * as yup from 'yup'

export const brandModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  abbreviation: yup.string().defined(),
})
export type BrandModel = yup.InferType<typeof brandModelSchema>

export const brandsModelSchema = yup.object({
  brands: yup.array(brandModelSchema).defined(),
})
export type BrandsModel = yup.InferType<typeof brandsModelSchema>

export const brandMutationRequestSchema = yup.object({
  name: yup.string().defined(),
  abbreviation: yup.string().defined(),
})
export type BrandMutationRequest = yup.InferType<typeof brandMutationRequestSchema>
