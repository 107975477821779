import { memo } from 'react'
import { FiltersListCard, Separator, Typography } from '@dots/uikit'
import { Accordion } from '@share/components/ui'
import { AvailableFilters } from '@share/hooks'
import { SelectedFilters } from '@share/utils'

interface FiltersItemsProps {
  list?: AvailableFilters[]
  selectedFilters: SelectedFilters[]
  onChangeFilter: (filter: { name: string; value: string }) => void
}

const maxVisibleItems = 6

export const FiltersItems: React.FC<FiltersItemsProps> = memo(
  ({ list = [], selectedFilters, onChangeFilter }) => {
    const filtersList = list.map(({ name, outputName, specDetailValues }) => {
      const listItems = specDetailValues.map((value) => {
        const getSameFilter = selectedFilters.find((filter) => filter.name === name)
        const isChecked = Boolean(getSameFilter?.value.includes(value))

        const onCheckedChange = () => {
          onChangeFilter({
            name,
            value,
          })
        }

        return {
          id: value,
          label: value,
          value,
          checked: isChecked,
          onCheckedChange,
        }
      })

      const searchable = specDetailValues.length > maxVisibleItems

      return {
        value: outputName,
        title: (
          <Typography component='span' variant='b1'>
            {outputName}
          </Typography>
        ),
        content: (
          <>
            <Separator />
            <FiltersListCard
              items={listItems}
              searchable={searchable}
              maxVisibleItems={maxVisibleItems}
            />
          </>
        ),
      }
    })

    const defaultOpenedFilters = filtersList.slice(0, 10).map((filter) => filter?.value)

    return <Accordion type='multiple' list={filtersList} defaultValue={defaultOpenedFilters} />
  },
)

FiltersItems.displayName = 'FiltersItems'
