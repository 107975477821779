import ArrowDownIcon from '@assets/icons/arrow-down.svg'
import * as RadixAccordion from '@radix-ui/react-accordion'

import { Icon } from '../icon'

import s from './accordion.module.scss'

type ListItem = {
  value: string
  title: React.ReactNode
  content: React.ReactNode
}

export interface AccordionProps extends RadixAccordion.AccordionMultipleProps {
  list: ListItem[]
}

export const Accordion: React.FC<AccordionProps> = ({ list = [], ...props }) => (
  <RadixAccordion.Root {...props}>
    {list.map(({ title, value, content }) => (
      <RadixAccordion.Item value={value} key={value} className={s.item}>
        <RadixAccordion.Trigger className={s.trigger}>
          <div className={s.title}>{title}</div>
          <Icon className={s.icon} icon={ArrowDownIcon} />
        </RadixAccordion.Trigger>

        <RadixAccordion.Content>{content}</RadixAccordion.Content>
      </RadixAccordion.Item>
    ))}
  </RadixAccordion.Root>
)
