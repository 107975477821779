import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Label, LabelProps, Stack, Typography } from '../../atoms'

import s from './field.module.scss'

export interface TextFieldLabelProps extends ClassName {
  required?: boolean
  disabled?: boolean
  supportingText?: string
  label?: LabelProps['children']
  icon?: LabelProps['icon']
}

export const TextFieldLabel: React.FC<TextFieldLabelProps> = ({
  label,
  supportingText,
  required,
  disabled,
  icon,
  className,
}) => (
  <Stack className={cn(s.label, className)} gap={4}>
    <Label required={required} icon={icon} disabled={disabled}>
      {label}
    </Label>
    {supportingText && (
      <Typography className={s.secondaryText} variant='b3'>
        {supportingText}
      </Typography>
    )}
  </Stack>
)
