import * as yup from 'yup'

import { countryModelSchema, regionModelSchema } from '../country'

import { userRoleModelSchema } from './user-role.schemas'
import { userTeamModelSchema } from './user-team.schemas'

export const userSchema = yup.object({
  createdAt: yup.date().defined(),
  updatedAt: yup.date().defined(),
  id: yup.string().defined(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined(),
  staffSource: yup.string().defined(),
  status: yup.string().defined(),
  countryId: yup.string().defined(),
  email: yup.string().defined(),
  role: userRoleModelSchema.defined(),
  team: userTeamModelSchema.defined(),
  phone: yup.string().optional(),
  address: yup.string().defined(),
  postalCode: yup.string().defined(),
  activeAffectedDate: yup.date().defined(),
  department: yup.string().defined(),
  companyPosition: yup.string().defined(),
  companyId: yup.string().defined(),
  deletedAt: yup.date().nullable().defined(),
  assets: yup.array().defined(), // TODO: change to assetsModelSchema.defined()
  country: countryModelSchema.defined(),
  region: regionModelSchema.defined(),
  assetsCount: yup.number().defined(),
})

export const userCompanyStaffUpdateSchema = yup.object({
  id: yup.string().defined(),
  createdAt: yup.date().defined(),
  updatedAt: yup.date().defined(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined().nullable(),
  staffSource: yup.string().defined(),
  status: yup.string().defined(),
  countryId: yup.string().defined().nullable(),
  email: yup.string().defined(),
  role: userRoleModelSchema.defined().nullable(),
  roleId: yup.string().defined(),
  team: userTeamModelSchema.defined().nullable(),
  phone: yup.string().optional(),
  lat: yup.number().nullable().defined(),
  long: yup.number().nullable().defined(),
  customRole: yup.string().defined().nullable(),
  address: yup.string().defined(),
  postalCode: yup.string().defined().nullable(),
  activeAffectedDate: yup.date().defined(),
  department: yup.string().defined(),
  companyPosition: yup.string().defined(),
  companyId: yup.string().defined(),
  cityId: yup.string().defined(),
  deletedAt: yup.date().nullable().defined(),
  assets: yup.array().defined(),
  country: countryModelSchema.defined(),
  region: regionModelSchema.defined().nullable(),
  assetsCount: yup.number().defined(),
  employeeStatus: yup.string().defined(),
})

export type UserModel = yup.InferType<typeof userSchema>
