import type { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { TextFieldProps } from '../fields'
import { TextField } from '../fields'

export const FormTextControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  showInvalidMessage = true,
  ...rest
}: UseControllerProps<TFieldValues, TName> &
  Omit<TextFieldProps, 'invalid' | 'message' | 'onChange' | 'onBlur' | 'ref' | 'value' | 'name'> & {
    showInvalidMessage?: boolean
  }) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  return (
    <TextField
      invalid={fieldState.invalid}
      message={showInvalidMessage ? fieldState.error?.message : undefined}
      {...field}
      {...rest}
    />
  )
}
