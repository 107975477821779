import * as yup from 'yup'

import { OrderDisputeFormValues } from './types'

export const DEFAULT_ORDER_DISPUTE_FORM_VALUES: OrderDisputeFormValues = {
  requesterEmail: '',
  subject: '',
  issueType: '',
  description: '',
  attachments: [],
  comment: { keepPrevious: false },
}

export const orderDisputeValidationSchema = yup.object().shape({
  requesterEmail: yup.string().email().label('Requester email').email().required(),
  subject: yup.string().label('Subject').required(),
  issueType: yup.string().label('Issue type').required(),
  description: yup.string().label('Description').required(),
  comment: yup.object({ keepPrevious: yup.boolean() }).notRequired(),
})
