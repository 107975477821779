import { useEffect, useMemo, useState } from 'react'
import { Nullable } from '@dots/uikit'
import { DIALOG_CONTENT_ID } from '@share/constants'

export const useDialogContentTarget = () => {
  const [dialogContentTarget, setDialogContentTarget] = useState<Nullable<HTMLElement>>()

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const dialogContentSelector = `[data-dialog-content-id="${DIALOG_CONTENT_ID}"]`
      const dialogContentElement = document.querySelector<HTMLElement>(dialogContentSelector)

      if (dialogContentElement) {
        setDialogContentTarget(dialogContentElement)
        observer.disconnect()
      }
    })

    observer.observe(document, { childList: true, subtree: true })
    return () => observer.disconnect()
  }, [])

  return useMemo(() => dialogContentTarget, [dialogContentTarget])
}
