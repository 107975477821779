import * as yup from 'yup'

const possibleDeliveryEntitiesType = ['DELIVERY', 'PURCHASE'] as const
const possibleDeliveryAssetsTypes = ['WITH_ASSETS', 'WITHOUT_ASSETS'] as const
const possibleDeliveryOrderTypes = ['DOMESTIC', 'INTERNATIONAL'] as const

export type DeliveryEntitiesType = (typeof possibleDeliveryEntitiesType)[number]
export type DeliveryAssetsTypes = (typeof possibleDeliveryAssetsTypes)[number]
export type DeliveryOrderTypes = (typeof possibleDeliveryOrderTypes)[number]

export const deliveryEntityTypeSchema = yup
  .mixed<DeliveryEntitiesType>()
  .defined()
  .oneOf(possibleDeliveryEntitiesType)

export const deliveryAssetsTypesSchema = yup
  .mixed<DeliveryAssetsTypes>()
  .defined()
  .oneOf(possibleDeliveryAssetsTypes)

export const deliveryOrderTypeSchema = yup
  .mixed<DeliveryOrderTypes>()
  .defined()
  .oneOf(possibleDeliveryOrderTypes)
