import * as yup from 'yup'

export const createIntegrationModelSchema = yup
  .object({
    added: yup.number().defined(),
    updated: yup.number().defined(),
  })
  .noUnknown()

export type CreateIntegrationModel = yup.InferType<typeof createIntegrationModelSchema>

export const integrationDataResponseSchema = <T>(result: yup.Schema<T>) =>
  yup
    .object({
      connected: yup.array().of<T>(result).defined(),
      unconnected: yup.array().of<T>(result).defined(),
    })
    .defined()
    .noUnknown()

export const integrationMDMDataResponseSchema = <T>(
  connectedSchema: yup.Schema<T>,
  unconnectedSchema: yup.Schema<T>,
) =>
  yup
    .object({
      connected: yup.array().of<T>(connectedSchema).defined(),
      unconnected: yup.array().of<T>(unconnectedSchema).defined(),
    })
    .defined()
    .noUnknown()
