import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { create, useModal } from '@ebay/nice-modal-react'
import { Dialog, Form, FormAttachmentsControl, FormTextControl, Stack } from '@share/components/ui'

import { OrderActionDialog } from '../../../../common'
import { useOrderPONumberForm } from '../../../hooks'
import type { OrderPONumberUpdateDialogProps } from '../../../libs'
import { ORDER_PO_NUMBER_EDIT_DIALOG_ID } from '../../../libs'

import s from './order-po-number-edit-dialog.module.scss'

export const OrderPONumberEditDialog = create<OrderPONumberUpdateDialogProps>(
  ({ purchaseOrderNumber, purchaseQuote, onSubmit, onQuoteRemove, loading }) => {
    const { t } = useTranslation()
    const modalMethods = useModal(ORDER_PO_NUMBER_EDIT_DIALOG_ID)

    const { control, handleSubmit, formState } = useOrderPONumberForm({
      purchaseOrderNumber,
      purchaseQuote,
    })

    const SlotFooter = useMemo(
      () => (
        <Dialog.Footer
          buttonOkProps={{
            children: t('actions.continue'),
            disabled: !formState.isDirty,
            loading,
            onClick: handleSubmit(onSubmit),
          }}
        />
      ),
      [t, formState.isDirty, loading, handleSubmit, onSubmit],
    )

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.po-number.title')}
        subTitle={t('orders.modals.po-number.subtitle')}
        onOpenChange={modalMethods.remove}
        slotFooter={SlotFooter}
      >
        <Form.Root>
          <Stack gap={20} className={s.content}>
            <FormTextControl
              label={t('orders.modals.po-number.number.label')}
              placeholder={t('orders.modals.po-number.number.placeholder')}
              control={control}
              name='purchaseOrderNumber'
            />
            <FormAttachmentsControl
              single
              label={t('orders.modals.po-number.document.label')}
              control={control}
              name='purchaseQuote'
              entityType='purchaseQuote'
              buttonUploadProps={{ color: 'secondary' }}
              onRemoveCallback={onQuoteRemove}
            />
          </Stack>
        </Form.Root>
      </OrderActionDialog>
    )
  },
)
