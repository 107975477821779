import { Navigate, Outlet } from 'react-router-dom'
import { useUserStore } from '@features/auth'
import { ROUTES } from '@share/constants'

export const PrivateRoute = () => {
  const { isUserHasAccess } = useUserStore()

  if (isUserHasAccess) {
    return <Outlet />
  }

  return <Navigate to={ROUTES.login} replace />
}
