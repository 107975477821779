import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { deliveryOrderCommercialOfferModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  id: yup.string().defined(),
  commercialOffer: deliveryOrderCommercialOfferModelSchema.defined(),
})

interface GetOrderDeliveryCommercialOfferRequest {
  id: string
}

type GetOrdersTableResponse = yup.InferType<typeof responseSchema>

const getOrderDeliveryCommercialOfferRequest = ({ id }: GetOrderDeliveryCommercialOfferRequest) =>
  apiRequest<GetOrdersTableResponse>({
    method: 'GET',
    url: API_LIST.order.delivery.slug.commercialOffer(id),
    responseSchema,
  })

export const useGetOrderDeliveryCommercialOfferRequest = (
  params: GetOrderDeliveryCommercialOfferRequest,
) =>
  useQuery<GetOrdersTableResponse, Error>({
    queryKey: [API_TAGS.ORDERS.root.commercialOffer, params],
    queryFn: () => getOrderDeliveryCommercialOfferRequest(params),
  })
