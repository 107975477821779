import * as yup from 'yup'

export const companyStaffFilterModelSchema = yup.object({
  name: yup.string().defined(),
  outputName: yup.string().defined(),
  values: yup.array(yup.string().defined()).defined(),
})
export const companyStaffFiltersModelSchema = yup
  .array()
  .of(companyStaffFilterModelSchema)
  .defined()

export type CompanyStaffFilterModel = yup.InferType<typeof companyStaffFilterModelSchema>
export type CompanyStaffFiltersModel = yup.InferType<typeof companyStaffFiltersModelSchema>
