import { RadioBox } from '../../atoms/radio-box'

export interface RadioButtonLabelProps extends RadioBox.RadioButtonProps {
  label: string
}

export const RadioButtonLabel: React.FC<RadioButtonLabelProps> = ({ id, label, ...rest }) => (
  <RadioBox.RadioLabel id={id}>
    <RadioBox.RadioButton id={id} {...rest} />
    {label}
  </RadioBox.RadioLabel>
)
