import EditIcon from '@assets/icons/edit.svg'
import { ActionIcon, LoadingOverlay, Stack } from '@dots/uikit'

import s from './dropzone.module.scss'

export type DropzonePreviewProps = {
  src?: string
  loading?: boolean
  onEdit: () => void
}

export const DropzonePreview: React.FC<DropzonePreviewProps> = ({ src, loading, onEdit }) => (
  <Stack className={s.preview} justify='center' align='center'>
    {loading && <LoadingOverlay variant='naked' />}
    <img className={s.previewImage} src={src} />
    <ActionIcon
      variant='subtle'
      disabled={loading}
      className={s.previewControl}
      icon={EditIcon}
      onClick={onEdit}
    />
  </Stack>
)
