import NoAssignIcon from '@assets/icons/noassign.svg'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Icon } from '../icon/icon'

import { AvatarFallback } from './avatar-fallback'

import s from './avatar.module.scss'

export interface AvatarProps extends ClassName {
  image?: string
  canCreate?: boolean
  secondary?: boolean
  size?: 'medium' | 'large'
}

// TODO: Move to PictureBox
export const Avatar: React.FC<AvatarProps> = ({
  className,
  image,
  canCreate,
  secondary,
  size = 'medium',
}) => (
  <div className={cn(s.root, [s[size]], className)}>
    {canCreate && (
      <div className={cn(s.createPlaceholder, { [s.secondary]: secondary })}>
        <Icon icon={NoAssignIcon} />
      </div>
    )}

    {!canCreate && image && <img className={s.image} src={image} alt='' loading='lazy' />}

    {!canCreate && !image && <AvatarFallback />}
  </div>
)
