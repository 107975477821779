import DividerHorSvg from '@assets/icons/divider-hor.svg'
import { Typography } from '@share/components/ui'

import { useSidebarStore } from '../../../model'

import s from './area.module.scss'

interface AreaProps {
  title?: string
  children: React.ReactNode
}

export const Area: React.FC<AreaProps> = ({ title, children }) => {
  const { isCollapsed } = useSidebarStore()

  return (
    <div className={s.root}>
      {title && (
        <div className={s.titleFrame}>
          {isCollapsed ? (
            <DividerHorSvg className={s.line} />
          ) : (
            <Typography variant='b3'>{title}</Typography>
          )}
        </div>
      )}

      <div className={s.list}>{children}</div>
    </div>
  )
}
