import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import s from './stack.module.scss'

export interface StackProps extends ClassName, Children {
  gap?: number
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between'
  align?: 'start' | 'center' | 'end'
  fullWidth?: boolean
  onClick?: () => void
}

export const Stack: React.FC<StackProps> = ({
  className,
  children,
  gap,
  justify,
  align,
  fullWidth,
  onClick,
}) => (
  <div
    className={cn(
      s.root,
      {
        [s.flexStart]: justify === 'flex-start',
        [s.flexCenter]: justify === 'center',
        [s.flexEnd]: justify === 'flex-end',
        [s.flexAround]: justify === 'space-around',
        [s.flexBetween]: justify === 'space-between',
        [s.start]: align === 'start',
        [s.center]: align === 'center',
        [s.end]: align === 'end',
        [s.fullWidth]: fullWidth,
      },
      className,
    )}
    style={{ gap }}
    onClick={onClick}
  >
    {children}
  </div>
)
