import * as yup from 'yup'

import { assetSpecsModelSchema } from '../asset'
import { brandModelSchema } from '../brand'
import { categoryModelSchema } from '../categories/category.schemas'

export const iceCatModelSchema = yup.object({
  category: categoryModelSchema.defined(),
  image: yup.string().defined(),
  model: yup.string().defined(),
  name: yup.string().defined(),
  price: yup.number().defined(),
  productCode: yup.string().defined(),
  source: yup.string().defined(),
  brand: brandModelSchema.defined().nullable(),
  brandId: yup.string().defined().nullable(),
  specs: assetSpecsModelSchema.defined(),
  subcategoryId: yup.string().defined(),
})

export type IceCatModel = yup.InferType<typeof iceCatModelSchema>
