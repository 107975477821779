import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'

import { TextareaField, TextareaFieldProps } from '../fields'

export const FormTextareaControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  ...rest
}: UseControllerProps<TFieldValues, TName> &
  Omit<
    TextareaFieldProps,
    'invalid' | 'message' | 'onChange' | 'onBlur' | 'ref' | 'value' | 'name'
  >) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules,
    shouldUnregister,
  })

  return (
    <TextareaField
      invalid={fieldState.invalid}
      message={fieldState.error?.message}
      {...field}
      {...rest}
    />
  )
}
