import { Group } from '../group/group'
import { Loader } from '../loader'

import s from './button.module.scss'

export const Loading = () => (
  <Group gap={8} className={s.loading}>
    <Loader />
    <span className={s.label}>Loading...</span>
  </Group>
)
