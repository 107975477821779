import * as yup from 'yup'

import { companyStaffTeamModelSchema } from './company-staff-team.schemas'

export const companyStaffRoleModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  team: companyStaffTeamModelSchema.defined(),
  teamId: yup.string().defined(),
})

export type CompanyStaffRoleModel = yup.InferType<typeof companyStaffRoleModelSchema>
