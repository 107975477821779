import * as yup from 'yup'

export const searchRecentModelSchema = yup.object({
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  id: yup.string().defined(),
  adminId: yup.string().defined(),
  search: yup.string().defined(),
})

export type SearchRecentModel = yup.InferType<typeof searchRecentModelSchema>
