import * as yup from 'yup'

import { paginationResponseSchema } from '../core.schemas'

import { countrySourcesSchema } from './sources.schemas'

export const cityModelSchema = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().defined(),
  countryId: yup.string().defined(),
  source: countrySourcesSchema.defined(),
})
export const citiesModelSchema = yup.array().of(cityModelSchema).defined()

export type CityModel = yup.InferType<typeof cityModelSchema>
export type CitiesModel = yup.InferType<typeof citiesModelSchema>

export const citiesResponseSchema = paginationResponseSchema(cityModelSchema)
export type CitiesResponse = yup.InferType<typeof citiesResponseSchema>
