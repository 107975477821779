import { Helmet } from 'react-helmet-async'
import {
  ALERT_CONTAINER_ID,
  CONFIRM_CONTAINER_ID,
  DRAWER_CONTAINER_ID,
  MODAL_CONTAINER_ID,
} from '@share/constants'

import { Router } from './router/router'
import { withProviders } from './providers'

const App = () => (
  <>
    <Helmet defaultTitle='Dots - Customer login'>
      <meta name='description' content='Log in to your account to keep track of your assets.' />
    </Helmet>
    <Router />

    <div id={DRAWER_CONTAINER_ID} />
    <div id={MODAL_CONTAINER_ID} />
    <div id={ALERT_CONTAINER_ID} />
    <div id={CONFIRM_CONTAINER_ID} />
  </>
)

export default withProviders(App)
