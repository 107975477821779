import Accessories from '@assets/icons/fallbacks/accessories.svg'
import Cables from '@assets/icons/fallbacks/cables.svg'
import ComputerSystems from '@assets/icons/fallbacks/computer-systems.svg'
import Displays from '@assets/icons/fallbacks/displays.svg'
import Networks from '@assets/icons/fallbacks/networks.svg'
import OfficeEquipment from '@assets/icons/fallbacks/office-equipment.svg'
import Other from '@assets/icons/fallbacks/other.svg'
import PhonesTables from '@assets/icons/fallbacks/phones-tables.svg'
import PrinterScanners from '@assets/icons/fallbacks/printers-and-scanners.svg'
import TwoAssetsIcon from '@assets/icons/large/two-assets.svg'
import ServersIcon from '@dots/uikit/icons/large/servers.svg'
import VideoConfIcon from '@dots/uikit/icons/large/video-conferencing.svg'

export const ASSETS_FALLBACK_ICONS = {
  accessories: Accessories,
  cables: Cables,
  comp_systems: ComputerSystems,
  displays: Displays,
  networks: Networks,
  office_eq: OfficeEquipment,
  phone_tables: PhonesTables,
  printers_and_scanners: PrinterScanners,
  video_conf: VideoConfIcon,
  servers_and_communication_cabinets: ServersIcon,
  grouped: TwoAssetsIcon,
  other: Other,
}
