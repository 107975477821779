export const possibleNotificationIcon = [
  'ASSET',
  'BAMBOO',
  'HIBOB',
  'INTEGRATION',
  'LOCATION',
  'SPACE',
  'STAFF',
  'JAMF',
  'INTUNE',
  'ORDER',
] as const

export type NotificationIcon = (typeof possibleNotificationIcon)[number]
