import * as yup from 'yup'

import { deliveryShippingMethodSchema } from './delivery-shipping-methods.schemas'

export const deliveryOrderShippingInfoSchema = yup.object().shape({
  id: yup.string().defined(),
  estimatedTimeOfArrival: yup.string().defined(),
  requestedPickUpDate: yup.string().defined(),
  shippingMethod: deliveryShippingMethodSchema.defined(),
  receiverSignatureNeeded: yup.boolean().defined(),
  receiverId: yup.string().nullable(),
})

export type DeliveryOrderShippingInfoModel = yup.InferType<typeof deliveryOrderShippingInfoSchema>
export interface DeliveryOrderUpdateShippingInfoModel
  extends Pick<
    DeliveryOrderShippingInfoModel,
    'requestedPickUpDate' | 'receiverSignatureNeeded' | 'receiverId'
  > {
  shippingMethodId: DeliveryOrderShippingInfoModel['shippingMethod']['id']
}
