import { Nullable } from '@share/@types'
import { useImageErrorState } from '@share/hooks'

import { Fallback, FallbackProps } from './asset-fallback'

import s from './asset-preview.module.scss'

export interface AssetPreviewProps extends FallbackProps {
  image?: Nullable<string>
}

export const AssetPreview: React.FC<AssetPreviewProps> = ({ image, category }) => {
  const { imageError, handleErrorImageState } = useImageErrorState()

  return (
    <div className={s.root}>
      {(!image || imageError) && <Fallback category={category} />}

      {image && !imageError && (
        <img className={s.image} src={image} alt='' onError={handleErrorImageState} />
      )}
    </div>
  )
}
