import { RadioGroupItemProps } from '@radix-ui/react-radio-group'

import s from './radio.module.scss'

interface ILabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  id: RadioGroupItemProps['id']
}

export const RadioLabel: React.FC<ILabelProps> = ({ id, children, ...rest }) => (
  <label className={s.label} htmlFor={id} {...rest}>
    {children}
  </label>
)
