import cn from 'classnames'

import s from './typography.module.scss'

export type Variants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'c1'
  | 'c2'
  | 'c3'
  | 'c3Fat'
  | 'c4'
  | 'c5'
  | 'b1'
  | 'b2'
  | 'b3'
type Align = 'left' | 'center' | 'right'

interface IProps extends React.HTMLAttributes<HTMLElement> {
  className?: string
  children?: React.ReactNode
  align?: Align
  variant?: Variants
  decoration?: 'line-through'
  component?: React.ElementType
}

export const Typography: React.FC<IProps> = ({
  variant = 'body',
  component: Component = 'p',
  className = '',
  children,
  align,
  decoration,
  ...rest
}) => (
  <Component
    className={cn(
      s.root,
      {
        [s[variant]]: variant,
        [s.left]: align === 'left',
        [s.center]: align === 'center',
        [s.right]: align === 'right',
        [s.lineThrough]: decoration === 'line-through',
      },
      className,
    )}
    {...rest}
  >
    {children}
  </Component>
)
