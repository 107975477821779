import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { create } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, ModalContent, ModalFooter, ModalHeader } from '@share/components/ui'

import { useDeleteEventControl } from '../../hooks'
import { DeleteEventFormValues, DeleteEventProps, deleteEventValidationSchema } from '../../libs'
import { EventDeleteForm } from '../molecules'

export const DeleteEvent = create<DeleteEventProps>(
  ({ title, label, placeholder, footerProps, ...modalProps }) => {
    const modalMethods = useDeleteEventControl()

    const { control, formState } = useForm<DeleteEventFormValues>({
      resolver: yupResolver(deleteEventValidationSchema),
    })
    const disabled = useMemo(
      () => !formState.isDirty || !formState.isValid,
      [formState.isDirty, formState.isValid],
    )

    return (
      <Modal open={modalMethods.visible} onOpenChange={modalMethods.remove} {...modalProps}>
        <ModalHeader title={title} />

        <ModalContent height={150}>
          <EventDeleteForm control={control} label={label} placeholder={placeholder} />
        </ModalContent>

        <ModalFooter
          buttonOkProps={{
            children: footerProps.children,
            color: 'danger',
            onClick: footerProps.onOkClick,
            loading: footerProps.okLoading,
            disabled,
          }}
        />
      </Modal>
    )
  },
)
