import { Control } from 'react-hook-form'
import * as yup from 'yup'

export type PurchaseFlowFormValues = {
  deliveryDate: [string | null, string | null]
  address: string
  addressAdditional: string
  country: string
  city: string
  state: string
  zipCode: string
  fullName: string
  email: string
  phoneNumber: string
  saveToAddress: boolean
  saveToPerson: boolean
  deliveryNotes: string
  method: string
  billing: string
  orderNotes?: string
  deliveryLocation: {
    type: 'OFFICE' | 'WAREHOUSE'
    value: string
  }
  deliveryEmployeeLocation: string
  contactPersonEmployee: string
}

export type PurchaseFlowFormControl = Control<PurchaseFlowFormValues>

export const defaultValues: PurchaseFlowFormValues = {
  deliveryDate: [null, null],
  address: '',
  addressAdditional: '',
  country: '',
  city: '',
  state: '',
  zipCode: '',
  fullName: '',
  email: '',
  phoneNumber: '',
  saveToAddress: false,
  saveToPerson: false,
  deliveryNotes: '',
  method: '',
  billing: '',
  orderNotes: '',
  deliveryLocation: {
    type: 'OFFICE',
    value: '',
  },
  deliveryEmployeeLocation: '',
  contactPersonEmployee: '',
}

const deliveryValidationSchema = yup.object().shape({
  deliveryDate: yup
    .mixed()
    .label('Delivery date')
    .test({
      name: 'deliveryDate',
      message: 'Expected delivery date is required',
      test: (value) => {
        if (Array.isArray(value)) {
          return value[0] !== null && value[1] !== null
        }
        return false
      },
    }),
  address: yup.string().label('Address').required(),
  addressAdditional: yup.string().label('Address additional').notRequired(),
  country: yup.string().label('Country').required(),
  saveToAddress: yup.boolean().label('Save changes').notRequired(),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  zipCode: yup.number().label('Zip code').typeError('Zip is required field').required(),
  fullName: yup.string().label('Full Name').required(),
  email: yup.string().email().label('Email').required(),
  phoneNumber: yup
    .number()
    .label('Phone number')
    .typeError('Phone number is required field')
    .required(),
  saveToPerson: yup.boolean().label('Save changes').notRequired(),
  deliveryNote: yup.number().label('Delivery note').notRequired(),
})

const paymentValidationSchema = yup.object().shape({
  method: yup.string().label('Payment method').required(),
  billing: yup.string().label('Billing information').required(),
})

const confirmValidationSchema = yup.object().shape({
  orderNotes: yup.string().label('Order notes').notRequired(),
})

export const schemas = [deliveryValidationSchema, paymentValidationSchema, confirmValidationSchema]

export interface SelectedAddress {
  address: string
  addressAdditional: string
  country: string
  city: string
  state: string
  zipCode: string
}

export interface SelectedContactPerson {
  fullName: string
  email: string
  phoneNumber: string
}

export type SelectedItem = SelectedAddress | SelectedContactPerson
export type PreviewChangeSelect = (newValues: Partial<SelectedItem>) => () => void
