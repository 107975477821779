import { asString } from '@dots/uikit'
import { Undefinable } from '@share/@types'
import { DeliveryOrderModel } from '@share/schemas'
import { asNumber, undefToEmptyString } from '@share/utils'
import * as yup from 'yup'

import { DeliveryCommercialOfferMutationFormValues } from './types'

export const deliveryOrderCommercialOfferDefaultValues: DeliveryCommercialOfferMutationFormValues =
  {
    tax: '',
    shipmentCost: '',
    currencyId: '',
    shippingPrice: '',
    etaMaxDays: '',
    etaMinDays: '',
    vendorId: '',
    validUntil: '',
    pickupDate: [null, null],
    comment: { keepPrevious: false },
  }

export const deliveryOrderCommercialOfferFormValidationSchema = yup.object().shape({
  etaMinDays: yup
    .number()
    .positive()
    .label('Eta min days')
    .typeError('Eta min days must be a positive number')
    .required(),
  etaMaxDays: yup
    .number()
    .positive()
    .label('Eta max days')
    .typeError('Eta max days must be a positive number')
    .required(),
  tax: yup
    .number()
    .positive()
    .min(0)
    .label('Tax')
    .typeError('Tax must be greater than or equal to 0')
    .required(),
  shippingPrice: yup
    .number()
    .positive()
    .min(0)
    .label('Shipping price')
    .typeError('Shipping price must be greater than or equal to 0')
    .required(),
  shipmentCost: yup
    .number()
    .positive()
    .min(0)
    .label('Shipment cost')
    .typeError('Shipment cost must be greater than or equal to 0')
    .required(),
  vendorId: yup.string().label('Vendor').required(),
  currencyId: yup.string().label('Currency').required(),
  validUntil: yup.string().label('Valid').required(),
  pickupDate: yup
    .mixed()
    .label('Pickup date')
    .test({
      name: 'pickupDate',
      message: 'Pickup date is required',
      test: (value) => {
        if (Array.isArray(value)) {
          return value[0] !== null && value[1] !== null
        }
        return false
      },
    }),
  comment: yup.object({ keepPrevious: yup.boolean() }).notRequired(),
})

export const adaptDeliveryOrderCommercialOfferToFormValues = (
  commercialOffer?: DeliveryOrderModel['commercialOffer'],
): Undefinable<DeliveryCommercialOfferMutationFormValues> => {
  if (!commercialOffer) return undefined

  return {
    etaMaxDays: asString(commercialOffer.etaMaxDays),
    etaMinDays: asString(commercialOffer.etaMinDays),
    tax: asString(commercialOffer.tax),
    shippingPrice: asString(commercialOffer.shippingPrice),
    shipmentCost: asString(commercialOffer.shipmentCost),
    validUntil: commercialOffer.validUntil,
    vendorId: undefToEmptyString(commercialOffer.vendorId),
    currencyId: undefToEmptyString(commercialOffer.currency?.id),
    pickupDate: [commercialOffer.pickupDateFrom, commercialOffer.pickupDateTo],
    comment: { keepPrevious: false },
  }
}

export const adaptDeliveryCommercialOfferFormValuesToOffer = ({
  pickupDate,
  ...formValues
}: DeliveryCommercialOfferMutationFormValues) => ({
  etaMaxDays: asNumber(formValues.etaMaxDays),
  etaMinDays: asNumber(formValues.etaMinDays),
  tax: asNumber(formValues.tax),
  shippingPrice: asNumber(formValues.shippingPrice),
  shipmentCost: asNumber(formValues.shipmentCost),
  validUntil: formValues.validUntil,
  vendorId: formValues.vendorId,
  currencyId: formValues.currencyId,
  pickupDateFrom: undefToEmptyString(pickupDate[0]),
  pickupDateTo: undefToEmptyString(pickupDate[1]),
  comment: formValues.comment,
})
