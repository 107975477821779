import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { orderDisputeSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  id: yup.string().defined(),
  openedDisputeForm: orderDisputeSchema.defined(),
})

interface GetOrderOpenedDisputeRequest {
  id: string
}

type GetOrdersTableResponse = yup.InferType<typeof responseSchema>

export const getOrderOpenedDisputeRequest = ({ id }: GetOrderOpenedDisputeRequest) =>
  apiRequest<GetOrdersTableResponse>({
    method: 'GET',
    url: API_LIST.order.root.openedDispute(id),
    responseSchema,
  })

export const useGetOrderOpenedDisputeRequest = (params: GetOrderOpenedDisputeRequest) =>
  useQuery<GetOrdersTableResponse, Error>({
    queryKey: [API_TAGS.ORDERS.root.dispute, params],
    queryFn: () => getOrderOpenedDisputeRequest(params),
    enabled: false,
  })
