import BambooPng from '@assets/images/bamboo.png'
import BobPng from '@assets/images/bob.png'
import WarehouseImage from '@assets/images/warehouse.jpg'
import type { IconProps } from '@dots/uikit'
import { Icon, IconHolder } from '@dots/uikit'
import AddressIcon from '@dots/uikit/icons/large/address.svg'
import EmployeeIcon from '@dots/uikit/icons/large/employee.svg'
import OfficeIcon from '@dots/uikit/icons/large/office.svg'
import SpaceIcon from '@dots/uikit/icons/large/space.svg'
import type { Nullable } from '@share/@types'
import { PictureBox } from '@share/components/ui/atoms'
import { useImageErrorState } from '@share/hooks'
import type { StaffSource } from '@share/schemas'
import { getIntegrationIcon } from '@share/utils'

export type AssignToRoleTypes = 'STAFF' | 'SPACE' | 'ADDRESS' | 'OFFICE' | 'WAREHOUSE'

const RoleIcon = {
  STAFF: EmployeeIcon,
  SPACE: SpaceIcon,
  ADDRESS: AddressIcon,
  OFFICE: OfficeIcon,
  WAREHOUSE: AddressIcon,
}

const integrationIcons = {
  HIBOB: BobPng,
  BAMBOO_HR: BambooPng,
}

export interface AssignPreviewProps {
  reversed?: PictureBox.RootProps['reversed']
  withBorder?: PictureBox.RootProps['withBorder']
  title?: PictureBox.CaptionProps['title']
  titleVariant?: PictureBox.CaptionProps['titleVariant']
  subtitle?: PictureBox.CaptionProps['subtitle']
  titleTestId?: PictureBox.CaptionProps['titleTestId']
  subtitleTestId?: PictureBox.CaptionProps['subtitleTestId']
  date?: PictureBox.DateProps['date']
  type?: AssignToRoleTypes
  image?: Nullable<PictureBox.AvatarProps['src']>
  iconConfig?: {
    icon: IconProps['icon']
    className: IconProps['className']
  }
  disabled?: boolean
  source?: StaffSource
  onClick?: PictureBox.RootProps['onClick']
  captionIconSlot?: React.ReactNode
  boxProps?: Partial<PictureBox.BoxProps>
  fallbackProps?: Partial<PictureBox.FallbackProps>
  gap?: PictureBox.RootProps['gap']
  slotPrepend?: React.ReactNode
}

export const AssignPreview: React.FC<AssignPreviewProps> = ({
  reversed,
  title,
  titleVariant,
  subtitle,
  date,
  type = 'STAFF',
  image,
  iconConfig,
  source,
  captionIconSlot,
  titleTestId,
  subtitleTestId,
  boxProps,
  fallbackProps,
  slotPrepend,
  ...rest
}) => {
  const { imageError, handleErrorImageState } = useImageErrorState()
  const { icon, className: iconClassname } = iconConfig || {}

  return (
    <PictureBox.Root reversed={reversed} {...rest}>
      {slotPrepend}
      <PictureBox.Box icon={getIntegrationIcon(integrationIcons, source)} {...boxProps}>
        {image && !icon && !imageError && (
          <PictureBox.Avatar src={image} handleFallbackImage={handleErrorImageState} />
        )}
        {icon && !image && (
          <IconHolder size='medium'>
            <Icon icon={icon} size='large' className={iconClassname} />
          </IconHolder>
        )}
        {imageError && !icon && <PictureBox.Fallback icon={RoleIcon[type]} />}

        {!image && !icon && type === 'WAREHOUSE' && <PictureBox.Avatar src={WarehouseImage} />}
        {!image && !icon && type !== 'WAREHOUSE' && (
          <PictureBox.Fallback icon={RoleIcon[type]} {...fallbackProps} />
        )}
      </PictureBox.Box>

      <PictureBox.Caption
        title={title}
        titleVariant={titleVariant}
        subtitle={subtitle}
        iconSlot={captionIconSlot}
        titleTestId={titleTestId}
        subtitleTestId={subtitleTestId}
      />

      {date && <PictureBox.Date date={date} />}
    </PictureBox.Root>
  )
}
