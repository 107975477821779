import * as yup from 'yup'

export const orderTrackingInfoModelSchema = yup
  .object()
  .shape({
    trackingNumber: yup.string().defined(),
    trackingLink: yup.string().defined(),
  })
  .noUnknown()

export type OrderTrackingInfoModel = yup.InferType<typeof orderTrackingInfoModelSchema>
