import { forwardRef, useMemo } from 'react'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Input, InputProps, Stack, Tooltip, TooltipProps } from '../../atoms'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface TextFieldProps extends ClassName, InputProps {
  inline?: boolean
  required?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
  tooltip?: Pick<TooltipProps, 'title' | 'content'>
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      messageType = 'error',
      className,
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      invalid,
      disabled,
      required,
      tooltip,
      ...inputProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.inline]: inline,
        [s.disabled]: disabled,
      },
      className,
    )

    const InputArea = useMemo(
      () => (
        <Stack gap={6}>
          <Input disabled={disabled} invalid={invalid} {...inputProps} ref={ref} />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      ),
      [assistiveText, disabled, inputProps, invalid, message, messageType, ref],
    )

    return (
      <div className={rootClassName}>
        {label && (
          <TextFieldLabel label={label} required={required} supportingText={supportingText} />
        )}

        <Stack gap={6} className={s.container}>
          <Tooltip
            title={tooltip?.title}
            content={tooltip?.content}
            sideOffset={10}
            side='top'
            trigger={InputArea}
          />
        </Stack>
      </div>
    )
  },
)

TextField.displayName = 'TextField'
