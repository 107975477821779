import { forwardRef } from 'react'
import cn from 'classnames'

import { Stack } from '../../atoms'
import { RangeDatePicker, RangeDatePickerProps } from '../../molecules'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface RangeDatepickerFieldProps extends RangeDatePickerProps {
  inline?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
}

export const RangeDatepickerField = forwardRef<HTMLInputElement, RangeDatepickerFieldProps>(
  (
    {
      messageType = 'error',
      className,
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      invalid,
      disabled,
      required,
      ...datepickerProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.errored]: invalid,
        [s.inline]: inline,
        [s.vCenter]: !supportingText,
        [s.disabled]: disabled,
      },
      className,
    )

    return (
      <div className={rootClassName}>
        <TextFieldLabel label={label} required={required} supportingText={supportingText} />

        <Stack className={s.container} gap={6}>
          <RangeDatePicker ref={ref} disabled={disabled} invalid={invalid} {...datepickerProps} />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      </div>
    )
  },
)

RangeDatepickerField.displayName = 'RangeDatepickerField'
