import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import type { OrderPONumberModel } from '@share/schemas'

import type { OrderPONumberFormValues } from '../libs'
import {
  adaptOrderPONumberToFormValues,
  orderPONumberDefaultValues,
  orderPONumberValidationSchema,
} from '../libs'

export const useOrderPONumberForm = (values: OrderPONumberModel) =>
  useForm<OrderPONumberFormValues>({
    values: adaptOrderPONumberToFormValues(values),
    defaultValues: orderPONumberDefaultValues,
    resolver: yupResolver(orderPONumberValidationSchema),
  })
