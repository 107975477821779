import { DateISOString } from '@share/@types'
import { asDate } from '@share/utils'

export const convertDateISOStringToDate = (date: DateISOString | null) => {
  if (date && asDate(date).isValid()) {
    return asDate(date).toDate()
  }

  return null
}

export const convertDateToDateISOString = (date: Date | null) => {
  if (date) return date.toISOString()

  return null
}
