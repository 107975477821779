import { useMemo } from 'react'
import { Nullable } from '@share/@types'

import { Stack, Typography } from '../../atoms'

import s from './entity-specs.module.scss'

interface EntitySpecsProps {
  titleSlot?: React.ReactNode
  specs: Array<{
    spec: string
    details: Array<{
      id: string
      outputName: string
      value: Nullable<string>
    }>
  }>
}

export const EntitySpecs: React.FC<EntitySpecsProps> = ({ specs, titleSlot }) => {
  const entitySpecs = useMemo(
    () => specs?.filter((item) => item.details.some(({ value }) => value)),
    [specs],
  )

  return (
    <Stack>
      {entitySpecs.length > 0 && titleSlot}
      {entitySpecs.map(({ details, spec }) => (
        <div className={s.item} key={spec}>
          <Typography variant='b3' component='p' className={s.label}>
            {spec}
          </Typography>

          <Typography variant='b3' component='ul' className={s.list}>
            {details
              .filter(({ value }) => Boolean(value))
              .map(({ id, outputName, value }) => (
                <li key={id} className={s.listItem}>
                  {outputName}: {value}
                </li>
              ))}
          </Typography>
        </div>
      ))}
    </Stack>
  )
}
