import * as yup from 'yup'

export const MDMIntegrationUnconnectedModelSchema = yup.object({
  name: yup.string().defined(),
  description: yup.string().defined().nullable(),
  lastSync: yup.string().defined().nullable(),
  countOfSyncedStaff: yup.number().defined().nullable(),
  available: yup.boolean().defined(),
  image: yup.string().defined(),
})

export const MDMIntegrationConnectedModelSchema = MDMIntegrationUnconnectedModelSchema.omit([
  'countOfSyncedStaff',
])

export type MDMIntegrationConnectedModel = yup.InferType<typeof MDMIntegrationConnectedModelSchema>
export type MDMIntegrationUnconnectedModel = yup.InferType<
  typeof MDMIntegrationUnconnectedModelSchema
>
