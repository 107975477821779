import { forwardRef } from 'react'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Stack, Tooltip, TooltipProps } from '../../atoms'
import { Select, SelectProps } from '../../molecules'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface SelectFieldProps extends ClassName, SelectProps {
  required?: boolean
  inline?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
  tooltip?: Pick<TooltipProps, 'title' | 'content'>
}

export const SelectField = forwardRef<never, SelectFieldProps>(
  (
    {
      className,
      inline,
      message,
      supportingText,
      required,
      assistiveText,
      label,
      invalid,
      disabled,
      messageType,
      tooltip,
      ...selectProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.errored]: invalid,
        [s.inline]: inline,
        [s.vCenter]: !supportingText,
        [s.disabled]: disabled,
      },
      className,
    )

    const SelectArea = (
      <Stack gap={6}>
        <Select disabled={disabled} invalid={invalid} {...selectProps} ref={ref} />

        {(message || assistiveText) && (
          <TextFieldMessage
            message={message}
            assistiveText={assistiveText}
            messageType={messageType}
          />
        )}
      </Stack>
    )

    return (
      <div className={rootClassName}>
        {label && (
          <TextFieldLabel label={label} required={required} supportingText={supportingText} />
        )}

        <Stack gap={6} className={s.container}>
          <Tooltip
            title={tooltip?.title}
            content={tooltip?.content}
            sideOffset={10}
            side='top'
            trigger={SelectArea}
          />
        </Stack>
      </div>
    )
  },
)

SelectField.displayName = 'SelectField'
