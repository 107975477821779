import AlertIcon from '@assets/icons/alert.svg'
import { create, useModal } from '@ebay/nice-modal-react'
import type { ConfirmCircleProps, ConfirmProps } from '@share/components/ui'
import { Confirm, ConfirmCircle, Stack, Typography } from '@share/components/ui'
import { CONFIRM_EVENT_MODAL_ID } from '@share/constants'

type ConfirmModalProps = Omit<
  ConfirmProps,
  'open' | 'onOpenChange' | 'modal' | 'defaultOpen' | 'children'
>

interface IConfirmCircle {
  icon: ConfirmCircleProps['icon']
  iconIndicator?: ConfirmCircleProps['iconIndicator']
  size?: ConfirmCircleProps['size']
  color?: ConfirmCircleProps['color']
}

export interface ConfirmEventProps extends ConfirmModalProps {
  title: React.ReactNode
  message?: React.ReactNode
  confirmCircle?: IConfirmCircle
}

export const useConfirmEventControl = (initialProps?: ConfirmEventProps) => {
  const modalMethods = useModal(CONFIRM_EVENT_MODAL_ID)

  return {
    visible: modalMethods.visible,
    show: (props?: Partial<ConfirmEventProps>) => modalMethods.show({ ...initialProps, ...props }),
    remove: modalMethods.remove,
    resolve: modalMethods.resolve,
    reject: modalMethods.reject,
  }
}

export const ConfirmEvent = create<ConfirmEventProps>(
  ({
    title,
    message,
    confirmCircle = {
      icon: AlertIcon,
      color: 'peach',
      size: 'medium',
    },
    ...confirmProps
  }) => {
    const modalMethods = useConfirmEventControl()

    return (
      <Confirm open={modalMethods.visible} onOpenChange={modalMethods.remove} {...confirmProps}>
        <ConfirmCircle {...confirmCircle} />

        <Stack justify='center' align='center' gap={8}>
          <Typography variant='h3' align='center'>
            {title}
          </Typography>
          {message && (
            <Typography variant='b2' align='center' className='fcSecondary'>
              {message}
            </Typography>
          )}
        </Stack>
      </Confirm>
    )
  },
)
