import * as yup from 'yup'

import { staffModelSchema } from '../staff'

export const orderContactInformationSchema = yup.object({
  id: yup.string().defined(),
  orderId: yup.string().defined(),
  fullName: yup.string().nullable().defined(),
  phone: yup.string().nullable().defined(),
  email: yup.string().nullable().defined(),
  privateEmail: yup.string().nullable().defined(),
  staff: staffModelSchema.nullable().defined(),
  staffId: yup.string().nullable().defined(),
  receiverSignatureNeeded: yup.boolean().defined(),
  receiverId: yup.string().nullable().defined(),
  updateEntity: yup.boolean().defined(),
})

export type OrderContactInformationModel = yup.InferType<typeof orderContactInformationSchema>
