import i18n from '@assets/locales/i18n'
import type { IconProps } from '@dots/uikit'
import ArchiveIcon from '@dots/uikit/icons/archive.svg'
import AssignIcon from '@dots/uikit/icons/assign.svg'
import ChangeLocationIcon from '@dots/uikit/icons/locations.svg'
import MoveToStorageIcon from '@dots/uikit/icons/move-to-storage.svg'
import NoAllocationIcon from '@dots/uikit/icons/noassign.svg'
import type { PurchaseOrderAssetsAllocationActionType } from '@share/schemas'

export const AFTER_COMPLETION_ACTION_CONFIG: Record<
  PurchaseOrderAssetsAllocationActionType,
  { icon: IconProps['icon']; label: string }
> = {
  ASSIGN: {
    icon: AssignIcon,
    label: i18n.t('purchase.after-completion.actions.assign'),
  },
  MOVE_TO_STORAGE: {
    icon: MoveToStorageIcon,
    label: i18n.t('purchase.after-completion.actions.move-to-storage'),
  },
  CHANGE_LOCATION: {
    icon: ChangeLocationIcon,
    label: i18n.t('purchase.after-completion.actions.change-location'),
  },
  ARCHIVE: {
    icon: ArchiveIcon,
    label: i18n.t('purchase.after-completion.actions.archive'),
  },
  NO_ACTIONS: {
    icon: NoAllocationIcon,
    label: i18n.t('purchase.after-completion.actions.no-allocation'),
  },
}

export const ACTIONS_WITH_ASSIGNEE = ['ASSIGN', 'MOVE_TO_STORAGE']
export const ASSIGNEE_TYPES_WITH_ADDRESS = ['STAFF', 'STAFF_IN_OFFICE', 'SPACE']
export const STAFF_TYPES = ['STAFF', 'STAFF_IN_OFFICE']
