import * as yup from 'yup'

export const assetDPNModelSchema = yup.object({
  brandId: yup.string().defined(),
  companyId: yup.string().defined(),
  counter: yup.number().defined(),
  dpn: yup.string().defined(),
  productCode: yup.number().nullable().defined(),
  subcategoryId: yup.string().defined(),
  syncedWithNetsuite: yup.boolean().defined(),
  id: yup.string().defined(),
  name: yup.string().defined(),
})

export type AssetDPNModel = yup.InferType<typeof assetDPNModelSchema>
