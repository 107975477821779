import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { measurementSystemSchema } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const requestSchema = yup.object().shape({
  depth: yup.number().defined().required(),
  width: yup.number().defined().required(),
  height: yup.number().defined().required(),
  measurementSystem: measurementSystemSchema.defined().required(),
})
const responseSchema = yup.object().shape({
  volumetricWeight: yup.number().defined(),
})

export type GetVolumetricWeightRequest = yup.InferType<typeof requestSchema>
type GetVolumetricWeightResponse = yup.InferType<typeof responseSchema>

export const getVolumetricWeightRequest = async (data: GetVolumetricWeightRequest) =>
  apiRequest<GetVolumetricWeightResponse, GetVolumetricWeightRequest>({
    method: 'POST',
    url: API_LIST.dictionaries.volumetricWeight,
    data,
    requestSchema,
    responseSchema,
  })

export const useGetVolumetricWeight = () => useMutation(getVolumetricWeightRequest)
