import { forwardRef } from 'react'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import s from './icon.module.scss'

export type Size = 'small' | 'medium' | 'large' | 'mlarge' | 'xlarge' | 'xxlarge'

const sizes = {
  small: {
    width: 12,
    height: 12,
  },
  medium: {
    width: 16,
    height: 16,
  },
  large: {
    width: 24,
    height: 24,
  },
  mlarge: {
    width: 32,
    height: 32,
  },
  xlarge: {
    width: 40,
    height: 40,
  },
  xxlarge: {
    width: 120,
    height: 120,
  },
}

export interface IconProps extends ClassName {
  size?: Size
  icon: React.FC<React.SVGProps<SVGElement>>
  onClick?: () => void
}

export const Icon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  const { className, size = 'medium', icon: IconSvg, onClick } = props

  const rootClassName = cn(
    s.root,
    {
      [s[size]]: size,
    },
    className,
  )

  return (
    <div className={rootClassName} ref={ref} onClick={onClick}>
      <IconSvg {...sizes[size]} />
    </div>
  )
})

Icon.displayName = 'Icon'
