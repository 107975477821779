import { AssetsStatuses, AssetsViewType, PartialPaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { assetModelSchema, paginationResponseSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import { InferType } from 'yup'

export interface GetAssetsRequest extends PartialPaginationState {
  sortBy?: string
  sortOrder?: string
  search?: string
  name?: string | string[]
  status?: AssetsStatuses | AssetsStatuses[]
  serialNumber?: string | string[]
  warranty?: string | string[]
  subcategoryId?: string | string[]
  staffId?: string | string[]
  warehouseId?: string | string[]
  officeRoomId?: string | string[]
  viewType?: AssetsViewType
}

const responseSchema = paginationResponseSchema(assetModelSchema)

type GetAssetsResponse = InferType<typeof responseSchema>

const getAssetsRequest = (params: GetAssetsRequest) =>
  apiRequest<GetAssetsResponse>({
    url: API_LIST.asset.root,
    params,
    responseSchema,
  })

export const useGetAssets = (params: GetAssetsRequest) =>
  useQuery<GetAssetsResponse, Error>({
    queryKey: [API_TAGS.assets, params],
    queryFn: () => getAssetsRequest(params),
    keepPreviousData: true,
  })
