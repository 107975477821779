import { PartialPaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { paginationResponseSchema } from '@share/schemas'
import { productIceCatParsedModelSchema } from '@share/schemas/product/product-icecat.schemas'
import { useQuery } from '@tanstack/react-query'
import { InferType } from 'yup'

interface GetIceCatProductRequest extends PartialPaginationState {
  search?: string
}

const responseSchema = paginationResponseSchema(productIceCatParsedModelSchema)

type GetCategoriesResponse = InferType<typeof responseSchema>

const getAssetIceCatProductRequest = async (params?: GetIceCatProductRequest) =>
  apiRequest<GetCategoriesResponse>({
    method: 'GET',
    url: API_LIST.product.icecat,
    params,
    responseSchema,
  })

export const useGetAssetIceCatProduct = (params?: GetIceCatProductRequest) =>
  useQuery({
    queryKey: [API_TAGS.icecatProduct, params],
    queryFn: () => getAssetIceCatProductRequest(params),
    enabled: Boolean(params),
  })
