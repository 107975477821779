import { Colors, CompanyStatuses } from '@share/@types'
import { CompanyStatusesEnum } from '@share/constants'

export type StatusConfig = {
  label: string
  color: Colors
}

export const companyStatusesConfig: Record<CompanyStatuses, StatusConfig> = {
  ACTIVE: {
    label: CompanyStatusesEnum.ACTIVE,
    color: 'forestGreen',
  },
  INACTIVE: {
    label: CompanyStatusesEnum.INACTIVE,
    color: 'gray',
  },
}
