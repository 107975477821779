import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Comment } from '@dots/uikit'
import {
  Form,
  FormAttachmentsControl,
  FormSelectControl,
  FormSwitchControl,
  FormTextareaControl,
  FormTextControl,
  Stack,
} from '@share/components/ui'
import { OrderModel } from '@share/schemas'

import { OrderDisputeFormValues, OrderDisputeList } from '../../../libs'

interface OrderDisputeFormProps {
  control: Control<OrderDisputeFormValues>
  issuesList: OrderDisputeList
  disabled?: boolean
  lastComment?: OrderModel['lastComment']
}

export const OrderDisputeForm: React.FC<OrderDisputeFormProps> = ({
  control,
  issuesList,
  disabled,
  lastComment,
}) => {
  const { t } = useTranslation()

  return (
    <Form.Root>
      <Stack gap={20}>
        <FormTextControl
          name='requesterEmail'
          control={control}
          label={t('orders.modals.dispute.requester-email')}
          placeholder={t('orders.modals.dispute.requester-email-placeholder')}
          disabled={disabled}
        />
        <FormSelectControl
          name='issueType'
          control={control}
          label={t('orders.modals.dispute.issue-type')}
          placeholder={t('orders.modals.dispute.issue-type-placeholder')}
          list={issuesList}
          disabled={disabled}
        />
        <FormTextControl
          name='subject'
          control={control}
          label={t('orders.modals.dispute.subject')}
          placeholder={t('orders.modals.dispute.subject-placeholder')}
          disabled={disabled}
        />
        <FormTextareaControl
          minRows={3}
          maxRows={5}
          control={control}
          name='description'
          label={t('orders.modals.dispute.description')}
          placeholder={t('orders.modals.dispute.description-placeholder')}
          disabled={disabled}
        />
        <FormAttachmentsControl
          control={control}
          name='attachments'
          preview={disabled}
          disabled={disabled}
        />
        {lastComment && !disabled && (
          <>
            <FormSwitchControl
              control={control}
              name='comment.keepPrevious'
              text='Keep previous order comment'
            />
            <Comment
              type='info'
              title={lastComment.title}
              content={lastComment.content}
              slotAction={
                <Button size='small' variant='transparent'>
                  {t('actions.learn-more')}
                </Button>
              }
            />
          </>
        )}
      </Stack>
    </Form.Root>
  )
}
