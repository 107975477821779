import { Dialog } from '@share/components/ui'

interface OrderActionDialogProps extends Dialog.HeaderProps {
  open: boolean
  withNoScroll?: boolean
  slotFooter: React.ReactNode
  slotMessage?: React.ReactNode
  slotSubheader?: React.ReactNode
  onOpenChange: (isOpen: boolean) => void
}

export const OrderActionDialog: React.FC<OrderActionDialogProps> = ({
  open,
  title,
  subTitle,
  children,
  slotMessage,
  slotFooter,
  slotSubheader,
  withNoScroll,
  onOpenChange,
}) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    <Dialog.Container>
      <Dialog.Header title={title} subTitle={subTitle}>
        {slotSubheader && <Dialog.Subheader>{slotSubheader}</Dialog.Subheader>}
      </Dialog.Header>

      <Dialog.Content withNoScroll={withNoScroll}>{children}</Dialog.Content>

      {slotMessage && <Dialog.SubContent>{slotMessage}</Dialog.SubContent>}

      {slotFooter}
    </Dialog.Container>
  </Dialog.Root>
)
