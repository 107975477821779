import * as yup from 'yup'

export const updateModelSchema = yup.object({
  id: yup.string().defined(),
  title: yup.string().defined(),
  text: yup.string().defined(),
  link: yup.string().defined().nullable(),
  showUpdate: yup.boolean().defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  admin: yup
    .object({
      id: yup.string().defined(),
      email: yup.string().defined(),
      fullName: yup.string().defined(),
    })
    .defined()
    .nullable(),
})

export type UpdateModel = yup.InferType<typeof updateModelSchema>
export type UpdateMutationModel = Partial<
  Pick<UpdateModel, 'title' | 'text' | 'link' | 'showUpdate'>
>
