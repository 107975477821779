import * as yup from 'yup'

export const currencyModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  symbol: yup.string().defined(),
  code: yup.string().defined(),
})

export type CurrencyModel = yup.InferType<typeof currencyModelSchema>
