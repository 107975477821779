import * as yup from 'yup'

import { assetSpecsModelSchema } from '../asset'
import { brandModelSchema } from '../brand'
import { categoryModelSchema, subcategoryModelSchema } from '../categories'

import { productAdditionalServicesSchema } from './product-additional-service.schemas'
import { possibleProductSource, ProductSource } from './product-source.schemas'
import { productSpecSchema } from './product-specs.schemas'

export const possibleProductType = ['ALTERNATIVE'] as const

export const productModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  model: yup.string().nullable().defined(),
  sku: yup.string().defined(),
  price: yup.number().defined(),
  priceWithoutMargin: yup.number().defined(),
  pricePerUnit: yup.number().defined(),
  pricePerUnitWithoutMargin: yup.number().defined(),
  image: yup.string().defined(),
  vendor: yup.string().optional(),
  brand: brandModelSchema.defined(),
  brandId: yup.string().defined(),
  notes: yup.string().optional(),
  category: categoryModelSchema.defined(),
  productCode: yup.string().nullable().defined(),
  subcategory: subcategoryModelSchema.defined(),
  source: yup.mixed<ProductSource>().defined().oneOf(possibleProductSource),
  specs: assetSpecsModelSchema.optional(),
  features: yup.array(yup.string().defined()).defined(),
  available: yup.boolean().defined(),
  saved: yup.boolean().defined(),
  additionalServices: productAdditionalServicesSchema.defined(),
  isKeyboardLayoutNeeded: yup.boolean().defined(),
  isPowerOutletNeeded: yup.boolean().defined(),
  keyboardLayouts: yup.array(yup.string().defined()).defined(),
  powerOutlets: yup.array(yup.string().defined()).defined(),
  deletedAt: yup.string().nullable(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  vendorId: yup.string().defined().nullable(),
  packageIndex: yup.number().defined().nullable(),
})

export const productMutationRequestSchema = yup.object({
  type: yup.mixed().oneOf(possibleProductType).optional(),
  name: yup.string().defined().required(),
  model: yup.string().defined().required(),
  image: yup.string().optional(),
  productCode: yup.string().defined().required(),
  brandId: yup.string().defined().required(),
  subcategoryId: yup.string().defined().optional(),
  features: yup.array(yup.string()).nullable().defined(),
  specs: yup.array(productSpecSchema).nullable().defined(),
})

export type ProductModel = yup.InferType<typeof productModelSchema>

export type ProductsModel = ProductModel[]

export type ProductMutationRequest = yup.InferType<typeof productMutationRequestSchema>
