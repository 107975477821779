import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { Props, PropsValue } from 'react-select'
import cn from 'classnames'

import { Typography } from '../../atoms'

import { PreviewOption } from './preview-components/preview-option'
import { PreviewSingleValue } from './preview-components/preview-single-value'
import { SelectDropdownIndicator } from './dropdown-indicator'
import { SelectLoadingIndicator } from './loading-indicator'
import { SelectOption, SelectOptions } from './types'

import s from './select.module.scss'

export interface SelectProps {
  type?: 'default' | 'preview'
  disabled?: boolean
  invalid?: boolean
  loading?: boolean
  placeholder?: Props['placeholder']
  list: SelectOptions
  value: PropsValue<string | number | { type: string; value: string | number }> | undefined
  isMulti?: boolean
  noOptionsMessage?: string
  menuPortalTarget?: HTMLElement | null
  menuShouldBlockScroll?: boolean
  onChange: (value: PropsValue<string | number | { type: string; value: string | number }>) => void
}

export const Select = forwardRef<never, SelectProps>(
  (
    {
      type = 'default',
      list,
      placeholder,
      invalid,
      disabled,
      loading,
      value,
      noOptionsMessage,
      menuPortalTarget,
      menuShouldBlockScroll,
      isMulti,
      onChange,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const handleChange = useCallback(
      (newValue: SelectOption | SelectOption[] | null) => {
        if (!newValue) return

        if (Array.isArray(newValue)) {
          onChange(newValue.map((item) => item.value))
          return
        }

        onChange(newValue.value)
      },
      [onChange],
    )

    const classNames = useMemo(
      () => ({
        menu: () => s.menu,
        menuPortal: () => s.root,
        menuList: () => s.menuList,
        control: ({ isDisabled }: { isDisabled: boolean }) =>
          cn({ [s.disabled]: isDisabled, [s.invalid]: invalid }),
        option: ({ isSelected, isDisabled }: { isSelected: boolean; isDisabled: boolean }) =>
          cn({ [s.selected]: isSelected, [s.disabled]: isDisabled }),
        indicatorsContainer: () => s.indicatorsContainer,
      }),
      [invalid],
    )

    const selectOption = useMemo(() => {
      if (!value) return null

      if (Array.isArray(value)) {
        return value.map((item) => list.find((option) => option.value === item))
      }

      return list.find((option) => option.value === value)
    }, [list, value])

    const components = useMemo(() => {
      const baseComponents = {
        DropdownIndicator: () => <SelectDropdownIndicator />,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => <SelectLoadingIndicator />,
        NoOptionsMessage: () => (
          <Typography variant='c2' align='center'>
            {noOptionsMessage || t('select.title')}
          </Typography>
        ),
      }
      const previewComponents = {
        Option: PreviewOption,
        SingleValue: PreviewSingleValue,
      }

      return {
        ...baseComponents,
        ...(type === 'preview' ? previewComponents : {}),
      }
    }, [noOptionsMessage, t, type])

    return (
      <ReactSelect
        className={cn(s.root, { [s[type]]: type })}
        classNames={classNames}
        options={list}
        placeholder={placeholder}
        components={components as Props['components']}
        isDisabled={disabled}
        isLoading={loading}
        isMulti={isMulti}
        onChange={handleChange as Props['onChange']}
        value={selectOption}
        menuPlacement='auto'
        menuShouldBlockScroll={menuShouldBlockScroll}
        menuPortalTarget={menuPortalTarget}
        ref={ref}
      />
    )
  },
)

Select.displayName = 'Select'
