import { Logo } from '@share/components/ui'
import cn from 'classnames'

import { useSidebarStore } from '../../../model'
import { ButtonCollapse } from '../../atoms'

import s from './topbar.module.scss'

export const TopBar: React.FC = () => {
  const { isCollapsed } = useSidebarStore()

  return (
    <div className={cn(s.root, { [s.open]: !isCollapsed })}>
      {!isCollapsed && <Logo />}

      <ButtonCollapse />
    </div>
  )
}
