import { forwardRef } from 'react'
import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { Label } from '../label'

import s from './menu-button.module.scss'

export interface MenuButtonProps extends Children, ClassName {
  buttonClassName?: string
  label?: string
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>((props, ref) => {
  const { children, className, label, buttonClassName, ...rest } = props

  return (
    <div className={cn(s.root, className)}>
      {label && <Label>{label}</Label>}

      <button className={cn(s.button, buttonClassName)} type='button' ref={ref} {...rest}>
        {children}
      </button>
    </div>
  )
})

MenuButton.displayName = 'MenuButton'
