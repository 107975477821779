import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const requestSchema = yup.object().shape({
  id: yup.string().required(),
  pickupDate: yup.string().required(),
  deliveryMethodId: yup.string().required(),
})

const responseSchema = yup.object().shape({
  estimatedTime: yup.string().defined(),
})

export type GetShippingArrivalTimeRequest = yup.InferType<typeof requestSchema>
type GetShippingArrivalTimeResponse = yup.InferType<typeof responseSchema>

export type GetShippingArrivalTimeOptions = UseQueryOptions<GetShippingArrivalTimeResponse>

export const getShippingArrivalTimeRequest = async ({
  id,
  ...params
}: GetShippingArrivalTimeRequest) =>
  apiRequest<GetShippingArrivalTimeResponse, GetShippingArrivalTimeRequest>({
    method: 'GET',
    url: API_LIST.dictionaries.estimatedArrivalTime(id),
    params,
    requestSchema,
    responseSchema,
  })

export const useGetShippingArrivalTime = () => useMutation(getShippingArrivalTimeRequest)
