import * as yup from 'yup'

import { assetStatusSchema } from './asset-status.schemas'

export const assetChangeLogActionByModelSchema = yup.object({
  id: yup.string().defined(),
  email: yup.string().defined(),
  phone: yup.string().defined(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined(),
  companyId: yup.string().defined(),
  accessLevel: yup.string().defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
})

export const assetChangeLogStatusModelSchema = yup.object({
  old: assetStatusSchema.defined(),
  new: assetStatusSchema.defined(),
})

export const assetChangeLogFieldModelSchema = yup.object({
  field: yup.string().defined(),
  old: yup.string().nullable().defined(),
  new: yup.string().nullable().defined(),
})

export const assetChangeLogFieldsModelSchema = yup
  .array()
  .of(assetChangeLogFieldModelSchema.defined())

export const assetChangeLogModelSchema = yup.object({
  index: yup.number().defined(),
  label: yup.string().defined(),
  createdAt: yup.string().defined(),
  actionBy: assetChangeLogActionByModelSchema.nullable().defined(),
  actionType: yup.string().defined(),
  status: assetChangeLogStatusModelSchema.optional(),
  statusAction: yup.string().optional(),
  fields: assetChangeLogFieldsModelSchema.optional(),
})

export const assetChangeLogsModelSchema = yup.array().of(assetChangeLogModelSchema.defined())

export type AssetChangeLogModel = yup.InferType<typeof assetChangeLogModelSchema>
export type AssetChangeLogsModel = yup.InferType<typeof assetChangeLogsModelSchema>
