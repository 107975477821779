import * as yup from 'yup'
import { object, string } from 'yup'

import { categoryModelSchema } from '../categories/category.schemas'

const possibleProductSubcategorySpecDetailType = ['INPUT', 'SELECT', 'TEXTAREA'] as const
type ProductSubcategorySpecDetailType = (typeof possibleProductSubcategorySpecDetailType)[number]

const productSubcategorySpecDetailModelSchema = yup.object({
  id: yup.string().defined(),
  value: yup.string().defined(),
  name: yup.string().defined(),
  icecatFeatureId: yup.string().defined(),
  editable: yup.boolean().defined(),
  outputName: yup.string().defined(),
  valueType: yup.string().defined(),
  unitOfMeasure: yup.string().defined(),
  type: yup
    .mixed<ProductSubcategorySpecDetailType>()
    .defined()
    .oneOf(possibleProductSubcategorySpecDetailType),
  possibleVariants: yup.array(yup.string().defined()).defined(),
})

export const productSpecDetailsModelSchema = yup.object({
  specDetails: yup.array().of(productSubcategorySpecDetailModelSchema).defined(),
})

export type ProductSubcategorySpecDetailModel = yup.InferType<
  typeof productSubcategorySpecDetailModelSchema
>
export type ProductSubcategorySpecDetailsModel = ProductSubcategorySpecDetailModel[]

const productSubcategorySpecModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  details: yup.array(productSubcategorySpecDetailModelSchema).defined(),
})
export type ProductSubcategorySpecModel = yup.InferType<typeof productSubcategorySpecModelSchema>
export type ProductSubcategorySpecsModel = ProductSubcategorySpecModel[]

export const productSubcategoryModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  specs: yup.array(productSubcategorySpecModelSchema).defined(),
  specDetails: yup.array(yup.string()).defined(),
  code: yup.string().defined(),
  internalName: yup.string().defined(),
  category: categoryModelSchema.defined(),
})
export type ProductSubcategoryModel = yup.InferType<typeof productSubcategoryModelSchema>
export type ProductSubcategoriesModel = ProductSubcategoryModel[]

export const productSubcategoryMutationRequestSchema = object({
  name: string().defined(),
  internalName: string().defined(),
  code: string().defined(),
  categoryId: string().defined(),
  specDetails: yup.array().of(yup.string()).defined(),
})
export type ProductSubcategoryMutationRequest = yup.InferType<
  typeof productSubcategoryMutationRequestSchema
>
