import { useCallback, useMemo } from 'react'
import { asExternalLink } from '@share/utils'

import { STATIC_DPN_TEMPLATE_LINK } from '../libs'

export const useDownloadDpnTemplate = () => {
  const downloadCSVTemplate = useCallback(() => {
    window.open(asExternalLink(STATIC_DPN_TEMPLATE_LINK), '_blank')
  }, [])

  return useMemo(
    () => ({
      onDownloadCSVTemplate: downloadCSVTemplate,
    }),
    [downloadCSVTemplate],
  )
}
