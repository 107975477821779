import { components, SingleValueProps } from 'react-select'

import { AssignPreview } from '../../assign-preview'
import { SelectOption } from '../types'

export const PreviewSingleValue: React.FC<SingleValueProps<SelectOption>> = (props) => (
  <components.SingleValue {...props}>
    <AssignPreview
      image={props.data.image}
      title={props.data.label}
      subtitle={props.data.address}
      type={props.data.type}
    />
  </components.SingleValue>
)
