import * as yup from 'yup'

export const csvReportModelSchema = yup
  .object({
    success: yup.boolean().defined(),
    fileWithBrokenRecords: yup.string().optional(),
    info: yup
      .object({
        successRecordsCount: yup.number().defined(),
        failedRecordsCount: yup.number().defined(),
        totalRecordsCount: yup.number().defined(),
      })
      .defined(),
  })
  .noUnknown()

export type CSVReportModel = yup.InferType<typeof csvReportModelSchema>
