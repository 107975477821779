import * as yup from 'yup'

export const orderCancelValidationSchema = (
  cancelReasons: Array<{ label: string; value: string }>,
) =>
  yup.object().shape({
    cancelReason: yup
      .mixed()
      .oneOf(cancelReasons.map(({ value }) => value))
      .label('Reason')
      .required(),
    cancelDescription: yup
      .mixed()
      .test('Description', 'Reason field is a required field', (value, context) => {
        const { cancelReason } = context.parent

        if (cancelReason === 'OTHER') return Boolean(value)

        return true
      }),
  })
