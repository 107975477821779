import * as yup from 'yup'

export const orderFilterSchema = yup
  .object({
    name: yup.string().defined(),
    outputName: yup.string().defined(),
    specDetailId: yup.string().defined().nullable(),
    specDetailValues: yup.array(yup.string().defined()).defined(),
    unitOfMeasure: yup.string().defined().nullable(),
    valueType: yup.string().defined().nullable(),
  })
  .noUnknown()

export const ordersFiltersSchema = yup.array().of(orderFilterSchema.defined())

export type OrderFilterModel = yup.InferType<typeof orderFilterSchema>
export type OrderFiltersModel = yup.InferType<typeof ordersFiltersSchema>
