import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'

export type CreateOrderCommentRequest = {
  orderId: string
  title: string
  content: string
  notifyClient?: boolean
}

const createOrderComment = (data: CreateOrderCommentRequest) =>
  apiRequest({
    method: 'POST',
    url: API_LIST.order.root.comment.root,
    data,
  })

export const useCreateOrderComment = () => useMutation(createOrderComment)
