import CloseIcon from '@assets/icons/close.svg'
import { Close } from '@radix-ui/react-dialog'
import { Children } from '@share/@types'

import { ActionIcon, Group, Typography } from '../../../atoms'

import s from './modal.module.scss'

export interface ModalHeaderProps extends Partial<Children> {
  title?: string
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, children }) => (
  <Group className={s.header} position='apart'>
    {title && (
      <Typography component='h2' variant='h2'>
        {title}
      </Typography>
    )}

    {children && <Group className={s.headerContent}>{children}</Group>}

    <Close aria-label='Close' asChild>
      <ActionIcon className={s.close} size='small' variant='subtle' icon={CloseIcon} />
    </Close>
  </Group>
)
