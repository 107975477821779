import { Nullable } from '@share/@types'
import { Icon } from '@share/components/ui/atoms/icon'
import { ASSETS_FALLBACK_ICONS } from '@share/constants'
import { CategoryTypes } from '@share/schemas'

import s from './asset-preview.module.scss'

export interface FallbackProps {
  category?: Nullable<CategoryTypes | 'grouped'>
}

export const Fallback: React.FC<FallbackProps> = ({ category = 'other' }) => {
  const icon = category && ASSETS_FALLBACK_ICONS[category]

  return (
    <div className={s.fallback}>
      <Icon size='large' icon={icon || ASSETS_FALLBACK_ICONS.other} />
    </div>
  )
}
