import OrderLargeIcon from '@assets/icons/large/order.svg'
import {
  Breadcrumbs,
  BreadcrumbsProps,
  Group,
  Icon,
  Stack,
  Typography,
} from '@share/components/ui/atoms'
import cn from 'classnames'

import s from './order-box.module.scss'

export interface OrderBoxProps {
  title: string
  breadcrumbs: BreadcrumbsProps['list']
}

export const OrderBox: React.FC<OrderBoxProps> = ({ title, breadcrumbs }) => (
  <Group gap={12} align='middle'>
    <div className={s.orderPreview}>
      <Icon size='large' icon={OrderLargeIcon} />
    </div>

    <Stack>
      <Typography component='span' variant='b2'>
        {title}
      </Typography>

      <Breadcrumbs list={breadcrumbs} className={cn([s.sub], 'fcSecondary')} />
    </Stack>
  </Group>
)
