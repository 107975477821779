import * as yup from 'yup'

const dictionaryBatterySchema = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().defined(),
})

const dictionaryBatteriesSchema = yup.array(dictionaryBatterySchema.defined()).defined()

const dictionaryGroupedBatteriesSchema = yup.object().shape({
  label: yup.string().defined(),
  batteries: dictionaryBatteriesSchema.defined(),
})

export const dictionaryBatteriesResponseSchema = yup.object().shape({
  batteries: yup.array(dictionaryGroupedBatteriesSchema.defined()).defined(),
})

export type DictionaryBattery = yup.InferType<typeof dictionaryBatterySchema>
export type DictionaryBatteries = yup.InferType<typeof dictionaryBatteriesSchema>
export type DictionaryGroupedBatteries = yup.InferType<typeof dictionaryGroupedBatteriesSchema>
export type DictionaryBatteriesResponse = yup.InferType<typeof dictionaryBatteriesResponseSchema>
