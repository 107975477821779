import type { CompanyLegalEntity } from '@share/@types'
import * as yup from 'yup'

export const companyLegalEntitySchema: yup.Schema<CompanyLegalEntity> = yup.object({
  id: yup.string().defined(),
  vat: yup.string().defined(),
  ftid: yup.string().defined(),
  eori: yup.string().defined().nullable(),
  address: yup.string().defined().nullable(),
  name: yup.string().defined(),
  companyId: yup.string().defined(),
  country: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
    })
    .defined()
    .nullable(),
  countryId: yup.string().defined().nullable(),
  city: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
    })
    .defined()
    .nullable(),
  cityId: yup.string().defined().nullable(),
  state: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
    })
    .defined()
    .nullable(),
  stateId: yup.string().defined().nullable(),
  contactPersonName: yup.string().defined().nullable(),
  contactPersonPhone: yup.string().defined().nullable(),
  isDefault: yup.boolean().defined(),
})

export const companyLegalEntitiesSchema = yup.array().of(companyLegalEntitySchema).defined()

export type CompanyLegalEntityModel = yup.InferType<typeof companyLegalEntitySchema>
export type CompanyLegalEntitiesModel = yup.InferType<typeof companyLegalEntitiesSchema>
