import { mixed } from 'yup'

export const possibleOrderType = ['DELIVERY', 'PURCHASE'] as const
export type OrderType = (typeof possibleOrderType)[number]
export const orderTypeSchema = mixed<OrderType>().defined().oneOf(possibleOrderType)

export const possibleOrderSubtype = [
  'DELIVERY',
  'PURCHASE',
  'STOCKING',
  'ONBOARDING',
  'OFFBOARDING',
] as const
export type OrderSubtype = (typeof possibleOrderSubtype)[number]
export const orderSubtypeSchema = mixed<OrderSubtype>().defined().oneOf(possibleOrderSubtype)
