import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  intuneCount: yup.number().defined(),
  jamfCount: yup.number().defined(),
  totalCount: yup.number().defined(),
})

type GetCountOfEmptySerialNumberAssetsResponse = yup.InferType<typeof responseSchema>

const getCountOfEmptySerialNumberAssets = () =>
  apiRequest<GetCountOfEmptySerialNumberAssetsResponse>({
    method: 'GET',
    url: API_LIST.asset.emptySerialNumberCount,
    responseSchema,
  })

export const useGetCountOfEmptySerialNumberAssets = () =>
  useQuery<GetCountOfEmptySerialNumberAssetsResponse, Error>({
    queryKey: [API_TAGS.countOfEmptySerialNumberAssets],
    queryFn: getCountOfEmptySerialNumberAssets,
  })
