import * as yup from 'yup'

export const possibleProductAdditionalServiceType = ['Warranty', 'Enrollment'] as const

export type ProductAdditionalServiceType = (typeof possibleProductAdditionalServiceType)[number]

export const productAdditionalServiceSchema = yup.object({
  id: yup.string().defined(),
  type: yup
    .mixed<ProductAdditionalServiceType>()
    .oneOf(possibleProductAdditionalServiceType)
    .defined(),
  name: yup.string().defined(),
})

export const productAdditionalServicesSchema = yup
  .array()
  .of(productAdditionalServiceSchema.defined())
  .defined()

export type ProductAdditionalServiceModel = yup.InferType<typeof productAdditionalServiceSchema>

export type ProductAdditionalServicesModel = yup.InferType<typeof productAdditionalServicesSchema>
