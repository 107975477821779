import * as yup from 'yup'

import { countryModelSchema, regionModelSchema } from '../country'

export const companyWarehouseModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  companyId: yup.string().defined(),
  assetCount: yup.number().defined(),
  country: countryModelSchema.defined(),
  countryId: yup.string().defined(),
  assets: yup.array().defined(),
  image: yup.string().optional(),
  region: regionModelSchema.defined(),
  address: yup.string().defined(),
})

// TODO: update country schema
export const companyGetWarehouseByIdModelSchema = yup.object({
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  id: yup.string().defined(),
  contactPersonName: yup.string().defined(),
  contactPersonEmail: yup.string().defined(),
  contactPersonPhone: yup.string().defined(),
  name: yup.string().defined(),
  netsuiteId: yup.string().defined().nullable(),
  country: countryModelSchema.defined(),
  countryId: yup.string().defined(),
  assets: yup.array().defined(),
  region: regionModelSchema.defined(),
  assetCount: yup.number().defined(),
})

export type CompanyWarehouseModel = yup.InferType<typeof companyWarehouseModelSchema>
export type CompanyGetWarehouseByIdModel = yup.InferType<typeof companyGetWarehouseByIdModelSchema>
