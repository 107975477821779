import { Outlet } from 'react-router-dom'

import s from './auth-layout.module.scss'

export const AuthLayout = () => (
  <div className={s.root}>
    <div className={s.inner}>
      <Outlet />
    </div>
  </div>
)
