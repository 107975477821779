import { useCallback } from 'react'
import { UseFormReturn, useWatch } from 'react-hook-form'
import {
  Form,
  FormDatePickerControl,
  FormRangeDatePickerControl,
  FormSwitchControl,
  Stack,
} from '@share/components/ui'

import type { OrderActualEtaFormValues } from '../../../libs'

interface UpdateOrderActualEtaFormProps {
  formMethods: UseFormReturn<OrderActualEtaFormValues>
  commentFormSlot: React.ReactNode
}

export const UpdateOrderActualEtaForm: React.FC<UpdateOrderActualEtaFormProps> = ({
  formMethods,
  commentFormSlot,
}) => {
  const { control, setValue } = formMethods
  const isActualEtaSingleData = useWatch({ control, name: 'isActualEtaSingleData' })

  const onSwitchChange = useCallback(() => {
    setValue('actualEta', ['', ''])
  }, [setValue])

  return (
    <Form.Root>
      <Stack gap={16}>
        <FormSwitchControl
          control={control}
          name='isActualEtaSingleData'
          text='Single date (if you have specific ETA, not range)'
          onChangeCallback={onSwitchChange}
        />
        {isActualEtaSingleData ? (
          <FormDatePickerControl
            control={control}
            minDate={new Date()}
            name='actualEta'
            label='Actual ETA'
            placeholder='Actual ETA'
          />
        ) : (
          <FormRangeDatePickerControl
            control={control}
            minDate={new Date()}
            defaultValue={['', '']}
            name='actualEta'
            label='Actual ETA'
            placeholder='Actual ETA'
          />
        )}
        {commentFormSlot}
      </Stack>
    </Form.Root>
  )
}
