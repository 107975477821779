export const possibleNotificationType = [
  'EMPLOYEE_ADDED',
  'EMPLOYEE_REMOVED',
  'ADDED_EMPLOYEES',
  'EMPLOYEES_REMOVED',
  'INTEGRATION_ADDED_ONE',
  'INTEGRATION_REMOVED_ONE',
  'INTEGRATION_ADDED_MANY',
  'INTEGRATION_REMOVED_MANY',
  'INTEGRATION_REMOVED_DUE_TO_ERROR',
  'LOCATION_ADDED',
  'LOCATION_REMOVED',
  'ASSET_STATUS_ACTION',
  'ASSETS_STATUS_ACTION',
  'SPACE_ADDED',
  'SPACE_REMOVED',
  'NOTIFICATION_SYNC_DEVICE',
  'NOTIFICATION_SYNC_INTUNE_DEVICE',
  'DEVICE_INTEGRATION_ADDED',
  'DEVICE_INTEGRATION_REMOVED',
  'DEVICE_INTUNE_INTEGRATION_ADDED',
  'DEVICE_INTUNE_INTEGRATION_REMOVED',
  'OFFER_ISSUED',
  'OFFER_EXPIRING',
  'OFFER_EXPIRED',
  'ORDER_CREATED',
  'ORDER_PREPARING',
  'ORDER_SHIPPED',
  'ORDER_DELIVERED',
  'ORDER_RECEIPT_REMINDER',
  'ORDER_PAYMENT_PENDING',
  'ORDER_COMPLETED',
  'ORDER_IN_DISPUTE',
  'ORDER_DISPUTE_UPDATE',
  'ORDER_CANCELED',
  'ORDER_DISMISSED',
  'ORDER_AUTO_CONFIRMED',
] as const

export type NotificationType = (typeof possibleNotificationType)[number]
