import { forwardRef } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Children } from '@share/@types'
import { MODAL_CONTAINER_ID } from '@share/constants'
import cn from 'classnames'

import { Overlay } from '../../../../atoms'

import s from './dialog.module.scss'

export interface ContentProps extends Children {
  size?: 'small' | 'medium' | 'large' | 'xlarge'
}

export const Container = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, size = 'medium' }, forwardedRef) => {
    const modalContainer = document.getElementById(MODAL_CONTAINER_ID)

    return (
      <DialogPrimitive.Portal container={modalContainer}>
        <DialogPrimitive.Overlay asChild>
          <Overlay>
            <DialogPrimitive.Content
              ref={forwardedRef}
              className={cn(s.root, [s[size]])}
              onPointerDownOutside={(e) => e.preventDefault()}
            >
              {children}
            </DialogPrimitive.Content>
          </Overlay>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    )
  },
)

Container.displayName = 'DialogContent'

export const { Root, Trigger } = DialogPrimitive
