import { API_LIST } from '@share/constants'
import { post } from '@share/helpers'

export type UploadFileDataRequest = FormData
export type UploadFileDataResponse = {
  file: string
}

export const uploadFileRequest = async (requestData: UploadFileDataRequest) => {
  const { data } = await post<UploadFileDataResponse>(API_LIST.file, requestData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}
