import CloseIcon from '@assets/icons/close.svg'
import { Close } from '@radix-ui/react-dialog'
import { Children } from '@share/@types'

import { ActionIcon, Group, Stack, Typography } from '../../../../atoms'

import s from './dialog.module.scss'

export interface HeaderProps extends Partial<Children> {
  title?: string
  subTitle?: string
}

export const Header: React.FC<HeaderProps> = ({ title, subTitle, children }) => (
  <Stack gap={16} className={s.header}>
    <Group position='apart'>
      <Stack gap={8}>
        {title && (
          <Typography component='h2' variant='h2'>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant='b2' className='fcSecondary'>
            {subTitle}
          </Typography>
        )}
      </Stack>

      <Close aria-label='Close' asChild>
        <ActionIcon className={s.close} size='small' variant='subtle' icon={CloseIcon} />
      </Close>
    </Group>

    {children}
  </Stack>
)
