import { toast } from 'react-toastify'

import { GroupProps } from '../../atoms/group/group'
import { IconProps } from '../../atoms/icon/icon'
import { Notification } from '../../atoms/notification'

interface ToastNotificationProps {
  title: string
  message?: string
  icon?: IconProps['icon']
  align?: GroupProps['align']
  slotAction?: React.ReactNode
  contentSlotAction?: React.ReactNode
}

export const toastSuccess = (props: ToastNotificationProps) =>
  toast(<Notification type='success' {...props} />)

export const toastInfo = (props: ToastNotificationProps) =>
  toast(<Notification type='info' {...props} />)

export const toastError = (props: ToastNotificationProps) =>
  toast(<Notification type='error' {...props} />)

export const toastQuestion = (props: ToastNotificationProps) =>
  toast(<Notification type='question' {...props} />)

export const toastWarning = (props: ToastNotificationProps) =>
  toast(<Notification type='warning' {...props} />)
