import { useTranslation } from 'react-i18next'
import { create, useModal } from '@ebay/nice-modal-react'
import { ProductDetails } from '@entities/product'
import { Modal, ModalContent, ModalHeader } from '@share/components/ui'

import { VIEW_CATALOG_PRODUCT_MODAL_ID, ViewCatalogProductModalProps } from '../../libs'

import s from './view-catalog-product-modal.module.scss'

export const ViewCatalogProductModal = create<ViewCatalogProductModalProps>(({ product }) => {
  const { t } = useTranslation()
  const modalMethods = useModal(VIEW_CATALOG_PRODUCT_MODAL_ID)

  return (
    <Modal open={modalMethods.visible} onOpenChange={modalMethods.remove}>
      <ModalHeader title={t('catalog.view-catalog-product.title')} />

      <ModalContent className={s.content} height={500} narrow={false}>
        <ProductDetails product={product} />
      </ModalContent>
    </Modal>
  )
})
