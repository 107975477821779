import type { NotDefinable } from '@dots/uikit'
import type { PartialPaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { paginationResponseSchema, productSubcategoryModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import type * as yup from 'yup'

type SubcategoriesRequest = PartialPaginationState & {
  categoryId?: NotDefinable<string>
}

const responseSchema = paginationResponseSchema(productSubcategoryModelSchema)

export type GetSubcategoriesResponse = yup.InferType<typeof responseSchema>

const getProductSubcategoriesRequest = async (params?: SubcategoriesRequest) =>
  apiRequest<GetSubcategoriesResponse>({
    url: API_LIST.subcategory.root,
    params,
    responseSchema,
  })

export const useGetProductSubcategories = (params?: SubcategoriesRequest) =>
  useQuery<GetSubcategoriesResponse, Error>({
    queryKey: [API_TAGS.productSubcategories, params],
    queryFn: () => getProductSubcategoriesRequest(params),
    keepPreviousData: true,
  })

export const invalidateProductSubcategories = () =>
  queryClient.invalidateQueries([API_TAGS.productSubcategories])
