import * as yup from 'yup'

export const deliveryProofSchema = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().defined(),
  orderDeliveryId: yup.string().defined(),
  size: yup.string().defined(),
  url: yup.string().defined(),
})

export const deliveryProofsSchema = yup.array().of(deliveryProofSchema)

export type DeliveryProofModel = yup.InferType<typeof deliveryProofSchema>
export type DeliveryProofsModel = yup.InferType<typeof deliveryProofsSchema>
