import * as yup from 'yup'

import type { OfficeType } from '../office/office-type.schema'
import { possibleOfficeType } from '../office/office-type.schema'
import { orderTypeSchema } from '../order/order-type.schemas'

export const possiblePurchaseOrderAssetsAllocationAction = [
  'ASSIGN',
  'MOVE_TO_STORAGE',
  'CHANGE_LOCATION',
  'ARCHIVE',
  'NO_ACTIONS',
] as const
export type PurchaseOrderAssetsAllocationActionType =
  (typeof possiblePurchaseOrderAssetsAllocationAction)[number]

export const possiblePurchaseOrderAssetsAllocationActionSchema = yup
  .mixed<PurchaseOrderAssetsAllocationActionType>()
  .oneOf(possiblePurchaseOrderAssetsAllocationAction)

export const possiblePurchaseOrderAssetAssigneeType = [
  ...possibleOfficeType,
  'COUNTRY',
  'STAFF_IN_OFFICE',
] as const
export type PurchaseOrderAssetAssigneeType = (typeof possiblePurchaseOrderAssetAssigneeType)[number]

export const purchaseOrderAssetAssigneeSchema = yup.object({
  id: yup.string(),
  type: yup
    .mixed<PurchaseOrderAssetAssigneeType>()
    .oneOf(possiblePurchaseOrderAssetAssigneeType)
    .defined(),
  name: yup.string(),
  image: yup.string().nullable(),
  address: yup.string().nullable(),
  office: yup
    .object({
      image: yup.string().nullable(),
      name: yup.string().defined(),
    })
    .nullable(),
  email: yup.string().defined(),
})
export type PurchaseOrderAssetAssigneeModel = yup.InferType<typeof purchaseOrderAssetAssigneeSchema>

export const purchaseOrderAssetsAllocationActionSchema = yup
  .object({
    default: yup.boolean().defined(),
    disabled: yup.boolean().defined(),
    assignees: yup
      .array(
        yup.object({
          id: yup.string().defined(),
          type: yup.mixed<OfficeType>().oneOf(possibleOfficeType).defined(),
          name: yup.string().defined(),
          image: yup.string().nullable(),
          address: yup.string().nullable(),
        }),
      )
      .nullable(),
    archiveOptions: yup
      .array(
        yup.object({
          label: yup.string().defined(),
          value: yup.string().defined(),
        }),
      )
      .nullable(),
    location: yup
      .object({
        id: yup.string().defined(),
        type: yup.mixed<OfficeType>().oneOf(possibleOfficeType).defined(),
        name: yup.string(),
        image: yup.string().nullable(),
        address: yup.string().nullable(),
      })
      .nullable(),
    assignee: purchaseOrderAssetAssigneeSchema.nullable(),
  })
  .noUnknown()

export type PurchaseOrderAssetsAllocationActionModel = yup.InferType<
  typeof purchaseOrderAssetsAllocationActionSchema
>

const dynamicPurchaseOrderAssetsAllocationSchema: Record<
  PurchaseOrderAssetsAllocationActionType,
  yup.ObjectSchema<PurchaseOrderAssetsAllocationActionModel>
> = possiblePurchaseOrderAssetsAllocationAction.reduce((acc, action) => {
  acc[action] = purchaseOrderAssetsAllocationActionSchema
  return acc
}, {} as Record<PurchaseOrderAssetsAllocationActionType, yup.ObjectSchema<PurchaseOrderAssetsAllocationActionModel>>)

export const purchaseOrderAssetsAllocationSchema = yup.object({
  modals: yup.object().shape(dynamicPurchaseOrderAssetsAllocationSchema),
})

export type PurchaseOrderAssetsAllocationModel = yup.InferType<
  typeof purchaseOrderAssetsAllocationSchema
>

export const purchaseOrderAssetsAllocationRequestSchema = yup
  .object({
    orderType: orderTypeSchema.defined(),
    officeId: yup.string().nullable(),
    officeSpaceId: yup.string().nullable(),
    staffId: yup.string().nullable(),
    warehouseId: yup.string().nullable(),
    archive: yup.boolean().defined(),
    archiveReason: yup.string().nullable(),
  })
  .noUnknown()

export type PurchaseOrderAssetsAllocationRequest = yup.InferType<
  typeof purchaseOrderAssetsAllocationRequestSchema
>

export const possiblePurchaseOrderDeliveryInfoAssigneeType = [
  'STAFF',
  'OFFICE',
  'WAREHOUSE',
  'COUNTRY',
] as const
export type PurchaseOrderDeliveryInfoAssigneeType =
  (typeof possiblePurchaseOrderDeliveryInfoAssigneeType)[number]
