import { memo } from 'react'

import { RadioBox, ScrollArea } from '../../atoms'

import { ListBoxItem, ListBoxItemProps } from './list-item'

import s from './list-box-menu.module.scss'

export interface ListItemsProps extends RadioBox.RadioGroupProps {
  list: ListBoxItemProps[]
}

export const ListItems: React.FC<ListItemsProps> = memo(({ list = [], ...rest }) => (
  <div className={s.area}>
    <ScrollArea className={s.scroll} type='auto'>
      <RadioBox.Root {...rest}>
        {list.map((item) => (
          <ListBoxItem key={item.id} {...item} />
        ))}
      </RadioBox.Root>
    </ScrollArea>
  </div>
))

ListItems.displayName = 'ListItems'
