import { Nullable } from '@dots/uikit'
import { Undefinable } from '@share/@types'
import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { AttachmentModel, OrderModel, OrderStatus } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'

export interface UpdatePurchaseOrderDelivery {
  addressLine1: string
  addressLine2?: string
  city: string
  state: Undefinable<string>
  zipCode: string
  staffId?: string | null
  officeId?: string | null
  warehouseId?: string | null
  notes?: string
}

export interface UpdatePurchaseOrderContactInformation {
  fullName: string
  phone: string
  email: string
  staffId?: Nullable<string>
  receiverSignatureNeeded: boolean
  receiverId?: Nullable<string>
}

type ShippingInfo = {
  shippingMethodId: string
  requestedPickUpDate: [Nullable<string>, Nullable<string>]
}

export interface UpdatePurchaseOrderRequestData {
  status?: OrderStatus
  notes?: string
  paymentInformationId?: string
  companyLegalEntityId?: string
  delivery?: UpdatePurchaseOrderDelivery
  contactInformation?: Nullable<UpdatePurchaseOrderContactInformation>
  purchaseOrderNumber?: Nullable<string>
  purchaseQuote?: Nullable<AttachmentModel>
  shippingInfo?: ShippingInfo
  customOrderName?: string
}

export interface UpdatePurchaseOrderRequestArgs {
  orderId: OrderModel['id']
  requestData: UpdatePurchaseOrderRequestData
}

const updatePurchaseOrder = async ({ orderId, requestData }: UpdatePurchaseOrderRequestArgs) =>
  apiRequest({
    method: 'PATCH',
    url: API_LIST.order.purchase.slug.id(orderId),
    data: requestData,
  })

export const useUpdatePurchaseOrder = () => useMutation(updatePurchaseOrder)
