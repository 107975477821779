import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  getOrderCancelValidationSchema,
  orderCancelDefaultFormValues,
  OrderCancelFormValues,
  OrderCancelReasons,
} from '../libs'

interface UseOrderCancelFormProps {
  values?: OrderCancelFormValues
  cancelReasons: OrderCancelReasons
}

export const useOrderCancelForm = ({ values, cancelReasons }: UseOrderCancelFormProps) =>
  useForm<OrderCancelFormValues>({
    values,
    defaultValues: orderCancelDefaultFormValues,
    resolver: yupResolver(getOrderCancelValidationSchema(cancelReasons)),
  })
