import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Separator, Typography } from '@dots/uikit'
import { create, useModal } from '@ebay/nice-modal-react'
import { OrderCommentForm } from '@entities/order/order-comment-form'

import type { UpdateOrderActualEtaDialogProps } from '../../../libs'
import { useUpdateOrderActualEtaController } from '../../../model'
import { UpdateOrderActualEtaForm } from '../../molecules'

export const UpdateOrderActualEtaDialog = create<UpdateOrderActualEtaDialogProps>(
  ({ orderId, onAfterUpdate }) => {
    const { t } = useTranslation()
    const modalMethods = useModal()

    const updateOrderActualEtaController = useUpdateOrderActualEtaController({
      orderId,
      modalMethods,
      onAfterUpdate,
    })

    const orderCommentFormSlot = (
      <FormProvider {...updateOrderActualEtaController.updateOrderActualEtaFormMethods}>
        <OrderCommentForm withNotifyClientSwitch />
      </FormProvider>
    )

    return (
      <Dialog.Root open={modalMethods.visible} onOpenChange={modalMethods.remove}>
        <Dialog.Content>
          <Dialog.Header>
            <Typography variant='h2'>{t('orders.modals.actual-eta.title')}</Typography>
          </Dialog.Header>
          <Separator />

          <Dialog.Body noPadding={false} size='medium'>
            <UpdateOrderActualEtaForm
              formMethods={updateOrderActualEtaController.updateOrderActualEtaFormMethods}
              commentFormSlot={orderCommentFormSlot}
            />
          </Dialog.Body>
          <Separator />

          <Dialog.Footer>
            <Button
              onClick={updateOrderActualEtaController.onUpdateOrderActualEta}
              loading={updateOrderActualEtaController.loading}
            >
              {t('actions.confirm')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    )
  },
)
