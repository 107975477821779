import { Indicator, Item, RadioGroupItemProps } from '@radix-ui/react-radio-group'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import s from './radio.module.scss'

export interface RadioButtonProps extends ClassName {
  id: RadioGroupItemProps['id']
  value: RadioGroupItemProps['value']
  checked?: RadioGroupItemProps['checked']
  disabled?: RadioGroupItemProps['disabled']
}

export const RadioButton: React.FC<RadioButtonProps> = ({ className, id, disabled, ...rest }) => (
  <Item
    id={id}
    className={cn(s.root, { [s.disabled]: disabled }, className)}
    disabled={disabled}
    {...rest}
  >
    <div className={s.frame}>
      <Indicator className={s.indicator} />
    </div>
  </Item>
)
