import * as yup from 'yup'

import { iceCatModelSchema } from './icecat.schemas'

export const iceCatParsedModelSchema = yup.object({
  description: yup.array(yup.string().defined()).defined(),
  parsed: iceCatModelSchema.defined(),
})

export type IceCatParsedModel = yup.InferType<typeof iceCatParsedModelSchema>
