import { isAxiosError } from 'axios'
import { Schema } from 'yup'

export type ErrorSerialized = {
  title: string
  message: string
}

export const getAxiosErrorMessage = (error: Error): ErrorSerialized => {
  if (isAxiosError(error)) {
    const { response } = error
    const title = response?.data?.title ?? 'Error'
    const message = response?.data?.error ?? error.message

    return {
      title,
      message,
    }
  }

  return {
    title: 'Error',
    message: 'Something went wrong',
  }
}

export const validateAPIData = <D>(data: D, validationSchema: Schema) =>
  validationSchema.validate(data, { stripUnknown: false })
