import { PaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { paginationResponseSchema } from '@share/schemas'
import { currencyModelSchema } from '@share/schemas/currency'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = paginationResponseSchema(currencyModelSchema)
type GetCurrenciesResponse = yup.InferType<typeof responseSchema>

interface GetCurrenciesRequest extends Partial<PaginationState> {
  name?: string
  sortOrder?: string
  sortBy?: string
}
type UseGetCurrencies = {
  params?: GetCurrenciesRequest
  options?: UseQueryOptions<GetCurrenciesResponse>
}

const getCurrencies = async (params?: GetCurrenciesRequest) =>
  apiRequest<GetCurrenciesResponse>({
    method: 'GET',
    url: API_LIST.currency.root,
    params,
    responseSchema,
  })

export const useGetCurrencies = ({ params, options }: UseGetCurrencies) =>
  useQuery({
    queryKey: [API_TAGS.currencies, params],
    queryFn: () => getCurrencies(params),
    ...options,
  })
