import * as yup from 'yup'

const possibleAssetSourceType = ['MANUAL', 'ICECAT'] as const

export type AssetSourceType = (typeof possibleAssetSourceType)[number]

export const assetSourceTypeSchema = yup
  .mixed<AssetSourceType>()
  .defined()
  .oneOf(possibleAssetSourceType)
