import CloseIcon from '@assets/icons/close-round-bordered.svg'
import RetryIcon from '@assets/icons/retry.svg'

import { Button, Group, Icon, Stack, Typography } from '../../atoms'

export type DropzoneErrorProps = {
  loading?: boolean
  onRetry: () => void
  onCancel: () => void
}

export const DropzoneError: React.FC<DropzoneErrorProps> = ({ loading, onRetry, onCancel }) => (
  <Stack gap={16} align='center'>
    <Icon icon={CloseIcon} />
    <Stack gap={6} align='center'>
      <Typography variant='h3'>title</Typography>
      <Typography variant='b2' className='fcSecondary'>
        subtitle
      </Typography>
    </Stack>

    <Group gap={16}>
      <Button size='small' variant='subtle' color='secondary' onClick={onCancel}>
        cancel
      </Button>
      <Button icon={RetryIcon} size='small' onClick={onRetry} loading={loading}>
        retry
      </Button>
    </Group>
  </Stack>
)
