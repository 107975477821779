/* eslint-disable import/no-cycle */
import * as yup from 'yup'

import { paginationResponseSchema } from '../core.schemas'

import { countryModelSchema } from './country.schemas'
import { countrySourcesSchema } from './sources.schemas'

export const stateModelSchema = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().defined(),
  countryId: yup.string().defined(),
  country: countryModelSchema.defined(),
  source: countrySourcesSchema.defined(),
})

export const statesModelSchema = yup.array().of(stateModelSchema).defined()

export const statesPaginationSchema = paginationResponseSchema(stateModelSchema)

export type StateModel = yup.InferType<typeof stateModelSchema>
export type StatesModel = yup.InferType<typeof statesModelSchema>
