import type { ApiResponseSuccessWithMessage } from '@share/@types'
import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const purchaseShippingInfoRequestSchema = yup
  .object({
    receiverId: yup.string().defined(),
    shippingMethodId: yup.string().defined(),
    receiverSignatureNeeded: yup.boolean().defined(),
    requestedPickUpDate: yup
      .tuple([yup.string().defined().nullable(), yup.string().defined().nullable()])
      .defined(),
  })
  .defined()

export type PurchaseShippingInfoRequest = yup.InferType<typeof purchaseShippingInfoRequestSchema>

interface UpdatePurchaseOrderShippingInfoRequest {
  id: string
  requestData: PurchaseShippingInfoRequest
}

const updatePurchaseOrderShippingInfoRequest = ({
  id,
  requestData,
}: UpdatePurchaseOrderShippingInfoRequest) =>
  apiRequest<ApiResponseSuccessWithMessage>({
    method: 'PATCH',
    url: API_LIST.order.purchase.slug.shippingInfo(id),
    data: requestData,
  })

export const useUpdatePurchaseOrderShippingInfoRequest = () =>
  useMutation(updatePurchaseOrderShippingInfoRequest)
