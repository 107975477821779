import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { concatValidationSchemas } from '@share/utils'
import { Schema } from 'yup'

import {
  orderActualEtaDefaultValues,
  OrderActualEtaFormValues,
  orderActualEtaValidationSchema,
} from '../libs'

export const useUpdateOrderActualEtaForm = (commentFormValidationSchema: Schema) =>
  useForm<OrderActualEtaFormValues>({
    defaultValues: orderActualEtaDefaultValues,
    resolver: yupResolver(
      concatValidationSchemas([orderActualEtaValidationSchema, commentFormValidationSchema]),
    ),
  })
