import { Group, Typography } from '@dots/uikit'
import EditIcon from '@dots/uikit/icons/edit.svg'
import { Nullable } from '@share/@types'
import { ActionIcon } from '@share/components/ui'

import s from './order-editable-name.module.scss'

interface OrderEditableNameProps {
  name: Nullable<string>
  isEditable: boolean
  onEdit?: () => void
}

export const OrderEditableName: React.FC<OrderEditableNameProps> = ({
  name = '',
  isEditable = true,
  onEdit,
}) => (
  <Group align='middle' gap={10} className={s.root}>
    <Typography variant='h2' className='breakWord'>
      {name}
    </Typography>
    {isEditable && (
      <ActionIcon
        foreground={null}
        className={s.button}
        variant='transparent'
        icon={EditIcon}
        onClick={onEdit}
      />
    )}
  </Group>
)
