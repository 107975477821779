import { OrderStatus } from '@share/schemas'

import { Reasons } from './types'

export const CANCELED_REASONS: Reasons = [
  { label: 'Duplicated Order', value: 'DUPLICATE_ORDER' },
  { label: 'Cost Concerns', value: 'COST_CONCERNS' },
  { label: 'Timing Issues', value: 'TIMING_ISSUES' },
  { label: 'Better Offer Found', value: 'BETTER_OFFER_FOUND' },
  { label: 'Change of Plans', value: 'CHANGE_OF_PLANS' },
  { label: 'Other', value: 'OTHER' },
]

export const DISMISSED_REASONS: Reasons = [
  { label: 'Out of Stock', value: 'OUT_OF_STOCK' },
  { label: 'Payment Failure', value: 'PAYMENT_FAILURE' },
  { label: 'Suspicious Activity', value: 'SUSPICIOUS_ACTIVITY' },
  { label: 'Policy Violation', value: 'POLICY_VIOLATION' },
  { label: 'Client Unresponsive', value: 'CLIENT_UNRESPONSIVE' },
  { label: 'Delivery Restrictions', value: 'DELIVERY_RESTRICTIONS' },
  { label: 'Force Majeure', value: 'FORCE_MAJEURE' },
  { label: 'Other', value: 'OTHER' },
]

export const REASONS_LISTS: Partial<Record<OrderStatus, Reasons>> = {
  CANCELED: CANCELED_REASONS,
  DISMISSED: DISMISSED_REASONS,
}

export const ORDER_CANCEL_MODAL_ID = 'order-cancel-modal'
