import { useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Show } from '@dots/uikit'
import {
  FormSwitchControl,
  FormTextareaControl,
  FormTextControl,
  Stack,
} from '@share/components/ui'

import { OrderCommentFormValues } from '../../libs'

interface OrderCommentFormProps {
  withNotifyClientSwitch?: boolean
  withKeepPreviousSwitch?: boolean
}

export const OrderCommentForm: React.FC<OrderCommentFormProps> = ({
  withNotifyClientSwitch = false,
  withKeepPreviousSwitch = true,
}) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext<OrderCommentFormValues>()
  const keepPreviousComment = useWatch({ control, name: 'comment.keepPrevious' })

  const onKeepPreviousSwitchChange = useCallback(() => {
    setValue('comment.title', '')
    setValue('comment.content', '')

    if (withNotifyClientSwitch) {
      setValue('comment.notifyClient', false)
    }
  }, [setValue, withNotifyClientSwitch])

  return (
    <Stack gap={16}>
      <Show when={withKeepPreviousSwitch}>
        <FormSwitchControl
          control={control}
          name='comment.keepPrevious'
          text={t('orders.comment-form.keep-previous.label')}
          onChangeCallback={onKeepPreviousSwitchChange}
        />
      </Show>

      <FormTextControl
        control={control}
        name='comment.title'
        label={t('orders.comment-form.title.label')}
        placeholder={t('orders.comment-form.title.placeholder')}
        disabled={keepPreviousComment}
      />
      <FormTextareaControl
        control={control}
        minRows={3}
        maxRows={5}
        name='comment.content'
        label={t('orders.comment-form.content.label')}
        placeholder={t('orders.comment-form.content.placeholder')}
        disabled={keepPreviousComment}
      />

      <Show when={withNotifyClientSwitch}>
        <FormSwitchControl
          control={control}
          name='comment.notifyClient'
          text={t('orders.comment-form.notify-client.label')}
        />
      </Show>
    </Stack>
  )
}
