import * as yup from 'yup'

export const superAdminCompanyModelSchema = yup.object({
  id: yup.string().defined(),
  status: yup.string().defined(),
  contactPersonName: yup.string().defined(),
  contactPersonEmail: yup.string().defined(),
  contactPersonPhone: yup.string().defined(),
  address: yup.string().defined(),
  website: yup.string().defined(),
  domain: yup.string().defined(),
  dotsAccountInfo: yup.string().defined(),
  notes: yup.string().defined(),
  creditLimit: yup.number().defined(),
  paymentTermsDaysCount: yup.number().defined(),
  currencyId: yup.string().defined(),
  name: yup.string().defined(),
  scale: yup.string().defined(),
  activity: yup.string().defined(),
  createdAt: yup.date().defined(),
  updatedAt: yup.date().defined(),
  deletedAt: yup.date().nullable().defined(),
})

export type SuperAdminCompanyModel = yup.InferType<typeof superAdminCompanyModelSchema>
