import cn from 'classnames'

import { Typography } from '../../typography'

import s from './root.module.scss'

export interface DateProps {
  date: string
  dateClassname?: string
}

export const Date: React.FC<DateProps> = ({ date, dateClassname }) => (
  <Typography component='span' variant='b3' className={cn('fcSecondary', s.date, dateClassname)}>
    {date}
  </Typography>
)
