import { array, InferType, object, string } from 'yup'

export const assetAttachmentModelSchema = object({
  name: string().defined(),
  url: string().defined(),
})

export const assetAttachmentsModelSchema = array().of(assetAttachmentModelSchema.defined())

export type AssetAttachmentModel = InferType<typeof assetAttachmentModelSchema>
export type AssetAttachmentsModel = InferType<typeof assetAttachmentsModelSchema>
