import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { AssetAttachmentsModel, assetModelSchema } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

export type CreateAssetManuallyRequest = {
  name?: string
  model?: string
  sku?: string
  purchaseDate?: string
  price?: string
  warranty?: string
  serialNumber?: string
  subcategoryId?: string
  createdAt?: string
  updatedAt?: string
  attachments?: AssetAttachmentsModel
  specs?: Array<{
    specDetailId: string
    specDetailValue: string
    editable?: boolean
  }>
}

const responseSchema = yup.object({
  asset: assetModelSchema.defined().noUnknown(),
})

type CreateAssetManuallyResponse = yup.InferType<typeof responseSchema>

export const createAssetManuallyRequest = (data: CreateAssetManuallyRequest) =>
  apiRequest<CreateAssetManuallyResponse>({
    method: 'POST',
    url: API_LIST.asset.createManual,
    data,
    responseSchema,
  })

export const useCreateManuallyAsset = () =>
  useMutation({
    mutationFn: async (requestData: CreateAssetManuallyRequest) => {
      const response = await createAssetManuallyRequest(requestData)

      await Promise.all([
        queryClient.invalidateQueries([API_TAGS.assets]),
        queryClient.invalidateQueries([API_TAGS.assetsStatusesCount]),
      ])

      return response
    },
  })
