import { forwardRef } from 'react'
import { Stack } from '@share/components/ui/atoms'
import cn from 'classnames'

import {
  DropzoneConnectedToFiles,
  DropzoneConnectedToFilesProps,
} from '../dropzone-connected-to-files'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface DropzoneFieldProps extends Omit<DropzoneConnectedToFilesProps, 'showError'> {
  invalid?: boolean
  inline?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
}

export const DropzoneField = forwardRef<HTMLInputElement, DropzoneFieldProps>(
  (
    {
      messageType = 'error',
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      invalid,
      disabled,
      ...dropzoneProps
    },
    ref,
  ) => {
    const rootClassName = cn(s.root, {
      [s.errored]: invalid,
      [s.inline]: inline,
      [s.disabled]: disabled,
    })

    return (
      <div className={rootClassName}>
        <TextFieldLabel label={label} supportingText={supportingText} />

        <Stack gap={6} className={s.container}>
          <DropzoneConnectedToFiles ref={ref} disabled={disabled} {...dropzoneProps} />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      </div>
    )
  },
)

DropzoneField.displayName = 'DropzoneField'
