import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { Icon, IconProps } from '../icon/icon'
import { Typography } from '../typography'

import s from './navigation-link.module.scss'

interface NavigationLinkProps {
  to: string
  label: string
  icon: IconProps['icon']
  notice?: boolean
  variant?: 'primary' | 'secondary'
  collapsed?: boolean
}

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  to,
  label,
  icon: ItemIcon,
  notice,
  variant = 'primary',
  collapsed = false,
}) => {
  const rootClassName = useCallback(
    ({ isActive }: { isActive: boolean }) =>
      cn(s.root, {
        [s[variant]]: variant,
        [s.active]: isActive,
        [s.round]: collapsed,
      }),
    [collapsed, variant],
  )

  return (
    <NavLink className={rootClassName} to={to}>
      <Icon icon={ItemIcon} className={s.icon} />

      {!collapsed && (
        <Typography component='span' variant='c2' className={s.label}>
          {label}
        </Typography>
      )}

      {notice && (
        <span className={s.notice}>
          <span className={s.circle} />
        </span>
      )}
    </NavLink>
  )
}
