import i18n from '@assets/locales/i18n'
import { emptyStringToNull } from '@share/utils'
import * as yup from 'yup'

import { OrderTrackingInfoFormValues } from './types'

export const defaultOrderTrackingInfoFormValues: OrderTrackingInfoFormValues = {
  trackingNumber: '',
  trackingLink: '',
}

export const orderTrackingInfoValidationSchema = yup.object().shape({
  trackingNumber: yup
    .string()
    .label(i18n.t('orders.tracking-info.form.tracking-number.label'))
    .notRequired()
    .transform(emptyStringToNull),
  trackingLink: yup
    .string()
    .url()
    .label(i18n.t('orders.tracking-info.form.tracking-link.label'))
    .notRequired()
    .transform(emptyStringToNull),
})
