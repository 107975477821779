import AlertIcon from '@assets/icons/alert.svg'
import DeliveredIcon from '@assets/icons/delivered.svg'
import InfoIcon from '@assets/icons/info.svg'
import ReportIcon from '@assets/icons/report-issue.svg'
import RequestsIcon from '@assets/icons/requests.svg'
import cn from 'classnames'

import { Group, GroupProps } from '../group/group'
import { Icon, IconProps } from '../icon/icon'
import { IconHolder } from '../icon-holder'
import { Stack } from '../stack/stack'
import { Typography } from '../typography'

import s from './notification.module.scss'

const iconTypes = {
  warning: ReportIcon,
  info: InfoIcon,
  success: DeliveredIcon,
  error: AlertIcon,
  question: RequestsIcon,
}

export interface NotificationProps {
  title: string
  message?: string
  type: 'warning' | 'info' | 'success' | 'error' | 'question'
  icon?: IconProps['icon']
  align?: GroupProps['align']
  slotAction?: React.ReactNode
  contentSlotAction?: React.ReactNode
}

export const Notification: React.FC<NotificationProps> = ({
  type = 'info',
  title,
  message,
  icon,
  align = 'middle',
  slotAction,
  contentSlotAction,
}) => (
  <Group className={cn(s.root, s[type])} align={align} gap={12}>
    <IconHolder size='medium' className={s.icon}>
      <Icon size='medium' icon={icon || iconTypes[type] || iconTypes.info} />
    </IconHolder>

    <Stack className={s.content}>
      <Typography className={s.title} variant='b2'>
        {title}
      </Typography>

      {message && (
        <Typography className={s.message} variant='b3'>
          {message}
        </Typography>
      )}

      {contentSlotAction && <Group className={s.contentSlotAction}>{contentSlotAction}</Group>}
    </Stack>

    {slotAction}
  </Group>
)
