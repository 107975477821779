import { ApiResponseSuccess } from '@share/@types'
import { API_LIST } from '@share/constants'
import { destroy } from '@share/helpers'

export type DeleteFileRequest = {
  url: string
}

export const deleteFileRequest = async ({ url }: DeleteFileRequest) => {
  const { data } = await destroy<ApiResponseSuccess>(API_LIST.file, { params: { url } })

  return data
}
