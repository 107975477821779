import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { paginationResponseSchema, productModelSchema } from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = paginationResponseSchema(productModelSchema)

type GetProductsResponse = yup.InferType<typeof responseSchema>

export interface GetProductsRequest {
  params: {
    limit?: number
    page?: number
    sortBy?: string
    sortOrder?: string
    search?: string
  }
  options?: UseQueryOptions<GetProductsResponse>
}

const getCatalogProducts = async (params: GetProductsRequest['params']) =>
  apiRequest<GetProductsResponse, Error>({
    method: 'GET',
    url: API_LIST.product.root,
    params,
  })

export const useGetProducts = ({ params, options }: GetProductsRequest) =>
  useQuery({
    queryKey: [API_TAGS.product, params],
    queryFn: () => getCatalogProducts(params),
    keepPreviousData: true,
    ...options,
  })

export const invalidateProducts = () => queryClient.invalidateQueries([API_TAGS.product])
