import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { OrderLastCommentModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  id: yup.string().defined(),
  lastComment: OrderLastCommentModelSchema.defined(),
})

interface GetOrderLastCommentRequest {
  id: string
}

type GetOrdersTableResponse = yup.InferType<typeof responseSchema>

export const getOrderLastCommentRequest = ({ id }: GetOrderLastCommentRequest) =>
  apiRequest<GetOrdersTableResponse>({
    method: 'GET',
    url: API_LIST.order.root.lastComment(id),
    responseSchema,
  })

export const useGetOrderLastCommentRequest = (params: GetOrderLastCommentRequest) =>
  useQuery<GetOrdersTableResponse, Error>({
    queryKey: [API_TAGS.ORDERS.root.lastComment, params],
    queryFn: () => getOrderLastCommentRequest(params),
  })

export const invalidateOrderLastComment = (params: GetOrderLastCommentRequest) =>
  queryClient.invalidateQueries([API_TAGS.ORDERS.root.lastComment, params])
