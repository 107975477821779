import { CreateOrderCommentRequest } from '@share/hooks/api'
import { OrderLastCommentModel } from '@share/schemas'
import { emptyStringToUndefined, undefToEmptyString } from '@share/utils'
import * as yup from 'yup'

import { OrderCommentFormValues } from './types'

export const defaultOrderCommentFormValues = {
  comment: {
    title: '',
    content: '',
    keepPrevious: false,
    notifyClient: false,
  },
}

export const orderCommentValidationSchema = yup.object().shape({
  comment: yup.object().shape(
    {
      title: yup
        .string()
        .label('Comment title')
        .when('content', {
          is: (content: string) => Boolean(content),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        })
        .transform(emptyStringToUndefined),
      content: yup
        .string()
        .label('Comment content')
        .when('title', {
          is: (title: string) => Boolean(title),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        })
        .transform(emptyStringToUndefined),
      keepPrevious: yup.boolean().notRequired(),
      notifyClient: yup.boolean().notRequired(),
    },
    [['title', 'content']],
  ),
})

export const orderCommentStrictValidationSchema = yup.object().shape({
  comment: yup.object().shape({
    title: yup.string().label('Comment title').required(),
    content: yup.string().label('Comment content').required(),
    keepPrevious: yup.boolean().notRequired(),
    notifyClient: yup.boolean().notRequired(),
  }),
})

export const adaptOrderCommentFormValuesToRequest = (
  formValues: OrderCommentFormValues,
): Omit<CreateOrderCommentRequest, 'orderId'> => {
  const { title, content, notifyClient } = formValues.comment

  return {
    title: undefToEmptyString(title),
    content: undefToEmptyString(content),
    notifyClient,
  }
}

export const adaptOrderCommentToFormValues = (
  orderLastComment?: OrderLastCommentModel,
): OrderCommentFormValues => ({
  comment: {
    title: undefToEmptyString(orderLastComment?.title),
    content: undefToEmptyString(orderLastComment?.content),
    keepPrevious: false,
    notifyClient: false,
  },
})
