import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'

export type UpdateOrderActualEtaRequest = {
  id: string
  actualEta: [string, string?]
}

const updateOrderActualEta = ({ id, ...data }: UpdateOrderActualEtaRequest) =>
  apiRequest({
    method: 'PATCH',
    url: API_LIST.order.root.actualEta(id),
    data,
  })

export const useUpdateOrderActualEta = () => useMutation(updateOrderActualEta)
