import { forwardRef } from 'react'
import { Stack, Textarea, TextareaProps } from '@share/components/ui/atoms'
import cn from 'classnames'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface TextareaFieldProps extends TextareaProps {
  inline?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  assistiveText?: TextFieldMessageProps['assistiveText']
  labelIcon?: TextFieldLabelProps['icon']
  required?: boolean
}

export const TextareaField: React.FC<TextareaFieldProps> = forwardRef<
  HTMLTextAreaElement,
  TextareaFieldProps
>(
  (
    {
      messageType = 'error',
      className,
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      invalid,
      disabled,
      labelIcon,
      required,
      ...inputProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.errored]: invalid,
        [s.inline]: inline,
        [s.disabled]: disabled,
      },
      className,
    )

    return (
      <div className={rootClassName}>
        <TextFieldLabel
          label={label}
          required={required}
          supportingText={supportingText}
          icon={labelIcon}
        />

        <Stack gap={6} className={s.container}>
          <Textarea disabled={disabled} invalid={invalid} {...inputProps} ref={ref} />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      </div>
    )
  },
)

TextareaField.displayName = 'TextareaField'
