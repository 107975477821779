import { mixed } from 'yup'

export const possibleDeliveryOrderStatus = [
  'DRAFT',
  'PROCESSING',
  'PENDING_OFFER',
  'PREPARING',
  'PICKING_UP',
  'SHIPPING',
  'DELIVERED',
  'PENDING_PAYMENT',
  'COMPLETED',
  'CONFIRMED',
  'IN_DISPUTE',
  'DISMISSED',
  'CANCELED',
  'EXPIRED',
] as const

export type DeliveryOrderStatus = (typeof possibleDeliveryOrderStatus)[number]
export type DeliveryOrderStatuses = DeliveryOrderStatus[]

export const deliveryOrderStatusSchema = mixed<DeliveryOrderStatus>()
  .defined()
  .oneOf(possibleDeliveryOrderStatus)
