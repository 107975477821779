import { forwardRef } from 'react'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Attachments, AttachmentsProps, Stack } from '../../atoms'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface AttachmentsFieldProps extends ClassName, AttachmentsProps {
  inline?: boolean
  invalid?: boolean
  required?: boolean
  label?: TextFieldLabelProps['label']
  supportingText?: TextFieldLabelProps['supportingText']
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  slotBellow?: React.ReactNode
  assistiveText?: TextFieldMessageProps['assistiveText']
}

export const AttachmentsField = forwardRef<HTMLInputElement, AttachmentsFieldProps>(
  (
    {
      messageType = 'error',
      className,
      inline,
      message,
      supportingText,
      assistiveText,
      label,
      invalid,
      disabled,
      required,
      slotBellow,
      ...inputProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.inline]: inline,
        [s.disabled]: disabled,
      },
      className,
    )

    return (
      <div className={rootClassName}>
        {label && (
          <TextFieldLabel label={label} required={required} supportingText={supportingText} />
        )}

        <Stack gap={6} className={s.container}>
          <Attachments disabled={disabled} ref={ref} {...inputProps} />
          {slotBellow && <div className={s.slot}>{slotBellow}</div>}

          {(message || assistiveText || invalid) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}
        </Stack>
      </div>
    )
  },
)

AttachmentsField.displayName = 'AttachmentsField'
