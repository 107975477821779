import type { Nullable, TypographyVariants } from '@dots/uikit'
import { Group, Stack, Typography } from '@dots/uikit'
import cn from 'classnames'

import s from './root.module.scss'

export interface CaptionProps {
  title?: string
  titleVariant?: TypographyVariants
  subtitle?: Nullable<string>
  titleClassName?: string
  subtitleClassName?: string
  iconSlot?: React.ReactNode
  titleTestId?: string
  subtitleTestId?: string
}

export const Caption: React.FC<CaptionProps> = ({
  title,
  titleVariant = 'b2',
  subtitle,
  titleClassName,
  subtitleClassName,
  iconSlot,
  titleTestId,
  subtitleTestId,
}) => (
  <Stack gap={2}>
    {title && (
      <Group gap={6} align='middle'>
        <Typography
          component='span'
          variant={titleVariant}
          className={cn(s.title, titleClassName)}
          dataTestId={titleTestId}
        >
          {title}
        </Typography>
        {iconSlot}
      </Group>
    )}

    {subtitle && (
      <Typography
        component='span'
        variant='b3'
        className={cn(s.subtitle, subtitleClassName)}
        dataTestId={subtitleTestId}
        align='left'
      >
        {subtitle}
      </Typography>
    )}
  </Stack>
)
