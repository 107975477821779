import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { Group, Icon, IconProps } from '../../atoms'

import s from './with-icon.module.scss'

export interface WithIconProps extends IconProps, Children, ClassName {
  iconClassname?: string
}

export const WithIcon: React.FC<WithIconProps> = ({ icon, className, iconClassname, children }) => (
  <Group gap={8} className={className}>
    <div className={cn(s.icon, iconClassname)}>
      <Icon icon={icon} />
    </div>
    <Group gap={8}>{children}</Group>
  </Group>
)
