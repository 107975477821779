import { PaginatedResponse, PaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { paginationResponseSchema, WarehouseModel, warehouseModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'

interface GetWarehousesRequest extends Partial<PaginationState> {
  country?: string
  companyId: string
  withAssets?: boolean
}

type GetWarehousesResponse = PaginatedResponse<WarehouseModel>

const responseSchema = paginationResponseSchema(warehouseModelSchema)

const getCompanyWarehousesRequest = (params: GetWarehousesRequest) =>
  apiRequest<GetWarehousesResponse>({
    url: API_LIST.company.warehouse,
    responseSchema,
    params,
  })

export const useGetCompanyWarehouses = (params: GetWarehousesRequest) =>
  useQuery<GetWarehousesResponse, Error>({
    queryKey: [API_TAGS.companyWarehouse, params],
    enabled: Boolean(params.companyId),
    queryFn: () => getCompanyWarehousesRequest(params),
  })
