import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { paginationResponseSchema, regionModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = paginationResponseSchema(regionModelSchema)
type GetRegionsResponse = yup.InferType<typeof responseSchema>

const getRegions = async () =>
  apiRequest<GetRegionsResponse>({
    method: 'GET',
    url: API_LIST.region.root,
    responseSchema,
  })

export const useGetRegions = () =>
  useQuery({
    queryKey: [API_TAGS.region.root],
    queryFn: getRegions,
  })
