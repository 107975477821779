import DotIcon from '@assets/icons/dot.svg'
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import cn from 'classnames'

import { Icon, IconProps } from '../icon'
import { Tooltip } from '../tooltip'
import { Typography } from '../typography'

import s from './dropdown-menu.module.scss'

export type DropdownMenuItemType = {
  type?: 'button'
  label: React.ReactNode
  icon?: IconProps['icon']
  active?: boolean
  disabled?: boolean
  hasNotice?: boolean
  onClick?: () => void
  tooltip?: {
    title: string
    side?: 'left' | 'right'
    sideOffset?: number
    delayDuration?: number
  }
}

export type DropdownMenuItemDividerType = {
  type: 'divider'
}

export type DropdownMenuItems = Array<DropdownMenuItemType | DropdownMenuItemDividerType | false>

export interface DropdownMenuProps extends RadixDropdownMenu.DropdownMenuContentProps {
  trigger?: React.ReactNode
  items: DropdownMenuItems
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ items, trigger, ...rest }) => (
  <RadixDropdownMenu.Root modal={false}>
    {trigger && <RadixDropdownMenu.Trigger asChild>{trigger}</RadixDropdownMenu.Trigger>}

    <RadixDropdownMenu.Content className={s.content} align='end' sideOffset={8} {...rest}>
      {items.map((item, index) => {
        if (!item) {
          return null
        }

        if (item.type === 'divider') {
          return <RadixDropdownMenu.Separator key={index} className={s.separator} />
        }

        return (
          <RadixDropdownMenu.DropdownMenuItem
            key={index}
            className={cn(s.item, { [s.disabled]: item.disabled, [s.active]: item.active })}
            onClick={!item.disabled ? item.onClick : undefined}
            disabled={item.disabled}
          >
            {item.icon && <Icon size='medium' icon={item.icon} className={s.icon} />}

            {item.disabled ? (
              <Tooltip
                title={item.tooltip?.title}
                side={item.tooltip?.side}
                sideOffset={item.tooltip?.sideOffset}
                delayDuration={item.tooltip?.delayDuration}
                trigger={
                  <Typography className={s.label} variant='b2' component='span'>
                    {item.label}
                  </Typography>
                }
              />
            ) : (
              <Typography variant='b2' component='span' className={s.label}>
                {item.label}
              </Typography>
            )}

            {item.hasNotice && <DotIcon className={s.notice} />}
          </RadixDropdownMenu.DropdownMenuItem>
        )
      })}
    </RadixDropdownMenu.Content>
  </RadixDropdownMenu.Root>
)
