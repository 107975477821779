import { toast } from 'react-toastify'
import { toastError } from '@share/components/ui/molecules/notifications'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

import { getAxiosErrorMessage } from '../utils/api'

export const handleOnRequestError = (error: unknown) => {
  if (isAxiosError(error)) {
    const message = getAxiosErrorMessage(error)
    toast.dismiss()
    toastError(message)
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: false,
    },
  },

  queryCache: new QueryCache({
    onError: handleOnRequestError,
  }),

  mutationCache: new MutationCache({
    onError: handleOnRequestError,
  }),
})
