import * as yup from 'yup'

import {
  DeliveryOrderStatus,
  possibleDeliveryOrderStatus,
} from '../delivery/delivery-statuses.schemas'

import { possibleOrderActionIndicatorTypeSchema } from './order-action-indicator-type.schemas'
import { orderCommercialOfferPackageModelSchema } from './order-commercial-offer.schemas'
import { OrderStatus, possibleOrderStatus } from './order-statuses.schemas'
import { orderSubtypeSchema, orderTypeSchema } from './order-type.schemas'

export type TableOrderStatus = OrderStatus | DeliveryOrderStatus

const possibleTableOrderStatus = [
  ...new Set([...possibleOrderStatus, ...possibleDeliveryOrderStatus]),
] as const

export const tableOrderStatusSchema = yup
  .mixed<TableOrderStatus>()
  .oneOf(possibleTableOrderStatus)
  .defined()

export const tableOrderModelSchema = yup
  .object()
  .shape({
    id: yup.string().defined(),
    name: yup.string().defined(),
    customOrderName: yup.string().defined().nullable(),
    type: orderTypeSchema.defined(),
    subtype: orderSubtypeSchema.defined(),
    createdBy: yup.string().defined(),
    confirmedByUserAt: yup.string().defined(),
    status: tableOrderStatusSchema.defined(),
    lastUpdate: yup.string().defined(),
    createdAt: yup.string().defined(),
    companyName: yup.string().defined().nullable(),
    deliveryCountryName: yup.string().defined(),
    commercialOfferId: yup.string().defined().nullable(),
    price: yup.number().defined(),
    currencyCode: yup.string().defined().nullable(),
    actualEta: yup.array().of(yup.string()).defined(),
    isComment: yup.boolean().defined(),
    etaChanged: yup
      .object({
        value: yup.boolean().defined(),
        readByCompany: yup.boolean().defined(),
      })
      .defined(),
    orderNeedSerialNumber: yup.boolean().defined(),
    orderActionIndicator: possibleOrderActionIndicatorTypeSchema.defined().nullable(),
    packages: yup.array(orderCommercialOfferPackageModelSchema.pick(['id', 'status'])).optional(),
    asanaLink: yup.string().defined().nullable(),
  })
  .noUnknown()

export type TableOrderModel = yup.InferType<typeof tableOrderModelSchema>
