import { UpdateOrderActualEtaRequest } from '@share/hooks/api'
import * as yup from 'yup'

import { OrderActualEtaFormValues } from './types'

export const orderActualEtaDefaultValues: OrderActualEtaFormValues = {
  isActualEtaSingleData: false,
  actualEta: ['', ''],
}

export const orderActualEtaValidationSchema = yup.object().shape({
  actualEta: yup
    .mixed()
    .label('Actual ETA')
    .test({
      name: 'actualEta',
      message: 'Actual ETA is a required field',
      test: (value) => {
        if (Array.isArray(value)) {
          return value[0] !== '' && value[1] !== ''
        }
        return Boolean(value)
      },
    }),
})

export const adaptOrderActualEtaFormValuesToRequest = ({
  isActualEtaSingleData,
  comment,
  ...formValues
}: OrderActualEtaFormValues): Omit<UpdateOrderActualEtaRequest, 'id'> => {
  const { actualEta } = formValues

  return {
    actualEta: Array.isArray(actualEta) ? actualEta : [actualEta],
  }
}
