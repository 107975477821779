export const ACCEPT_FILE_EXTENSIONS = [
  '.ogm',
  '.wmv',
  '.mpg',
  '.webm',
  '.ogv',
  '.mov',
  '.asx',
  '.mpeg',
  '.mp4',
  '.m4v',
  '.avi',
  '.xbm',
  '.tif',
  '.jfif',
  '.ico',
  '.tiff',
  '.gif',
  '.svg',
  '.jpeg',
  '.svgz',
  '.jpg',
  '.webp',
  '.png',
  '.bmp',
  '.pjp',
  '.apng',
  '.pjpeg',
  '.avif',
  '.pdf',
]
export const ACCEPT_FILE_EXTENSIONS_AS_STRING = ACCEPT_FILE_EXTENSIONS.join(', ')

export const ACCEPT_IMAGES_EXTENSIONS = ['jpg', 'jpeg', 'png']
export const ACCEPT_IMAGES_EXTENSIONS_STRING = ACCEPT_FILE_EXTENSIONS.join(', ')
