import * as yup from 'yup'

import { OrderCancelReasons, OrderNameEditFormValues, OrderNotesEditFormValues } from './types'

// Order Notes
export const orderNotesEditDefaultFormValues = { notes: '' }
export const orderNotesEditValidationSchema: yup.Schema<OrderNotesEditFormValues> = yup
  .object()
  .shape({
    notes: yup.string().label('Order notes').required('This field is required'),
  })

// Order Name
export const orderNameEditDefaultFormValues = { customOrderName: '' }
export const orderNameEditValidationSchema: yup.Schema<OrderNameEditFormValues> = yup
  .object()
  .shape({
    customOrderName: yup.string().label('Order name').required('This field is required'),
  })

// Order Cancel
export const orderCancelDefaultFormValues = { cancelReason: '', comment: { keepPrevious: false } }
export const getOrderCancelValidationSchema = (cancelReasons: OrderCancelReasons) =>
  yup.object().shape({
    cancelReason: yup
      .mixed()
      .oneOf(cancelReasons.map(({ value }) => value))
      .label('Reason')
      .required(),
    cancelDescription: yup
      .mixed()
      .test('Description', 'Reason field is a required field', (value, context) => {
        const { cancelReason } = context.parent
        if (cancelReason === 'OTHER') return Boolean(value)
        return true
      }),
  })
