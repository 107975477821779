import * as yup from 'yup'

import { possibleProductAdditionalServiceType, ProductAdditionalServiceType } from '../product'

export const orderProductAdditionalServiceSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  type: yup
    .mixed<ProductAdditionalServiceType>()
    .oneOf(possibleProductAdditionalServiceType)
    .defined(),
  quantity: yup.number().defined(),
  price: yup.number().defined(),
  priceWithoutMargin: yup.number().defined(),
  pricePerUnit: yup.number().defined(),
  pricePerUnitWithoutMargin: yup.number().defined(),
})

export type OrderProductAdditionalServiceModel = yup.InferType<
  typeof orderProductAdditionalServiceSchema
>

export type OrderProductAdditionalServicesModel = OrderProductAdditionalServiceModel[]
