import type { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react'
import { forwardRef, useCallback, useId } from 'react'
import type { ClassName } from '@share/@types'
import cn from 'classnames'

import { DEFAULT_TEL_INPUT_PATTERN } from './constants'

import s from './input.module.scss'

export interface InputProps extends ClassName {
  className?: string
  invalid?: boolean
  disabled?: boolean
  value?: string
  type?: 'text' | 'password' | 'email' | 'number' | 'tel'
  telPattern?: string
  placeholder?: string
  slotPrepend?: React.ReactNode
  slotAppend?: React.ReactNode
  radius?: 'small' | 'medium'
  size?: 'small' | 'medium'
  min?: number
  max?: number
  onClick?: () => void
  onChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      type = 'text',
      disabled,
      value,
      telPattern = DEFAULT_TEL_INPUT_PATTERN,
      invalid,
      slotPrepend,
      slotAppend,
      radius = 'small',
      size = 'medium',
      onChange,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputPattern = new RegExp(e.target.pattern)
        if (!inputPattern.test(e.target.value)) return
        onChange?.(e.target.value, e)
      },
      [onChange],
    )
    const additionalInputProps: Partial<
      Record<HTMLInputTypeAttribute, InputHTMLAttributes<HTMLInputElement>>
    > = {
      tel: { pattern: telPattern },
    }

    const id = useId()

    const rootClassName = cn(
      s.root,
      {
        [s.disabled]: disabled,
        [s.invalid]: invalid,
        [s.radiusSmall]: radius === 'small',
        [s.radiusMedium]: radius === 'medium',
        [s.sizeSmall]: size === 'small',
        [s.sizeMedium]: size === 'medium',
      },
      className,
    )

    const onWheel = useCallback(
      (event: React.WheelEvent<HTMLInputElement>) => event.currentTarget.blur(),
      [],
    )

    return (
      <label htmlFor={id} className={rootClassName}>
        {slotPrepend && <div className={s.icon}>{slotPrepend}</div>}
        <input
          id={id}
          className={s.input}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onClick={onClick}
          onWheel={onWheel}
          type={type}
          ref={ref}
          {...additionalInputProps[type]}
          {...rest}
        />
        {slotAppend && <div className={s.icon}>{slotAppend}</div>}
      </label>
    )
  },
)

Input.displayName = 'Input'
