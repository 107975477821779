import * as yup from 'yup'

const possibleSpecTypes = ['INPUT', 'SELECT', 'TEXTAREA'] as const

type AssetsSpecTypes = (typeof possibleSpecTypes)[number]

export const assetSpecDetailModelSchema = yup.object({
  id: yup.string().defined(),
  value: yup.string().nullable().defined(),
  name: yup.string().defined(),
  icecatFeatureId: yup.string().defined(),
  source: yup.string().optional(),
  editable: yup.boolean().defined(),
  outputName: yup.string().defined(),
  valueType: yup.mixed<string | number>().defined(),
  unitOfMeasure: yup.string().nullable().defined(),
  type: yup.mixed<AssetsSpecTypes>().defined().oneOf(possibleSpecTypes),
  possibleVariants: yup.array(yup.string().defined()).defined(),
})

export type AssetSpecDetailModel = yup.InferType<typeof assetSpecDetailModelSchema>

export type AssetSpecDetailsModel = AssetSpecDetailModel[]

export const assetSpecModelSchema = yup.object({
  spec: yup.string().defined(),
  details: yup.array(assetSpecDetailModelSchema).defined(),
})

export const assetSpecsModelSchema = yup.array(assetSpecModelSchema).defined()

export type AssetSpecModel = yup.InferType<typeof assetSpecModelSchema>

export type AssetSpecsModel = yup.InferType<typeof assetSpecsModelSchema>
