import LoadingIcon from '@assets/icons/loader.svg'

import type { IconProps } from '../icon/icon'
import { Icon } from '../icon/icon'

import s from './loader.module.scss'

export type LoaderProps = Omit<IconProps, 'icon'>

export const Loader: React.FC<LoaderProps> = (props) => (
  <Icon size='medium' className={s.root} icon={LoadingIcon} {...props} />
)
