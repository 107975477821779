import * as yup from 'yup'

const possibleLocationType = ['OFFICE', 'STAFF'] as const

export type LocationType = (typeof possibleLocationType)[number]

export const locationTypeSchema = yup.mixed<LocationType>().defined().oneOf(possibleLocationType)

export const availableLocationModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  address: yup.string().defined(),
  type: locationTypeSchema.defined(),
})

export const availableLocationsModelSchema = yup
  .array(availableLocationModelSchema.defined())
  .defined()

export type AvailableLocationModel = yup.InferType<typeof availableLocationModelSchema>
export type AvailableLocationsModel = yup.InferType<typeof availableLocationsModelSchema>
