import * as yup from 'yup'

import { brandModelSchema } from '../brand'
import { categoryModelSchema, subcategoryModelSchema } from '../categories'
import { companyStaffModelSchema } from '../company'
import { integrationSpecsModelSchema } from '../integration'
import { officeLocationModelSchema } from '../office/location.schemas'
import { officeModelSchema } from '../office/office.schemas'
import { orderTypeSchema } from '../order/order-type.schemas'
import { warehouseModelSchema } from '../warehouse'

import { assetAssignToModelSchema } from './asset-assign.schemas'
import { assetAttachmentsModelSchema } from './asset-attachment.schemas'
import { assetChangeLogsModelSchema } from './asset-change-log.schemas'
import { assetDPNModelSchema } from './asset-dpn.schemas'
import { assetLocationModelSchema } from './asset-location.schemas'
import { assetSourceTypeSchema } from './asset-source.schemas'
import { assetSpecsModelSchema } from './asset-specs.schemas'
import { assetStatusSchema } from './asset-status.schemas'
import { assetStockStatusesModelSchema } from './asset-stock-status.schemas'

export const assetModelSchema = yup
  .object()
  .shape({
    id: yup.string().defined(),
    name: yup.string().defined(),
    model: yup.string().nullable().defined(),
    sku: yup.string().nullable().defined(),
    purchaseDate: yup.string().nullable().defined(),
    price: yup.number().nullable().defined(),
    warranty: yup.string().nullable().defined(),
    image: yup.string().nullable().defined(),
    serialNumber: yup.string().nullable().defined(),
    brand: brandModelSchema.nullable().defined(),
    brandId: yup.string().nullable().defined(),
    status: assetStatusSchema.defined(),
    deletedAt: yup.date().nullable().defined(),
    createdAt: yup.date().defined(),
    updatedAt: yup.date().defined(),
    manuallyAdded: yup.boolean().defined(),
    canEdit: yup.boolean().defined(),
    office: officeModelSchema.nullable().defined(),
    officeId: yup.string().nullable().defined(),
    officeSpace: officeModelSchema.nullable().defined(),
    officeSpaceId: yup.string().nullable().defined(),
    officeLocationId: yup.string().nullable().defined(),
    warehouse: warehouseModelSchema.nullable().defined(),
    warehouseId: yup.string().nullable().defined(),
    officeLocation: officeLocationModelSchema.defined(),
    staff: companyStaffModelSchema.defined(),
    staffId: yup.string().defined(),
    archiveReason: yup.string().nullable().defined(),
    archiveDescription: yup.string().nullable().defined(),
    specs: assetSpecsModelSchema.defined(),
    assignedTo: assetAssignToModelSchema.nullable().defined(),
    category: categoryModelSchema.nullable().defined(),
    subcategory: subcategoryModelSchema.defined(),
    location: assetLocationModelSchema.nullable().defined(),
    assetDpn: assetDPNModelSchema.defined(),
    assetDpnId: yup.string().defined(),
    assetState: yup.string().defined(),
    vendor: yup.string().nullable().defined(),
    icecatNotFound: yup.boolean().defined(),
    diskEncryption: yup.string().nullable().defined(),
    productCode: yup.string().nullable().defined(),
    notes: yup.string().nullable().defined(),
    source: assetSourceTypeSchema.defined(),
    attachments: assetAttachmentsModelSchema.defined(),
    totalStock: yup.number().defined(),
    statusStock: assetStockStatusesModelSchema.defined(),
    dpn: yup.string().defined(),
    logs: assetChangeLogsModelSchema.defined(),
    integrationSpec: integrationSpecsModelSchema.nullable().defined(),
    integrationSyncDate: yup.date().nullable().defined(),
    integrationSystemDeviceId: yup.string().nullable().defined(),
    ipAddress: yup.string().nullable().defined(),
    changedByAdminId: yup.string().nullable().defined(),
    company: companyStaffModelSchema.nullable().defined(),
    companyId: yup.string().nullable().defined(),
    condition: yup.string().defined(),
    netsuiteId: yup.string().nullable().defined(),
    hasIssues: yup.boolean().defined(),
    purchaseOrder: yup
      .object({
        id: yup.string().defined(),
        name: yup.string().defined(),
      })
      .defined()
      .nullable(),
    activeOrder: yup
      .object({
        id: yup.string().defined(),
        type: orderTypeSchema.defined(),
        name: yup.string().defined(),
      })
      .defined()
      .nullable(),
  })
  .noUnknown()

export const assetsModelSchema = yup.array().of(assetModelSchema.defined()).defined()

export type AssetModel = yup.InferType<typeof assetModelSchema>
export type AssetsModel = yup.InferType<typeof assetsModelSchema>
