import * as yup from 'yup'

import { deliveryShippingMethodSchema } from '../delivery/delivery-shipping-methods.schemas'

export const purchaseOrderShippingInfoSchema = yup.object().shape({
  id: yup.string().defined(),
  estimatedTimeOfArrival: yup.string().defined(),
  requestedPickUpDate: yup
    .tuple([yup.string().nullable().defined(), yup.string().nullable().defined()])
    .nullable()
    .defined(),
  shippingMethod: deliveryShippingMethodSchema.defined(),
})

export type PurchaseOrderShippingInfoModel = yup.InferType<typeof purchaseOrderShippingInfoSchema>
