import * as yup from 'yup'

import { productModelSchema } from '../product'

const purchaseOrderProductWithSerialNumberSchema = yup.object({
  id: yup.string().defined(),
  product: productModelSchema
    .pick(['id', 'name', 'model', 'image', 'available'])
    .concat(
      yup.object({
        categoryName: yup.string().defined(),
        subcategoryName: yup.string().defined(),
      }),
    )
    .defined(),
  serialNumber: yup.string().defined().nullable(),
})

export const purchaseOrderProductsWithSerialNumbersSchema = yup
  .array()
  .of(purchaseOrderProductWithSerialNumberSchema.defined())
  .defined()

export type PurchaseOrderProductsWithSerialNumbersModel = yup.InferType<
  typeof purchaseOrderProductsWithSerialNumbersSchema
>
