import { forwardRef } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Overlay } from '@share/components/ui/atoms'
import { DIALOG_CONTENT_ID, MODAL_CONTAINER_ID } from '@share/constants'

import s from './modal.module.scss'

export type ModalProps = DialogPrimitive.DialogProps & {
  slotFooter?: React.ReactNode
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, forwardedRef) => {
  const { children, defaultOpen, onOpenChange, open, slotFooter } = props
  const modalContainer = document.getElementById(MODAL_CONTAINER_ID)

  return (
    <DialogPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal container={modalContainer}>
        <DialogPrimitive.Overlay asChild>
          <Overlay>
            <DialogPrimitive.Content
              ref={forwardedRef}
              className={s.root}
              data-dialog-content-id={DIALOG_CONTENT_ID}
            >
              {children}

              {slotFooter && slotFooter}
            </DialogPrimitive.Content>
          </Overlay>
        </DialogPrimitive.Overlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
})

Modal.displayName = 'Modal'
