import { forwardRef } from 'react'
import AttachmentIcon from '@assets/icons/attachment.svg'

import { ButtonUpload, ButtonUploadProps } from '../button'

export type AttachmentButtonProps = ButtonUploadProps

export const AttachmentButton = forwardRef<HTMLInputElement, AttachmentButtonProps>(
  ({ children, ...props }, inputRef) => (
    <ButtonUpload {...props} ref={inputRef} variant='subtle' icon={AttachmentIcon}>
      {children}
    </ButtonUpload>
  ),
)

AttachmentButton.displayName = 'AttachmentButton'
