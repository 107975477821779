import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  orderNotesEditDefaultFormValues,
  OrderNotesEditFormValues,
  orderNotesEditValidationSchema,
} from '../libs'

export const useOrderNotesEditForm = (values?: OrderNotesEditFormValues) =>
  useForm<OrderNotesEditFormValues>({
    values,
    defaultValues: orderNotesEditDefaultFormValues,
    resolver: yupResolver(orderNotesEditValidationSchema),
  })
