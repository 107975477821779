import { InferType, mixed, object, string } from 'yup'

import { CategoryTypes, possibleCategoryTypes } from './category-types.schemas'

export const categoryModelSchema = object({
  id: string().defined(),
  name: string().defined(),
  code: string().defined(),
  category: mixed<CategoryTypes>().defined().oneOf(possibleCategoryTypes).nullable(),
})
export type CategoryModel = InferType<typeof categoryModelSchema>
export type CategoriesModel = CategoryModel[]

export const categoryMutationRequestSchema = object({
  name: string().defined(),
  category: string().defined(),
  code: string().defined(),
})
export type CategoryMutationRequest = InferType<typeof categoryMutationRequestSchema>
