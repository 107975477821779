import * as yup from 'yup'

export const deliveryConfirmationInfoRequestSchema = yup.object().shape({
  customOrderName: yup.string().defined(),
  notes: yup.string().defined().nullable(),
})

export type DeliveryConfirmationInfoRequest = yup.InferType<
  typeof deliveryConfirmationInfoRequestSchema
>
