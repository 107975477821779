import * as yup from 'yup'

import { possibleEmailSettingsSchema } from '../user/admin.schemas'

import { tableOrderStatusSchema } from './order-core.schemas'

export const OrderLastCommentModelSchema = yup
  .object()
  .shape({
    id: yup.string().defined(),
    title: yup.string().defined(),
    content: yup.string().defined(),
    orderStatus: tableOrderStatusSchema.defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined(),

    admin: yup
      .object({
        id: yup.string().defined(),
        avatar: yup.string().defined().nullable(),
        createdAt: yup.date().defined(),
        updatedAt: yup.date().defined(),
        fullName: yup.string().defined(),
        email: yup.string().defined(),
        phone: yup.string().defined(),
        emailSettings: possibleEmailSettingsSchema.defined(),
      })
      .defined(),
  })
  .noUnknown()

export type OrderLastCommentModel = yup.InferType<typeof OrderLastCommentModelSchema>
