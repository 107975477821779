import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { Icon, IconProps } from '../icon/icon'
import { Stack } from '../stack/stack'

import s from './icon-holder.module.scss'

export type Colors = 'gray' | 'forestGreen' | 'forestGreenDark' | 'peach'
interface IconHolderProps extends ClassName, Children {
  iconIndicator?: IconProps['icon']
  size?: 'medium' | 'large' | 'xlarge'
  color?: Colors
}

export const IconHolder: React.FC<IconHolderProps> = ({
  className,
  children,
  iconIndicator,
  size = 'large',
  color = 'gray',
}) => (
  <Stack align='center' justify='center' className={cn(s.root, [s[size]], [s[color]], className)}>
    {children}
    {iconIndicator && <Icon size='medium' icon={iconIndicator} className={s.iconIndicator} />}
  </Stack>
)
