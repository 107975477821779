import type { AttachmentModel, OrderPONumberModel } from '@share/schemas'
import { emptyStringToNull, undefToEmptyString } from '@share/utils'
import * as yup from 'yup'

import type { OrderPONumberFormValues } from './types'

export const orderPONumberDefaultValues: OrderPONumberFormValues = {
  purchaseOrderNumber: '',
  purchaseQuote: [],
}

export const orderPONumberValidationSchema = yup.object().shape({
  purchaseOrderNumber: yup
    .string()
    .max(30)
    .label('Customer PO number')
    .when('purchaseQuote', {
      is: (poQuote: AttachmentModel[]) => Boolean(poQuote.length),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    })
    .transform(emptyStringToNull),
})

export const adaptOrderPONumberToFormValues = ({
  purchaseOrderNumber,
  purchaseQuote,
}: OrderPONumberModel): OrderPONumberFormValues => ({
  purchaseOrderNumber: undefToEmptyString(purchaseOrderNumber),
  purchaseQuote: purchaseQuote ? [{ name: purchaseQuote.name, url: purchaseQuote.url }] : [],
})

export const adaptOrderPONumberFormValuesToRequest = (formValues: OrderPONumberFormValues) => ({
  purchaseOrderNumber: formValues.purchaseOrderNumber ?? null,
  purchaseQuote: formValues.purchaseQuote[0] ?? null,
})
