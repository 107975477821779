import AssetIcon from '@assets/icons/large/assets.svg'
import EmployeeIcon from '@assets/icons/large/employee.svg'
import OfficeIcon from '@assets/icons/large/office.svg'
import SpaceIcon from '@assets/icons/large/space.svg'
import { FileUploadEntities } from '@share/@types'

import { Group, Icon } from '../../atoms'

import s from './file-upload.module.scss'

const RoleIcon: Record<FileUploadEntities, React.FC> = {
  employee: EmployeeIcon,
  space: SpaceIcon,
  location: OfficeIcon,
  asset: AssetIcon,
  admin: EmployeeIcon,
  assetFile: AssetIcon,
  invoice: AssetIcon,
  purchaseQuote: AssetIcon,
  proofOfDelivery: AssetIcon,
  proofOfPackageDelivery: AssetIcon,
}

export type FileUploadPreviewProps = {
  src?: string
  entityType?: FileUploadEntities
}

export const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  src,
  entityType = 'employee',
}) => (
  <Group className={s.preview} align='middle' position='center'>
    {src && <img className={s.previewImage} src={src} />}
    {!src && (
      <Group className={s.previewPlaceholder} align='middle' position='center'>
        <Icon size='large' icon={RoleIcon[entityType]} />
      </Group>
    )}
  </Group>
)
