import * as yup from 'yup'

import { adminSchema } from '../user'

const possibleAuthorizationTypes = ['GOOGLE', 'MICROSOFT'] as const
const possibleAuthorizationSteps = ['DASHBOARD', 'REGISTRATION', 'SUPER_ADMIN'] as const

export type AuthorizationType = (typeof possibleAuthorizationTypes)[number]
export type AuthorizationSteps = (typeof possibleAuthorizationSteps)[number]

export const sendLoginRequestSchema = yup.object({
  code: yup.string().required().defined(),
  redirectUrl: yup.string(),
  authorizationType: yup.mixed<AuthorizationType>().defined().oneOf(possibleAuthorizationTypes),
})

export const sendLoginResponseSchema = yup.object({
  step: yup.mixed<AuthorizationSteps>().defined().oneOf(possibleAuthorizationSteps),
  user: adminSchema,
})

export const sendSuperAdminLoginRequestSchema = yup.object({
  companyId: yup.string().required().defined(),
})
