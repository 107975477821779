import { useTranslation } from 'react-i18next'
import { FormInputControl } from '@dots/uikit'
import { create, useModal } from '@ebay/nice-modal-react'
import { OrderBox } from '@entities/order/ui/atoms'
import { Dialog, Form } from '@share/components/ui'

import { useOrderNameEditForm } from '../../../hooks'
import { ORDER_NAME_EDIT_DIALOG_ID, type OrderNameEditDialogProps } from '../../../libs'
import { OrderActionDialog } from '../../molecules'

export const OrderNameEditDialog = create<OrderNameEditDialogProps>(
  ({ formValues, orderName, orderBreadcrumbs, loading, onSubmit }) => {
    const { t } = useTranslation()
    const modalMethods = useModal(ORDER_NAME_EDIT_DIALOG_ID)
    const formMethods = useOrderNameEditForm(formValues)

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.order-name.title')}
        onOpenChange={modalMethods.remove}
        slotSubheader={<OrderBox title={orderName} breadcrumbs={orderBreadcrumbs} />}
        slotFooter={
          <Dialog.Footer
            buttonCancelProps={{
              children: t('actions.cancel'),
              onClick: modalMethods.remove,
            }}
            buttonOkProps={{
              children: t('actions.save-changes'),
              onClick: formMethods.handleSubmit(onSubmit),
              disabled: !formMethods.formState.isDirty,
              loading,
            }}
          />
        }
      >
        <Form.Root style={{ minHeight: '200px' }}>
          <FormInputControl
            optional
            name='customOrderName'
            control={formMethods.control}
            label={t('orders.modals.order-name.name.label')}
            placeholder={t('orders.modals.order-name.name.placeholder')}
          />
        </Form.Root>
      </OrderActionDialog>
    )
  },
)
