import { forwardRef, useCallback, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { Input, Loader, Stack, Typography } from '@dots/uikit'
import type { ClassName } from '@share/@types'
import type { PlaceAutocompleteHookReturn, PlaceAutocompleteSuggestion } from '@share/hooks'
import { useOutsideClick } from '@share/hooks'
import cn from 'classnames'

import type { TextFieldLabelProps } from './text-field-label'
import { TextFieldLabel } from './text-field-label'
import type { TextFieldMessageProps } from './text-field-message'
import { TextFieldMessage } from './text-field-message'

import s from './field.module.scss'

export interface AutoCompleteFieldProps extends ClassName {
  value: string
  label?: TextFieldLabelProps['label']
  message?: TextFieldMessageProps['message']
  messageType?: TextFieldMessageProps['messageType']
  assistiveText?: TextFieldMessageProps['assistiveText']
  supportingText?: TextFieldLabelProps['supportingText']
  placeholder?: string
  inline?: boolean
  invalid?: boolean
  disabled?: boolean
  required?: boolean
  loading?: boolean
  suggestions: PlaceAutocompleteHookReturn['suggestions']
  onChange: (address: PlaceAutocompleteSuggestion) => void
  onInputChange: (event: string) => void
  clearSuggestions: PlaceAutocompleteHookReturn['clearSuggestions']
}

export const AutoCompleteField = forwardRef<HTMLInputElement, AutoCompleteFieldProps>(
  (
    {
      suggestions,
      value,
      className,
      inline,
      message,
      supportingText,
      required,
      assistiveText,
      label,
      invalid,
      disabled,
      messageType,
      placeholder,
      loading,
      onChange,
      onInputChange,
      clearSuggestions,
    },
    ref,
  ) => {
    const selectRef = useRef<HTMLInputElement>(null)

    const rootClassName = cn(
      s.root,
      {
        [s.errored]: invalid,
        [s.inline]: inline,
        [s.vCenter]: !supportingText,
        [s.disabled]: disabled,
      },
      className,
    )

    const hasSuggestions = Boolean(suggestions.length)

    const handleChange = useCallback(
      (address: PlaceAutocompleteSuggestion) => {
        onChange(address)
        clearSuggestions()
      },
      [onChange, clearSuggestions],
    )

    useOutsideClick(selectRef, clearSuggestions, hasSuggestions)

    return (
      <div className={rootClassName}>
        {label && (
          <TextFieldLabel label={label} required={required} supportingText={supportingText} />
        )}

        <Stack className={s.container} gap={6}>
          <Input
            ref={mergeRefs([ref, selectRef])}
            disabled={disabled}
            invalid={invalid}
            placeholder={placeholder}
            className={s.autocompleteInput}
            value={value}
            onChange={onInputChange}
            slotAppend={loading && <Loader />}
          />

          {(message || assistiveText) && (
            <TextFieldMessage
              message={message}
              assistiveText={assistiveText}
              messageType={messageType}
            />
          )}

          {hasSuggestions && (
            <div className={s.addressesList}>
              {hasSuggestions && !loading && (
                <ul className={s.addresses}>
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.description}
                      className={s.addressItem}
                      onClick={handleChange.bind(null, suggestion)}
                    >
                      <Typography variant='b2' component='div'>
                        {suggestion.description}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}

              {!hasSuggestions && !loading && (
                <div className={s.notFound}>
                  <Typography component='p' variant='c2'>
                    Items not found
                  </Typography>
                </div>
              )}
              {loading && (
                <div className={s.notFound}>
                  <Typography component='p' variant='c2'>
                    Loading...
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Stack>
      </div>
    )
  },
)

AutoCompleteField.displayName = 'AutoCompleteField'
