import * as yup from 'yup'

const possibleMeasurementSystem = ['METRIC', 'IMPERIAL'] as const

export type MeasurementSystem = (typeof possibleMeasurementSystem)[number]

const measurementSystemSchema = yup
  .mixed<MeasurementSystem>()
  .defined()
  .oneOf(possibleMeasurementSystem)

const measurementUnits = yup.object().shape({
  length: yup.string().defined(),
  mass: yup.string().defined(),
})

export const measurementModelSchema = yup.object().shape({
  system: measurementSystemSchema.defined(),
  name: yup.string().defined(),
  measurements: measurementUnits.defined(),
})

export const measurementsModelSchema = yup.array(measurementModelSchema).defined()

export type MeasurementUnits = yup.InferType<typeof measurementUnits>
export type MeasurementModel = yup.InferType<typeof measurementModelSchema>
export type MeasurementsModel = yup.InferType<typeof measurementsModelSchema>
