import { CSSProperties } from 'react'
import { LoadingOverlay } from '@dots/uikit'
import { Children, ClassName } from '@share/@types'
import { ScrollArea } from '@share/components/ui/atoms'
import cn from 'classnames'

import s from './modal.module.scss'

interface ModalContentProps extends Children, ClassName {
  height?: CSSProperties['height']
  narrow?: boolean
  loading?: boolean
}

export const ModalContent: React.FC<ModalContentProps> = ({
  children,
  height,
  className,
  loading,
  narrow = true,
}) => (
  <ScrollArea className={cn(s.scroll, { [s.narrow]: narrow }, className)} style={{ height }}>
    {loading && <LoadingOverlay variant='naked' className={s.loader} />}
    {!loading && children}
  </ScrollArea>
)
