import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Comment, Show } from '@dots/uikit'
import { OrderUpdateStatusFormsProps } from '@entities/order'
import {
  Form,
  FormSelectControl,
  FormSwitchControl,
  FormTextareaControl,
  Stack,
} from '@share/components/ui'
import { useDialogContentTarget } from '@share/hooks'
import { OrderModel } from '@share/schemas'

type OrderCancelFormProps = OrderUpdateStatusFormsProps & {
  reasons: Array<{ label: string; value: string }>
  lastComment?: OrderModel['lastComment']
}

export const OrderCancelForm: React.FC<OrderCancelFormProps> = ({
  control,
  reasons,
  commentFormSlot,
  lastComment,
}) => {
  const { t } = useTranslation()
  const menuPortalTarget = useDialogContentTarget()

  const cancelReason = useWatch({
    name: 'cancelReason',
    control,
  })

  return (
    <Form.Root style={{ minHeight: '200px' }}>
      <Stack gap={16}>
        <FormSelectControl
          label={t('orders.modals.cancel.reason')}
          placeholder={t('orders.modals.cancel.reason-placeholder')}
          control={control}
          name='cancelReason'
          list={reasons}
          menuPortalTarget={menuPortalTarget}
        />
        {cancelReason === 'OTHER' && (
          <FormTextareaControl
            control={control}
            minRows={3}
            maxRows={5}
            name='cancelDescription'
            label={t('orders.modals.cancel.description')}
            placeholder={t('orders.modals.cancel.description-placeholder')}
          />
        )}
        {commentFormSlot}
        <Show when={!commentFormSlot && lastComment}>
          <FormSwitchControl
            control={control}
            name='comment.keepPrevious'
            text='Keep previous order comment'
          />
        </Show>
        {lastComment && (
          <Comment
            type='info'
            title={lastComment.title}
            content={lastComment.content}
            slotAction={
              <Button size='small' variant='transparent'>
                {t('actions.learn-more')}
              </Button>
            }
          />
        )}
      </Stack>
    </Form.Root>
  )
}
