import cn from 'classnames'

import { Typography } from '../typography'

import s from './breadcrumbs.module.scss'

export interface BreadcrumbsProps {
  className?: string
  list: string[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className, list }) => (
  <ul className={cn(s.root, className)}>
    {list
      .filter((item) => Boolean(item))
      .map((item, index) => (
        <li key={`${item}${index}`} className={s.item}>
          <Typography variant='b3'>{item}</Typography>
        </li>
      ))}
  </ul>
)
