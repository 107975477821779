import * as yup from 'yup'

import { cartProductsSchema } from './cart-product.schemas'

export const cartModelSchema = yup.object({
  id: yup.string().defined(),
  cartProducts: cartProductsSchema.defined(),
})

export type CartModel = yup.InferType<typeof cartModelSchema>
