import { Admin } from '@share/@types'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UserStore {
  user?: Admin
  isUserHasAccess: boolean
  signIn: (user: Admin) => void
  signOut: () => void
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      isUserHasAccess: false,
      signIn: (user) => set({ isUserHasAccess: true, user }),
      signOut: () => set({ isUserHasAccess: false }),
    }),
    {
      name: 'user-store',
    },
  ),
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('useUserStore', useUserStore)
}
