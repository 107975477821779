import { Image } from '@share/@types'

import s from './root.module.scss'

export type AvatarProps = {
  src: Image['src']
  handleFallbackImage?: () => void
}

export const Avatar: React.FC<AvatarProps> = ({ src, handleFallbackImage }) => (
  <img className={s.image} src={src} loading='lazy' onError={handleFallbackImage} />
)
