import * as yup from 'yup'

import { regionModelSchema } from './region.schemas'
import { countrySourcesSchema } from './sources.schemas'

export const countryModelSchema = yup.object({
  id: yup.string().defined(),
  deliveryDelay: yup.number().defined(),
  name: yup.string().defined(),
  flag: yup.string().defined().nullable(),
  alphaCode2: yup.string().defined().nullable(),
  region: regionModelSchema.defined().nullable(),
  regionId: yup.string().defined().nullable(),
  source: countrySourcesSchema.optional(),
  minDeliveryDate: yup.date().defined(),
})

export const countryOfficeModelSchema = countryModelSchema.omit(['minDeliveryDate'])

export const countriesResponseSchema = yup.array().of(countryModelSchema).defined()

export type CountryModel = yup.InferType<typeof countryModelSchema>
export type CountriesModel = yup.InferType<typeof countriesResponseSchema>
export type CountryOfficeModel = yup.InferType<typeof countryOfficeModelSchema>
