import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface SidebarStore {
  isCollapsed: boolean
  toggleCollapse: () => void
}

export const useSidebarStore = create<SidebarStore>()(
  persist(
    (set) => ({
      isCollapsed: false,
      toggleCollapse: () => set(({ isCollapsed }) => ({ isCollapsed: !isCollapsed })),
    }),
    {
      name: 'sidebar-store',
    },
  ),
)
