import { NotDefinable } from '@dots/uikit'
import { formatDate } from '@share/utils'

export const getFormattedActualEtaDate = (actualEta: NotDefinable<string>[] | null) => {
  const [startDate, endDate] = actualEta || []

  const formattedStartDate = startDate && formatDate(startDate, 'D MMM, YYYY')
  const formattedEndDate = endDate && formatDate(endDate, 'D MMM, YYYY')

  return formattedStartDate && formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : formattedStartDate
}
