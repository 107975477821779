import { Children } from '@share/@types'

import { Stack, StackProps } from '../stack/stack'

import s from './dash-box.module.scss'

type DashBoxProps = Children & StackProps

export const DashBox: React.FC<DashBoxProps> = ({ children, gap = 16, ...rest }) => (
  <Stack className={s.root} gap={gap} {...rest}>
    {children}
  </Stack>
)
