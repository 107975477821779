import * as yup from 'yup'

export const integrationAssetApplicationModelSchema = yup.object({
  name: yup.string().defined(),
  path: yup.string().defined(),
  version: yup.string().defined(),
  bundle_id: yup.string().defined(),
  isAppStore: yup.boolean().defined(),
})

export type IntegrationAssetApplicationModel = yup.InferType<
  typeof integrationAssetApplicationModelSchema
>
export type IntegrationAssetApplicationsModel = IntegrationAssetApplicationModel[]
