import { UserMenu } from '@entities/user'
import { Group } from '@share/components/ui'

import s from './header.module.scss'

interface HeaderProps {
  siteActionMenuSlot: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ siteActionMenuSlot }) => (
  <Group gap={24} component='header' className={s.root} position='apart' align='middle'>
    <Group gap={24} className={s.dash}>
      {siteActionMenuSlot}
    </Group>

    <Group gap={24} align='middle'>
      <UserMenu />
    </Group>
  </Group>
)
