import { mixed } from 'yup'

export const possibleOrderStatus = [
  'DRAFT',
  'PROCESSING',
  'PENDING_OFFER',
  'PREPARING',
  'SHIPPING',
  'DELIVERED',
  'PENDING_PAYMENT',
  'COMPLETED',
  'CONFIRMED',
  'IN_DISPUTE',
  'DISMISSED',
  'CANCELED',
  'EXPIRED',
  'PARTIALLY_DELIVERED',
  'PICKING_UP',
] as const

export type OrderStatus = (typeof possibleOrderStatus)[number]
export type OrderStatuses = OrderStatus[]

export const orderStatusSchema = mixed<OrderStatus>().defined().oneOf(possibleOrderStatus)
