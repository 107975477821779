import { Icon, IconProps } from '../../icon/icon'

import s from './root.module.scss'

export type FallbackProps = IconProps

export const Fallback: React.FC<FallbackProps> = ({ icon, size = 'large' }) => (
  <div className={s.fallback}>
    <Icon size={size} icon={icon} />
  </div>
)
