import * as yup from 'yup'

const possibleShippingTypes = ['Ground', 'Express'] as const
const possibleDeliveryDaysTypes = ['BusinessDays', 'Days'] as const

export type ShippingTypes = (typeof possibleShippingTypes)[number]
export type DeliveryDaysTypes = (typeof possibleDeliveryDaysTypes)[number]

export const shippingTypesSchema = yup.mixed<ShippingTypes>().oneOf(possibleShippingTypes)
export const deliveryDaysTypesSchema = yup
  .mixed<DeliveryDaysTypes>()
  .oneOf(possibleDeliveryDaysTypes)

const calendarInfoSchema = yup.object({
  excludeDates: yup.array().of(yup.string().defined()).defined(),
  includeDateIntervals: yup
    .array()
    .of(
      yup
        .object({
          start: yup.string().defined(),
          end: yup.string().defined(),
        })
        .defined(),
    )
    .defined(),
})

export const deliveryShippingMethodSchema = yup
  .object({
    deliveryTimeInDaysFrom: yup.number().defined(),
    deliveryTimeInDaysTo: yup.number().defined(),
    descriptionText: yup.string().defined().nullable(),
    descriptionTitle: yup.string().defined().nullable(),
    id: yup.string().defined(),
    name: yup.string().defined(),
    price: yup.number().defined(),
    calendarInfo: calendarInfoSchema.defined(),
  })
  .noUnknown()

export const deliveryShippingMethodsSchema = yup.array().of(deliveryShippingMethodSchema).defined()

export type DeliveryShippingMethodModel = yup.InferType<typeof deliveryShippingMethodSchema>
export type DeliveryShippingMethodsModel = yup.InferType<typeof deliveryShippingMethodsSchema>
