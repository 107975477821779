import { useMemo } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Comment, Group, Label } from '@dots/uikit'
import {
  Form,
  FormDatePickerControl,
  FormRangeDatePickerControl,
  FormSelectControl,
  FormSwitchControl,
  FormTextControl,
  Stack,
} from '@share/components/ui'
import { useGetCurrencies, useGetVendors } from '@share/hooks/api'
import { DeliveryOrderModel } from '@share/schemas'
import { getTomorrowDate } from '@share/utils'

import { DeliveryCommercialOfferMutationFormValues } from '../../../libs'

interface AddDeliveryCommercialOfferFormProps {
  control: Control<DeliveryCommercialOfferMutationFormValues>
  addVendorSlot: React.ReactNode
  lastComment?: DeliveryOrderModel['lastComment']
}

export const DeliveryCommercialOfferMutationForm: React.FC<AddDeliveryCommercialOfferFormProps> = ({
  control,
  addVendorSlot,
  lastComment,
}) => {
  const { t } = useTranslation()
  const vendorsResources = useGetVendors({ params: { limit: 1000 } })
  const vendorsOptions = useMemo(
    () =>
      vendorsResources.data?.result.map((vendor) => ({
        label: vendor.companyName,
        value: vendor.id,
      })) || [],
    [vendorsResources.data?.result],
  )

  const currenciesResources = useGetCurrencies({ params: { limit: 1000 } })
  const currenciesOptions = useMemo(
    () =>
      currenciesResources.data?.result.map((currency) => ({
        value: currency.id,
        label: currency.name,
      })) || [],
    [currenciesResources],
  )

  return (
    <Form.Root>
      <Stack gap={16}>
        <FormTextControl
          required
          type='number'
          label='Eta min days'
          placeholder='Enter eta min days'
          control={control}
          name='etaMinDays'
        />
        <FormTextControl
          required
          type='number'
          label='Eta max days'
          placeholder='Enter eta max days'
          control={control}
          name='etaMaxDays'
        />
        <Stack gap={6}>
          <Label required> Vendor</Label>
          <Group gap={20} align='middle' fullWidth>
            <div style={{ width: '80%' }}>
              <FormSelectControl
                name='vendorId'
                placeholder='Select vendor'
                control={control}
                list={vendorsOptions}
                loading={vendorsResources.isLoading}
              />
            </div>
            {addVendorSlot}
          </Group>
        </Stack>
        <FormTextControl
          required
          type='number'
          label='Tax'
          placeholder='Enter tax'
          control={control}
          name='tax'
        />
        <FormSelectControl
          required
          name='currencyId'
          label='Currency'
          placeholder='Select currency'
          control={control}
          list={currenciesOptions}
          loading={currenciesResources.isLoading}
        />
        <FormTextControl
          required
          type='number'
          label='Shipping price - Client side'
          placeholder='Enter shipping price'
          control={control}
          name='shippingPrice'
        />
        <FormTextControl
          required
          type='number'
          label='Shipping cost - Internal'
          assistiveText='Client does not see this'
          placeholder='Enter shipment cost'
          control={control}
          name='shipmentCost'
        />
        <FormDatePickerControl
          required
          control={control}
          name='validUntil'
          label='Valid until'
          placeholder='Valid until'
          minDate={getTomorrowDate()}
        />
        <FormRangeDatePickerControl
          required
          control={control}
          minDate={getTomorrowDate()}
          defaultValue={[null, null]}
          name='pickupDate'
          label='Pickup dates'
          placeholder='Pickup dates'
        />
        {lastComment && (
          <>
            <FormSwitchControl
              control={control}
              name='comment.keepPrevious'
              text='Keep previous order comment'
            />
            <Comment
              type='info'
              title={lastComment.title}
              content={lastComment.content}
              slotAction={
                <Button size='small' variant='transparent'>
                  {t('actions.learn-more')}
                </Button>
              }
            />
          </>
        )}
      </Stack>
    </Form.Root>
  )
}
