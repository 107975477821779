import { useCallback } from 'react'
import { useUserStore } from '@features/auth'

import { useSendLogout } from './api'

export const useLogout = () => {
  const { signOut } = useUserStore()
  const { mutateAsync } = useSendLogout()

  const onLogout = useCallback(async () => {
    await mutateAsync()
    signOut()
  }, [mutateAsync, signOut])

  return [onLogout]
}
