import { forwardRef } from 'react'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Icon, IconProps } from '../icon/icon'

import s from './button-progress.module.scss'

interface ActionIconProgressProps extends ClassName {
  progress?: number
  icon: IconProps['icon']
  onClick?: () => void
}

export const ButtonProgress = forwardRef<HTMLButtonElement, ActionIconProgressProps>(
  ({ className, progress = 0, icon, onClick }, buttonRef) => (
    <div className={s.root}>
      <svg width={56} height={56} className={s.circleProgress} fill='none'>
        <circle
          className={s.circleProgressPath}
          cx='28'
          cy='28'
          r='27'
          fill='none'
          strokeWidth='2'
          strokeDashoffset={100 - progress}
          pathLength='100'
        />
      </svg>

      <button ref={buttonRef} className={cn(s.button, className)} onClick={onClick}>
        <Icon size='large' icon={icon} />
      </button>
    </div>
  ),
)

ButtonProgress.displayName = 'ButtonProgress'
