import { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useUploadDPNFile } from '@share/hooks/api'

export const useUploadDPN = () => {
  const uploadDPNFileRequest = useUploadDPNFile()

  const uploadDPNFile = useCallback(
    async (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      const { file: fileLink } = await uploadDPNFileRequest.mutateAsync(formData)
      window.open(fileLink, '_blank')
    },
    [uploadDPNFileRequest],
  )

  const { open } = useDropzone({
    accept: { 'text/csv': [] },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => uploadDPNFile(acceptedFiles[0]),
  })

  return useMemo(
    () => ({
      onUploadDPN: open,
      ...uploadDPNFileRequest,
    }),
    [open, uploadDPNFileRequest],
  )
}
