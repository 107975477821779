import { useEffect } from 'react'

type RefTarget = {
  current: HTMLElement | null
}

export const useOutsideClick = (ref: RefTarget, callback: () => void, when: boolean) => {
  useEffect(() => {
    if (!when) return undefined

    const handleClick = (e: Event) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) callback()
    }

    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [ref, callback, when])
}
