import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'
import cn from 'classnames'

import { RadioBox, Typography } from '../../atoms'
import { AssignPreview, AssignPreviewProps } from '../../molecules'

import s from './list-box-menu.module.scss'

export interface ListBoxItemProps
  extends Omit<AssignPreviewProps, 'titleClassName' | 'subtitleClassName'>,
    RadioBox.RadioButtonProps {
  label?: string
}

export const ListBoxItem: React.FC<ListBoxItemProps> = ({
  className,
  id,
  disabled,
  label,
  title,
  subtitle,
  type,
  ...radioProps
}) => (
  <DropdownMenuItem
    className={cn(s.item, { [s.disabled]: disabled }, className)}
    onSelect={(e) => e.preventDefault()}
  >
    <RadioBox.RadioLabel id={id} className={s.label}>
      <AssignPreview title={title} subtitle={subtitle} type={type} />
    </RadioBox.RadioLabel>

    {label && (
      <Typography className={s.radioLabel} variant='b3' component='span'>
        {label}
      </Typography>
    )}

    <RadioBox.RadioButton id={id} disabled={disabled} {...radioProps} />
  </DropdownMenuItem>
)
