interface BrowserStorage {
  storage: Storage
}

export class CreateBrowserStorage implements BrowserStorage {
  storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  getItem = (itemName: string) => this.storage?.getItem(itemName)

  setItem = (itemName: string, itemValue: string) => {
    this.storage.setItem(itemName, itemValue)
  }

  removeItem = (itemName: string) => {
    this.storage.removeItem(itemName)
  }

  removeItems = (items: string) => {
    if (!Array.isArray(items)) {
      throw new TypeError(`${items} must be an array`)
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      this.removeItem(item)
    }
  }
}

export default CreateBrowserStorage
