import { forwardRef } from 'react'
import ArrowDownIcon from '@assets/icons/arrow-down.svg'
import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import { DropdownMenu, DropdownMenuItems, Group, Icon } from '../../atoms'

import s from './split-button.module.scss'

type Variants = 'filled' | 'subtle' | 'transparent'

type Size = 'small' | 'medium' | 'large'

type Colors = 'primary' | 'secondary'

export interface SplitButtonProps extends Children, ClassName {
  variant?: Variants
  size?: Size
  colors?: Colors
  disabled?: boolean
  icon?: React.FC
  dropdownMenuItems: DropdownMenuItems
  onClick?: () => void
}

export const SplitButton = forwardRef<HTMLButtonElement, SplitButtonProps>((props, buttonRef) => {
  const {
    className,
    children,
    size = 'medium',
    variant = 'filled',
    colors = 'primary',
    disabled = false,
    icon,
    dropdownMenuItems,
    ...rest
  } = props

  const rootClassName = cn(s.root, className)
  const buttonClassName = {
    [s[size]]: size,
    [s[variant]]: variant,
    [s[colors]]: colors,
    [s.disabled]: disabled,
  }

  return (
    <Group className={rootClassName}>
      <button
        disabled={disabled}
        ref={buttonRef}
        className={cn(s.button, buttonClassName)}
        {...rest}
      >
        <span className={s.inner}>
          {icon && <Icon className={s.icon} icon={icon} />}
          <span className={s.label}>{children}</span>
        </span>
      </button>

      <DropdownMenu
        items={dropdownMenuItems}
        trigger={
          <button className={cn(s.trigger, buttonClassName)} disabled={disabled}>
            <Icon className={s.icon} icon={ArrowDownIcon} />
          </button>
        }
      />
    </Group>
  )
})

SplitButton.displayName = 'SplitButton'
