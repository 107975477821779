import * as yup from 'yup'

import { possibleStorageTypes, StorageTypes } from './storage-types.schema'

export const storageSchema = yup
  .object({
    id: yup.string().defined(),
    name: yup.string().defined(),
    address: yup.string().defined(),
    type: yup.mixed<StorageTypes>().defined().oneOf(possibleStorageTypes),
  })
  .noUnknown()

export const storagesSchema = yup.array().of(storageSchema).defined()

export type AvailableStorageModel = yup.InferType<typeof storageSchema>
export type AvailableStoragesModel = yup.InferType<typeof storagesSchema>
