import { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@assets/icons/close.svg'
import type { ButtonProps } from '@dots/uikit'
import { Button } from '@dots/uikit'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Close } from '@radix-ui/react-dialog'
import { ActionIcon, Group, Overlay, Stack } from '@share/components/ui/atoms'
import { CONFIRM_CONTAINER_ID } from '@share/constants'

import s from './confirm.module.scss'

export type ConfirmProps = DialogPrimitive.DialogProps & {
  okText?: string
  okColor?: ButtonProps['color']
  okVariant?: ButtonProps['variant']
  okLoading?: boolean
  onOkClick?: () => void
  cancelText?: string
  cancelColor?: ButtonProps['color']
  cancelVariant?: ButtonProps['variant']
  onCancelClick?: () => void
  reverseButtons?: boolean
  withClose?: boolean
}

export const Confirm = forwardRef<HTMLDivElement, ConfirmProps>(
  (
    {
      children,
      defaultOpen,
      modal,
      open,
      onOpenChange,
      okText,
      okColor = 'danger',
      okVariant = 'filled',
      okLoading,
      onOkClick,
      cancelText,
      cancelColor = 'neutral',
      cancelVariant = 'subtle',
      onCancelClick,
      reverseButtons = false,
      withClose = true,
    },
    forwardedRef,
  ) => {
    const { t } = useTranslation()
    const modalContainer = document.getElementById(CONFIRM_CONTAINER_ID)

    const actionButtons = useMemo(
      () => [
        {
          component: (
            <Button
              className={s.action}
              onClick={onOkClick}
              loading={okLoading}
              variant={okVariant}
              color={okColor}
            >
              {okText ?? t('modals.delete')}
            </Button>
          ),
          position: reverseButtons ? 1 : 2,
        },
        {
          component: (
            <Close asChild>
              <Button
                className={s.action}
                variant={cancelVariant}
                color={cancelColor}
                onClick={onCancelClick}
              >
                {cancelText ?? t('modals.cancel')}
              </Button>
            </Close>
          ),
          position: reverseButtons ? 2 : 1,
        },
      ],
      [
        cancelColor,
        cancelText,
        cancelVariant,
        okColor,
        okLoading,
        okText,
        okVariant,
        onCancelClick,
        onOkClick,
        reverseButtons,
        t,
      ],
    )

    const renderActionButtons = useCallback(() => {
      const sortedActionButton = [...actionButtons].sort(
        (okButton, cancelButton) => okButton.position - cancelButton.position,
      )
      return sortedActionButton.map((actionButtonComponent) => actionButtonComponent.component)
    }, [actionButtons])

    return (
      <DialogPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        modal={modal}
      >
        <DialogPrimitive.Portal container={modalContainer}>
          <DialogPrimitive.Overlay asChild>
            <Overlay>
              <DialogPrimitive.Content ref={forwardedRef} className={s.root}>
                {withClose && (
                  <Close aria-label='Close' asChild className={s.close}>
                    <ActionIcon size='small' variant='subtle' icon={CloseIcon} />
                  </Close>
                )}

                <div className={s.content}>
                  <Stack gap={22} className={s.inner} align='center'>
                    {children}
                  </Stack>

                  <Group gap={16} className={s.actions} position='center'>
                    {renderActionButtons()}
                  </Group>
                </div>
              </DialogPrimitive.Content>
            </Overlay>
          </DialogPrimitive.Overlay>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    )
  },
)

Confirm.displayName = 'Confirm'
