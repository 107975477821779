import { forwardRef } from 'react'
import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import s from './overlay.module.scss'

interface OverlayProps extends ClassName, Partial<Children> {
  onClick?: () => void
}

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn(s.root, className)} {...props}>
      {children}
    </div>
  ),
)

Overlay.displayName = 'Overlay'
