import * as yup from 'yup'

export const attachmentSchema = yup
  .object({
    name: yup.string().required().defined(),
    url: yup.string().required().defined(),
  })
  .noUnknown()

export type AttachmentModel = yup.InferType<typeof attachmentSchema>

export const attachmentV2Schema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  url: yup.string().defined(),
  size: yup.string().defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
})

export type AttachmentV2Model = yup.InferType<typeof attachmentV2Schema>
