import { isEmptyObject } from './core'
import { asString } from './string'

type UnknownObject = Record<string, unknown>

export const getDirtyFields = <D extends UnknownObject, A extends UnknownObject>(
  dirtyFields: D,
  allValues: A,
): Partial<A> => {
  if (Array.isArray(dirtyFields)) {
    return allValues
  }

  return Object.fromEntries(
    Object.keys(dirtyFields)
      .map((key) => [key, allValues[key]])
      .filter(([, value]) => value !== undefined),
  )
}

export const attachFileToFormData = (formData: FormData, file: unknown, fieldName: string) => {
  if (file instanceof File) {
    formData.append(fieldName, file)
  }
}

export const attachFieldsToFormData = (
  formData: FormData,
  fields: Record<string, unknown>,
  fieldName = 'fields',
) => {
  if (!fields || isEmptyObject(fields)) return

  formData.append(fieldName, JSON.stringify(fields))
}

export const emptyStringToNull = (value: string, originalValue: string) =>
  originalValue === '' ? null : value

export const emptyStringOrNullToUndef = (value: string | null) =>
  value === '' || value === null ? undefined : value

export const emptyStringToUndefined = (value: string, originalValue: string) =>
  originalValue === '' ? undefined : value

export const undefToEmptyString = (value: string | number | null | undefined) => {
  if (value === undefined || value === null) return ''

  return asString(value)
}

export const getFileAcceptExtensions = (extensions: string[]) => extensions.join(', ')

export const testIsNumber = (value: unknown) => !Number.isNaN(Number(value))

export const testIsPositiveNumber = (value: unknown) => Number(value) > 0

export const validateIsNotEmptyArrayOrValue = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return value.every(Boolean)
  }
  return Boolean(value)
}

export const toArray = (value: string | string[]) => (Array.isArray(value) ? value : [value])
export const toEmptyArray = () => []
export const toUndef = () => undefined
