import { getLanguage } from './languages'

interface NumberFormatOptions {
  localeMatcher?: string | undefined
  style?: string | undefined
  currency?: string | undefined
  currencyDisplay?: string | undefined
  currencySign?: string | undefined
  useGrouping?: boolean | undefined
  minimumIntegerDigits?: number | undefined
  minimumFractionDigits?: number | undefined
  maximumFractionDigits?: number | undefined
  minimumSignificantDigits?: number | undefined
  maximumSignificantDigits?: number | undefined
}

export const number = (value: number, options: NumberFormatOptions = {}) => {
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: 'decimal',
    ...options,
  })
  return formatter.format(value)
}

export const currency = (value: number, options: NumberFormatOptions = {}) => {
  const formatter = new Intl.NumberFormat(getLanguage(), {
    style: 'currency',
    currency: 'USD',
    ...options,
  })
  return formatter.format(value)
}

export const getCurrencySymbol = (currencyCode: string): string => {
  const defaultCurrencyCode = 'USD'

  try {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
    })
    const currencyParts = formatter.formatToParts(0)
    return currencyParts?.find((part) => part.type === 'currency')?.value || ''
  } catch {
    return getCurrencySymbol(defaultCurrencyCode)
  }
}
