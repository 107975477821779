import * as yup from 'yup'

import {
  NotificationActionIcon,
  possibleNotificationActionIcon,
} from './notification-action-icon.schema'
import { NotificationIcon, possibleNotificationIcon } from './notification-icon.schema'
import { NotificationType, possibleNotificationType } from './notification-types.schemas'

export const notificationModelSchema = yup.object({
  id: yup.string().defined().required(),
  text: yup.string().defined().required(),
  notificationType: yup
    .mixed<NotificationType>()
    .defined()
    .oneOf(possibleNotificationType)
    .required(),
  isRead: yup.boolean().required(),
  isResolved: yup.boolean().required(),
  entityId: yup.string().nullable().defined(),
  icon: yup.mixed<NotificationIcon>().defined().oneOf(possibleNotificationIcon).required(),
  actionIcon: yup
    .mixed<NotificationActionIcon>()
    .defined()
    .oneOf(possibleNotificationActionIcon)
    .required(),
  createdAt: yup.date().defined().required(),
  updatedAt: yup.date().defined().required(),
})

export type NotificationModel = yup.InferType<typeof notificationModelSchema>
