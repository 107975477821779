import * as yup from 'yup'

import { officeModelSchema } from './office.schemas'

export const officeLocationModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  office: officeModelSchema.defined(),
  officeId: yup.string().defined(),
  image: yup.string().defined().nullable(),
})

export type OfficeLocationModel = yup.InferType<typeof officeLocationModelSchema>
