import { Control } from 'react-hook-form'
import { Form, FormTextControl } from '@share/components/ui'

import { DeleteEventFormValues } from '../../libs'

import s from './event-delete-form.module.scss'

interface EventDeleteFormProps {
  control: Control<DeleteEventFormValues>
  label: string
  placeholder: string
}

export const EventDeleteForm: React.FC<EventDeleteFormProps> = ({
  control,
  label,
  placeholder,
}) => (
  <Form.Root className={s.root}>
    <FormTextControl name='delete' control={control} label={label} placeholder={placeholder} />
  </Form.Root>
)
