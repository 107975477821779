import type { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import s from './root.module.scss'

export interface BoxProps extends Children, ClassName {
  size?: 'small' | 'semiMedium' | 'medium'
  icon?: string
}

export const Box: React.FC<BoxProps> = ({ children, icon, size = 'medium', className }) => (
  <div className={cn(s.box, { [s[size]]: size }, className)}>
    {children} {icon && <img className={s.source} src={icon} />}
  </div>
)
