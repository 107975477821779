import * as yup from 'yup'

import { integrationAssetApplicationModelSchema } from './integration-asset-application.schemas'
import {
  possibleIntegrationSpecsNames,
  SpecsIntegrationNamesModel,
} from './integration-specs-name.schemas'

export const integrationSpecsModelSchema = yup.object({
  generalInfo: yup
    .object({
      integrationSystemDeviceId: yup.string().defined(),
      image: yup.string().defined(),
      name: yup.mixed<SpecsIntegrationNamesModel>().defined().oneOf(possibleIntegrationSpecsNames),
      lastSyncDate: yup.string().defined(),
    })
    .defined(),
  link: yup.string().defined(),
  integrationInfo: yup
    .array(
      yup.object({
        field: yup.string().defined(),
        value: yup.string().nullable().defined(),
      }),
    )
    .defined(),
  software: yup
    .object({
      application: yup.array(integrationAssetApplicationModelSchema).defined(),
    })
    .defined(),
  availableSoftwareUpdates: yup
    .array(
      yup
        .object({
          name: yup.string().defined(),
        })
        .defined(),
    )
    .defined(),
})

export type IntegrationSpecsModel = yup.InferType<typeof integrationSpecsModelSchema>
