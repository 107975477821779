import { useMemo } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import cn from 'classnames'

import { Typography } from '../typography'

import s from './tooltip.module.scss'

export interface TooltipProps extends TooltipPrimitive.TooltipContentProps {
  content?: React.ReactNode
  trigger?: React.ReactNode
  asChild?: boolean

  delayDuration?: TooltipPrimitive.TooltipProps['delayDuration']
  defaultOpen?: TooltipPrimitive.TooltipProps['defaultOpen']
  open?: TooltipPrimitive.TooltipProps['open']
  onOpenChange?: TooltipPrimitive.TooltipProps['onOpenChange']
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  trigger: TriggerComponent,
  defaultOpen,
  open,
  content,
  onOpenChange,
  side = 'left',
  sideOffset = 40,
  delayDuration = 300,
  asChild = false,
  ...props
}) => {
  const isTooltipVisible = useMemo(() => title || content, [content, title])

  return (
    <TooltipPrimitive.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
    >
      {TriggerComponent && (
        <TooltipPrimitive.Trigger className={cn({ [s.root]: !asChild })} asChild={asChild}>
          {TriggerComponent}
        </TooltipPrimitive.Trigger>
      )}

      {isTooltipVisible && (
        <TooltipPrimitive.Content {...props} alignOffset={-8} side={side} sideOffset={sideOffset}>
          <div className={s.frame}>
            <Typography component='div' variant='c2'>
              {title}
            </Typography>
            {content && (
              <Typography variant='b3' className={s.content}>
                {content}
              </Typography>
            )}
          </div>
          <TooltipPrimitive.Arrow className={s.arrow} width={12} height={4} />
        </TooltipPrimitive.Content>
      )}
    </TooltipPrimitive.Root>
  )
}
