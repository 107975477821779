import { forwardRef } from 'react'
import cn from 'classnames'

import { Typography } from '../typography'

import s from './navigation-button.module.scss'

interface NavigationButtonProps {
  label: string
  notice?: boolean
  isActive?: boolean
  variant?: 'primary' | 'secondary'
  disabled?: boolean
  withCaret?: boolean
  collapsed?: boolean
  onClick?: () => void
}

export const NavigationButton = forwardRef<HTMLButtonElement, NavigationButtonProps>(
  ({ label, notice, variant = 'primary', isActive, disabled, collapsed = false, ...rest }, ref) => {
    const rootClassName = cn(s.root, {
      [s[variant]]: variant,
      [s.active]: isActive,
      [s.round]: collapsed,
      [s.disabled]: disabled,
    })

    return (
      <button className={rootClassName} type='button' ref={ref} disabled={disabled} {...rest}>
        {!collapsed && (
          <Typography component='span' variant='c2' className={s.label}>
            {label}
          </Typography>
        )}

        {notice && (
          <span className={s.notice}>
            <span className={s.circle} />
          </span>
        )}
      </button>
    )
  },
)

NavigationButton.displayName = 'NavigationButton'
