import { forwardRef } from 'react'
import CloseIcon from '@assets/icons/close.svg'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Close } from '@radix-ui/react-dialog'
import { ActionIcon, Overlay } from '@share/components/ui/atoms'
import { ALERT_CONTAINER_ID } from '@share/constants'
import cn from 'classnames'

import s from './alert.module.scss'

export interface AlertProps extends DialogPrimitive.DialogProps {
  canClose?: boolean
  size?: 'medium' | 'large'
  onClose?: () => void
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  (
    { children, defaultOpen, onOpenChange, open, canClose = true, size = 'medium', onClose },
    forwardedRef,
  ) => {
    const modalContainer = document.getElementById(ALERT_CONTAINER_ID)

    return (
      <DialogPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
        <DialogPrimitive.Portal container={modalContainer}>
          <DialogPrimitive.Overlay asChild>
            <Overlay>
              <DialogPrimitive.Content
                ref={forwardedRef}
                className={cn(s.root, { [s[size]]: size })}
                onPointerDownOutside={(e) => e.preventDefault()}
                onEscapeKeyDown={(e) => e.preventDefault()}
              >
                {canClose && (
                  <Close aria-label='Close' asChild className={s.close}>
                    <ActionIcon size='small' variant='subtle' icon={CloseIcon} onClick={onClose} />
                  </Close>
                )}
                <div className={cn(s.content, { [s[size]]: size })}>{children}</div>
              </DialogPrimitive.Content>
            </Overlay>
          </DialogPrimitive.Overlay>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    )
  },
)

Alert.displayName = 'Alert'
