import { useUserStore } from '@features/auth'
import { Avatar, Group, Indicator } from '@share/components/ui/atoms'

import { UserActionMenu } from './ui'

import s from './user-menu.module.scss'

export const UserMenu: React.FC = () => {
  const { user } = useUserStore()

  return (
    <Group className={s.root} align='middle' gap={16}>
      <Indicator className={s.avatar}>
        <Avatar image={user?.avatar} />
      </Indicator>

      <UserActionMenu />
    </Group>
  )
}
