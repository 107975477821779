export const possibleCategoryTypes = [
  'accessories',
  'cables',
  'comp_systems',
  'displays',
  'networks',
  'office_eq',
  'phone_tables',
  'printers_and_scanners',
  'video_conf',
  'servers_and_communication_cabinets',
  'other',
] as const

export type CategoryTypes = (typeof possibleCategoryTypes)[number]
