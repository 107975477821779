import * as yup from 'yup'

import { purchaseOrderCommercialOfferModelSchema } from './order-commercial-offer.schemas'
import { orderDeliveryModelSchema } from './order-delivery.schemas'
import { orderProductModelSchema } from './order-product.schemas'

export const purchaseOrderTableSchema = yup
  .object({
    delivery: orderDeliveryModelSchema.defined(),
    orderProducts: yup.array(orderProductModelSchema).nullable().defined(),
    commercialOffer: purchaseOrderCommercialOfferModelSchema.nullable().defined(),
  })
  .nullable()
  .defined()
