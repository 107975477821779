import ArrowLeftSvg from '@assets/icons/arrow-left.svg'
import ArrowRightSvg from '@assets/icons/arrow-right.svg'
import { Icon } from '@share/components/ui'

import { useSidebarStore } from '../../../model'

import s from './button-collapse.module.scss'

export const ButtonCollapse: React.FC = () => {
  const { isCollapsed, toggleCollapse } = useSidebarStore()

  return (
    <button type='button' className={s.root} onClick={toggleCollapse}>
      <Icon icon={!isCollapsed ? ArrowLeftSvg : ArrowRightSvg} />
    </button>
  )
}
