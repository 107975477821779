import * as yup from 'yup'
import { InferType } from 'yup'

import { assetModelSchema } from '../asset'
import { paginationResponseSchema } from '../core.schemas'

export const deliveryOrderAssetSchema = yup.object().shape({
  id: yup.string().defined(),
  asset: assetModelSchema.defined(),
})

export const deliveryOrderAssetsSchema = yup.array().of(deliveryOrderAssetSchema)

export type DeliveryOrderAssetModel = yup.InferType<typeof deliveryOrderAssetSchema>
export type DeliveryOrderAssetsModel = yup.InferType<typeof deliveryOrderAssetsSchema>

export const deliveryAssetsSelectionRequestSchema = yup.object({
  assetIds: yup.array().of(yup.string()),
})
export const deliveryAssetsResponseSchema = paginationResponseSchema(assetModelSchema)

export type DeliveryAssetsSelectionRequest = yup.InferType<
  typeof deliveryAssetsSelectionRequestSchema
>
export type DeliveryAssetsResponse = InferType<typeof deliveryAssetsResponseSchema>
