import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  orderNameEditDefaultFormValues,
  type OrderNameEditFormValues,
  orderNameEditValidationSchema,
} from '../libs'

export const useOrderNameEditForm = (values?: OrderNameEditFormValues) =>
  useForm<OrderNameEditFormValues>({
    values,
    defaultValues: orderNameEditDefaultFormValues,
    resolver: yupResolver(orderNameEditValidationSchema),
  })
