import { useModal } from '@ebay/nice-modal-react'

import { ORDER_DISPUTE_MODAL_ID, OrderDisputeDialogProps } from '../libs'

export const useOrderDisputeDialogControl = (initialProps?: OrderDisputeDialogProps) => {
  const modalMethods = useModal(ORDER_DISPUTE_MODAL_ID)

  return {
    visible: modalMethods.visible,
    show: (props?: Partial<OrderDisputeDialogProps>) =>
      modalMethods.show({ ...initialProps, ...props }),
    remove: modalMethods.remove,
  }
}
