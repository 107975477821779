import { initReactI18next } from 'react-i18next'
import { getLanguage } from '@share/utils'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEn from './en/translation.json'

const resources = {
  en: {
    translation: translationEn,
  },
}

const language = getLanguage()

const initOptions = {
  fallbackLng: 'en',
  lng: language || 'en',
  interpolation: {
    escapeValue: false,
  },
  resources,
  returnNull: true,
}

i18n.use(LanguageDetector).use(initReactI18next).init(initOptions)

export default i18n
