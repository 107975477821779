/* eslint-disable no-param-reassign */
import { useUserStore } from '@features/auth/model'
import { toastError } from '@share/components/ui/molecules/notifications'
import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

type AxiosErrorWithMessage = AxiosError<{ error: string }>

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => config
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
const onResponse = (response: AxiosResponse): AxiosResponse => response
const onResponseError = (error: AxiosErrorWithMessage) => {
  const { response, message } = error

  if (response?.status === 401 || response?.status === 403) {
    toastError({
      title: 'Unauthorized',
      message,
    })
    useUserStore.getState().signOut()
  }

  return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)

  return axiosInstance
}
