import { Nullable } from '@dots/uikit'
import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { OrderAttachmentsModel, OrderCommentModel, OrderStatus } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'

interface UpdatePurchaseOrderDisputeInformation {
  requesterEmail: string
  subject: string
  issueType: string
  description: string
  attachments: OrderAttachmentsModel
}

interface UpdatePurchaseOrderStatusRequestData {
  status: OrderStatus
  purchaseOrderNumber?: string
  deliveryTrackingNumber?: Nullable<string>
  deliveryTrackingLink?: Nullable<string>
  cancelReason?: string
  dispute?: UpdatePurchaseOrderDisputeInformation
  actualEta?: [string] | [string, string]
  comment?: Nullable<OrderCommentModel['comment']>
}

export interface UpdatePurchaseOrderStatusRequest {
  orderId: string
  requestData: UpdatePurchaseOrderStatusRequestData
}

const updatePurchaseOrderStatus = ({ orderId, requestData }: UpdatePurchaseOrderStatusRequest) =>
  apiRequest({
    method: 'PATCH',
    url: API_LIST.order.purchase.slug.status(orderId),
    data: requestData,
  })

export const useUpdatePurchaseOrderStatus = () => useMutation(updatePurchaseOrderStatus)
