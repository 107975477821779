import { Children, ClassName } from '@share/@types'
import cn from 'classnames'

import s from './group.module.scss'

export interface GroupProps extends ClassName, Children {
  gap?: number
  position?: 'start' | 'center' | 'end' | 'apart'
  align?: 'top' | 'middle' | 'bottom'
  component?: React.ElementType
  onClick?: () => void
}

export const Group: React.FC<GroupProps> = ({
  className,
  children,
  gap,
  position = 'start',
  align = 'center',
  component: Component = 'div',
  onClick,
  ...rest
}) => (
  <Component
    className={cn(
      s.root,
      {
        [s.start]: position === 'start',
        [s.center]: position === 'center',
        [s.end]: position === 'end',
        [s.apart]: position === 'apart',
        [s.top]: align === 'start',
        [s.middle]: align === 'middle',
        [s.bottom]: align === 'end',
      },
      className,
    )}
    style={{ gap }}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Component>
)
