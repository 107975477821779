import * as yup from 'yup'

import { productModelSchema } from '../product'

export const orderAlternativeProductModelSchema = yup.object().shape({
  product: productModelSchema,
  id: yup.string().defined(),
  price: yup.number().defined(),
  productCodeDpn: yup.string().defined().nullable(),
})

export const orderAlternativeProductsModelSchema = yup
  .array()
  .of(orderAlternativeProductModelSchema)

export type OrderAlternativeProductModel = yup.InferType<typeof orderAlternativeProductModelSchema>
export type OrderAlternativeProductsModel = yup.InferType<
  typeof orderAlternativeProductsModelSchema
>
