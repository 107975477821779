import * as yup from 'yup'

const attachmentSchema = yup
  .object({
    createdAt: yup.string().defined(),
    id: yup.string().defined(),
    name: yup.string().defined(),
    orderDisputeFormId: yup.string().defined(),
    updatedAt: yup.string().defined(),
    url: yup.string().defined(),
  })
  .noUnknown()

export const orderDisputeSchema = yup
  .object()
  .shape({
    subject: yup.string().defined(),
    requesterEmail: yup.string().defined(),
    orderId: yup.string().defined(),
    issueType: yup.string().defined(),
    id: yup.string().defined(),
    description: yup.string().defined(),
    closed: yup.boolean().defined(),
    attachments: yup.array().of(attachmentSchema).defined(),
    createdAt: yup.string().defined(),
    updatedAt: yup.string().defined().nullable(),
  })
  .noUnknown()

export const orderDisputesSchema = yup.array().of(orderDisputeSchema)

export type OrderDisputeModel = yup.InferType<typeof orderDisputeSchema>
export type OrderDisputesModel = yup.InferType<typeof orderDisputesSchema>
