import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  DeliveryCommercialOfferMutationFormValues,
  deliveryOrderCommercialOfferDefaultValues,
  deliveryOrderCommercialOfferFormValidationSchema,
} from '../libs'

export const useDeliveryCommercialOfferForm = (
  values?: DeliveryCommercialOfferMutationFormValues,
) =>
  useForm<DeliveryCommercialOfferMutationFormValues>({
    values,
    defaultValues: deliveryOrderCommercialOfferDefaultValues,
    resolver: yupResolver(deliveryOrderCommercialOfferFormValidationSchema),
  })
