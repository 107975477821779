import { useModal } from '@ebay/nice-modal-react'
import { DELETE_EVENT_MODAL_ID } from '@share/constants'

import { DeleteEventProps } from '../libs'

export const useDeleteEventControl = (initialProps?: DeleteEventProps) => {
  const modalMethods = useModal(DELETE_EVENT_MODAL_ID)

  return {
    visible: modalMethods.visible,
    show: (props?: Partial<DeleteEventProps>) => modalMethods.show({ ...initialProps, ...props }),
    remove: modalMethods.remove,
    resolve: modalMethods.resolve,
  }
}
