import { forwardRef, useCallback, useId } from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import s from './textarea.module.scss'

export interface TextareaProps
  extends ClassName,
    Pick<TextareaAutosizeProps, 'minRows' | 'maxRows'> {
  invalid?: boolean
  disabled?: boolean
  value?: string | number
  placeholder?: string
  onChange?: (value: string | number, e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className = '', disabled, value, invalid, onChange, ...rest }, ref) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e.target.value, e),
      [onChange],
    )

    const id = useId()

    const rootClassName = cn(s.root, { [s.disabled]: disabled, [s.invalid]: invalid }, className)

    return (
      <label htmlFor={id} className={rootClassName}>
        <TextareaAutosize
          id={id}
          className={s.textarea}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
      </label>
    )
  },
)

Textarea.displayName = 'Textarea'
