import { useTranslation } from 'react-i18next'
import Dot from '@assets/icons/dot.svg'
import TechSpecsIcon from '@assets/icons/tech-specs.svg'
import { EntitySpecs, Group, Icon, Stack, Typography, WithIcon } from '@share/components/ui'
import type { ProductModel } from '@share/schemas'

import { ProductPreview } from '../../atoms'

import s from './product-details.module.scss'

interface ProductDetailsProps {
  product: ProductModel
  productAdditionalInfoSlot?: React.ReactNode
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  product,
  productAdditionalInfoSlot,
}) => {
  const { t } = useTranslation()

  return (
    <Stack gap={16}>
      <Group gap={12} align='middle' className={s.preview}>
        <ProductPreview image={product.image} category={product.category.category!} />

        <Stack>
          <Typography variant='b2'>{product.name}</Typography>
          <Group gap={8} align='middle'>
            <Typography variant='b3' className='fcSecondary'>
              {product.category.name}
            </Typography>
            <Icon icon={Dot} className={s.dot} />
            <Typography variant='b3' className='fcSecondary'>
              {product.subcategory.name}
            </Typography>
          </Group>
        </Stack>
      </Group>

      {productAdditionalInfoSlot}
      <EntitySpecs
        specs={product.specs || []}
        titleSlot={
          <WithIcon icon={TechSpecsIcon} iconClassname='blackSecondary'>
            <Typography variant='c2'>
              {t('catalog.view-catalog-product.tech-specs.title')}
            </Typography>
          </WithIcon>
        }
      />
    </Stack>
  )
}
