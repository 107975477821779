import { forwardRef } from 'react'
import cn from 'classnames'

import { Stack } from '../../atoms'
import { IRadioGroupProps, RadioGroup } from '../../molecules'

import { TextFieldLabel, TextFieldLabelProps } from './text-field-label'
import { TextFieldMessage, TextFieldMessageProps } from './text-field-message'

import s from './field.module.scss'

export interface RadioGroupFieldProps extends IRadioGroupProps {
  required?: boolean
  label?: string
  inline?: boolean
  messageType?: TextFieldMessageProps['messageType']
  message?: TextFieldMessageProps['message']
  supportingText?: TextFieldLabelProps['supportingText']
}

export const RadioGroupField = forwardRef<HTMLDivElement, RadioGroupFieldProps>(
  (
    {
      className,
      inline,
      required,
      label,
      message,
      messageType,
      supportingText,
      ...radioGroupProps
    },
    ref,
  ) => {
    const rootClassName = cn(
      s.root,
      {
        [s.inline]: inline,
      },
      className,
    )

    return (
      <div className={rootClassName}>
        {label && (
          <TextFieldLabel label={label} required={required} supportingText={supportingText} />
        )}

        <Stack gap={6} className={s.container}>
          <RadioGroup {...radioGroupProps} ref={ref} />
          {message && <TextFieldMessage message={message} messageType={messageType} />}
        </Stack>
      </div>
    )
  },
)

RadioGroupField.displayName = 'RadioGroupField'
