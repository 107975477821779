import * as yup from 'yup'

import { CategoryTypes, possibleCategoryTypes } from '../categories'

export const productCategoryModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  category: yup.mixed<CategoryTypes>().defined().oneOf(possibleCategoryTypes),
  code: yup.string().defined(),
})

export const productCategoriesModelSchema = yup
  .array()
  .of(productCategoryModelSchema.defined())
  .defined()

export type ProductCategoryModel = yup.InferType<typeof productCategoryModelSchema>

export type ProductCategoriesModel = yup.InferType<typeof productCategoriesModelSchema>
