/* eslint-disable simple-import-sort/imports */
import 'sanitize.css/sanitize.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@share/styles/chrome-bug.css'
import '@share/styles/document.scss'
import '@dots/uikit/styles'

import '@assets/locales/i18n'

import { BrowserTracing, Replay, init, reactRouterV6Instrumentation } from '@sentry/react'
import { HttpClient } from '@sentry/integrations'

import ReactDOM from 'react-dom/client'
import FontFaceObserver from 'fontfaceobserver'

import { APP_VERSION } from '@share/constants'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { SENTRY_DNS, SENTRY_ENV } from '@app/services/config'
import App from './app/app'

const openSansObserver = new FontFaceObserver('Satoshi', {})

openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: SENTRY_DNS,
    integrations: [
      new HttpClient(),
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: APP_VERSION,
    environment: SENTRY_ENV,
  })
}

const MOUNT_NODE = document.getElementById('root') as HTMLElement

interface IProps {
  Component: typeof App
}
const ConnectedApp = ({ Component }: IProps) => <Component />

const render = (Component: typeof App) => {
  const root = ReactDOM.createRoot(MOUNT_NODE)
  root.render(<ConnectedApp Component={Component} />)
}

render(App)
