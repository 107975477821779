import { PaginationState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { paginationResponseSchema, vendorModelSchema } from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = paginationResponseSchema(vendorModelSchema)
type GetVendorsResponse = yup.InferType<typeof responseSchema>

interface GetVendorsRequest extends Partial<PaginationState> {
  name?: string
}

type UseGetVendorsParams = {
  params?: GetVendorsRequest
  options?: UseQueryOptions<GetVendorsResponse>
}

const getVendorsRequest = async (params?: GetVendorsRequest) =>
  apiRequest<GetVendorsResponse, Error>({
    method: 'GET',
    url: API_LIST.vendor.root,
    params,
    responseSchema,
  })

export const useGetVendors = ({ params, options }: UseGetVendorsParams) =>
  useQuery({
    queryKey: [API_TAGS.vendor.root, params],
    queryFn: () => getVendorsRequest(params),
    keepPreviousData: true,
    ...options,
  })

export const invalidateVendors = () => queryClient.invalidateQueries([API_TAGS.vendor.root])
