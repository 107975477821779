import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { create, useModal } from '@ebay/nice-modal-react'
import { OrderActionDialog } from '@entities/order'
import { Dialog, Form, FormTextareaControl, Stack } from '@share/components/ui'
import { useUpdatePurchaseOrder } from '@share/hooks/api'
import { undefToEmptyString } from '@share/utils'

import { PURCHASE_ORDER_NOTES_EDIT_DIALOG_ID } from '../../libs/consts'
import { formConfig } from '../../libs/form'
import { FormValues, OrderNotesEditDialogProps } from '../../libs/types'

export const PurchaseOrderNotesEditDialog = create<OrderNotesEditDialogProps>(
  ({ orderId, notes, onAfterSave }) => {
    const { t } = useTranslation()
    const updateOrder = useUpdatePurchaseOrder()
    const { control, formState, handleSubmit } = useForm<FormValues>({
      values: { notes: undefToEmptyString(notes) },
      ...formConfig,
    })
    const modalMethods = useModal(PURCHASE_ORDER_NOTES_EDIT_DIALOG_ID)

    const onSubmit = handleSubmit((formValues) => {
      const requestData = {
        orderId,
        requestData: formValues,
      }

      updateOrder.mutateAsync(requestData).then(onAfterSave).then(modalMethods.remove)
    })

    const SlotFooter = useMemo(
      () => (
        <Dialog.Footer
          buttonOkProps={{
            children: t('orders.modals.order-notes.actions.submit'),
            disabled: !formState.isDirty,
            loading: updateOrder.isLoading,
            onClick: onSubmit,
          }}
        />
      ),
      [formState.isDirty, updateOrder.isLoading, t, onSubmit],
    )

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.order-notes.title')}
        subTitle={t('orders.modals.order-notes.subtitle')}
        onOpenChange={modalMethods.remove}
        slotFooter={SlotFooter}
      >
        <Form.Root style={{ minHeight: '200px' }}>
          <Stack gap={16}>
            <FormTextareaControl
              control={control}
              minRows={5}
              maxRows={8}
              name='notes'
              label={t('orders.modals.order-notes.notes.label')}
              placeholder={t('orders.modals.order-notes.notes.placeholder')}
            />
          </Stack>
        </Form.Root>
      </OrderActionDialog>
    )
  },
)
