import * as yup from 'yup'

const possibleAssetAssignType = ['STAFF', 'SPACE'] as const

export type AssetAssignType = (typeof possibleAssetAssignType)[number]

export const assetAssignTypeSchema = yup
  .mixed<AssetAssignType>()
  .defined()
  .oneOf(possibleAssetAssignType)

export const assetAssignToModelSchema = yup.object({
  id: yup.string().defined(),
  type: assetAssignTypeSchema.defined(),
  name: yup.string().defined(),
  additionalDescription: yup.string().defined(),
  avatar: yup.string().defined(),
})

export type AssetAssignToModel = yup.InferType<typeof assetAssignToModelSchema>
