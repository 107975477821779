import * as yup from 'yup'

import { userTeamModelSchema } from './user-team.schemas'

export const userRoleModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  team: userTeamModelSchema.defined(),
  teamId: yup.string().defined(),
})

export type UserRoleModel = yup.InferType<typeof userRoleModelSchema>
