import * as yup from 'yup'

const possibleAssetLocationType = ['STAFF', 'SPACE', 'OFFICE', 'WAREHOUSE'] as const

export type AssetLocationType = (typeof possibleAssetLocationType)[number]

export const assetLocationTypeSchema = yup
  .mixed<AssetLocationType>()
  .defined()
  .oneOf(possibleAssetLocationType)

export const assetLocationModelSchema = yup.object({
  id: yup.string().defined(),
  image: yup.string().defined(),
  type: assetLocationTypeSchema.defined(),
  addressTitle: yup.string().defined(),
  addressValue: yup.string().defined(),
})

export type AssetLocationModel = yup.InferType<typeof assetLocationModelSchema>
