import { useCallback } from 'react'
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { PropsValue } from 'react-select'

import { SelectField, SelectFieldProps } from '../fields'

export interface FormSelectControlProps
  extends Omit<
    SelectFieldProps,
    'invalid' | 'message' | 'onChange' | 'onBlur' | 'ref' | 'value' | 'name'
  > {
  onChangeCallback?: (value: unknown) => void
}

export const FormSelectControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  rules,
  shouldUnregister,
  onChangeCallback,
  ...rest
}: UseControllerProps<TFieldValues, TName> & FormSelectControlProps) => {
  const {
    field: { onChange, ...otherFieldProps },
    fieldState,
  } = useController({
    name,
    rules,
    control,
    defaultValue,
    shouldUnregister,
  })

  const onChangeMemoized = useCallback(
    (value: PropsValue<string | number | { type: string; value: string | number }> | null) => {
      onChange(value)

      if (onChangeCallback) {
        onChangeCallback(value)
      }
    },
    [onChangeCallback, onChange],
  )

  return (
    <SelectField
      invalid={fieldState.invalid}
      message={fieldState.error?.message}
      onChange={onChangeMemoized}
      {...otherFieldProps}
      {...rest}
    />
  )
}
