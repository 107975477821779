import * as yup from 'yup'

const possibleAssetsDashStatuses = [
  'Pending',
  'In use',
  'In storage',
  'Malfunctioning',
  'In transit',
  'Archived',
] as const

export type AssetsDashboardStatuses = (typeof possibleAssetsDashStatuses)[number]

const assetsDashboardStatusResponseSchema = yup.object({
  count: yup.number().required().defined(),
  status: yup
    .mixed<AssetsDashboardStatuses>()
    .defined()
    .oneOf(possibleAssetsDashStatuses)
    .required(),
})

export const assetsDashboardStatusesCountSchema = yup
  .array()
  .of(assetsDashboardStatusResponseSchema)
  .defined()

export type AssetsDashboardStatusCountModel = yup.InferType<
  typeof assetsDashboardStatusResponseSchema
>
export type AssetsDashboardStatusCountResponse = yup.InferType<
  typeof assetsDashboardStatusesCountSchema
>
