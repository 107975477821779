import ArrowDownIcon from '@assets/icons/arrow-down.svg'
import cn from 'classnames'

import { Icon } from '../../atoms'

import s from './select.module.scss'

interface SelectDropdownIndicatorProps {
  isFocused?: boolean
}

export const SelectDropdownIndicator: React.FC<SelectDropdownIndicatorProps> = ({ isFocused }) => (
  <Icon className={cn(s.triggerCaret, { [s.isOpen]: isFocused })} icon={ArrowDownIcon} />
)
