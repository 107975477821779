import { AssetModel, AssetsModel } from '@share/schemas'
import { create } from 'zustand'

interface DeliverySelectedAssetsStore {
  selectedAssets: AssetsModel
  computed: {
    selectedAssetsCount: number
  }
  onToggleSelectedAssets: (assetItem: AssetModel) => void
  setInitialSelectedAssets: (assets: AssetsModel) => void
  onResetSelectedAssets: () => void
}
export const useSelectedDeliveryAssetsStore = create<DeliverySelectedAssetsStore>()((set, get) => ({
  selectedAssets: [],
  computed: {
    get selectedAssetsCount() {
      return get().selectedAssets.length
    },
  },
  onToggleSelectedAssets: (assetItem: AssetModel) =>
    set((state) => {
      const isAlreadySelected = Boolean(
        state.selectedAssets.find((asset) => asset.id === assetItem.id),
      )

      const newSelectedAssets = isAlreadySelected
        ? state.selectedAssets.filter((asset) => asset.id !== assetItem.id)
        : [...state.selectedAssets, assetItem]

      return {
        selectedAssets: newSelectedAssets,
      }
    }),
  setInitialSelectedAssets: (assets) => set({ selectedAssets: assets }),
  onResetSelectedAssets: () => set({ selectedAssets: [] }),
}))

export const selectedDeliveryAssetsSelector = (store: DeliverySelectedAssetsStore) =>
  store.selectedAssets
export const selectedDeliveryAssetsCountSelector = (store: DeliverySelectedAssetsStore) =>
  store.computed.selectedAssetsCount
export const onToggleSelector = (store: DeliverySelectedAssetsStore) => store.onToggleSelectedAssets
export const setInitialAssetsSelector = (store: DeliverySelectedAssetsStore) =>
  store.setInitialSelectedAssets
export const onResetSelector = (store: DeliverySelectedAssetsStore) => store.onResetSelectedAssets
export const useDeliveryAssetStoreSelector = <T>(
  selector: (store: DeliverySelectedAssetsStore) => T,
) => useSelectedDeliveryAssetsStore(selector)
