import { Stack } from '@share/components/ui/atoms'

import { StepLineItem, StepLineItemProps } from './stepline-item'

export interface SteplineProps {
  list: StepLineItemProps[]
}

export const Stepline: React.FC<SteplineProps> = ({ list = [] }) => (
  <Stack>
    {list.map((item, index) => (
      <StepLineItem key={`${item.label}${index}`} {...item} />
    ))}
  </Stack>
)
