import { Button, ButtonProps, Group, Stack } from '@share/components/ui/atoms'

import s from './modal.module.scss'

export interface ModalFooterProps {
  children?: React.ReactNode
  buttonOkProps?: ButtonProps
  buttonAdditionalActionProps?: ButtonProps
  buttonCancelProps?: ButtonProps
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  buttonOkProps = {
    size: 'large',
  },
  buttonAdditionalActionProps = {
    size: 'large',
  },
  buttonCancelProps = {
    size: 'large',
  },
  children,
}) => (
  <Stack>
    {children}

    <Group gap={16} className={s.footer} position='end'>
      {buttonCancelProps.children && (
        <Button className={s.buttonCancel} {...buttonCancelProps}>
          {buttonCancelProps.children}
        </Button>
      )}

      {buttonAdditionalActionProps.children && (
        <Button {...buttonAdditionalActionProps}>{buttonAdditionalActionProps.children}</Button>
      )}
      <Button {...buttonOkProps}>{buttonOkProps.children}</Button>
    </Group>
  </Stack>
)
