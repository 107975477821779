import { forwardRef } from 'react'
import { Group, Icon, IconProps, Stack, Typography } from '@dots/uikit'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { IconHolder, Switch, SwitchProps } from '../../../atoms'

import s from './switch-box-field.module.scss'

export interface SwitchBoxFieldProps extends ClassName, SwitchProps {
  icon: IconProps['icon']
  title: string
  subtitle: string
}

export const SwitchBoxField = forwardRef<HTMLButtonElement, SwitchBoxFieldProps>(
  ({ icon, title, subtitle, className, ...switchProps }, ref) => {
    const { value } = switchProps
    const rootClassName = cn(s.root, { [s.active]: value }, className)

    return (
      <Group className={rootClassName} align='middle' gap={12}>
        <IconHolder size='medium' color={value ? 'forestGreenDark' : 'peach'}>
          <Icon icon={icon} />
        </IconHolder>

        <Stack>
          <Typography variant='b2'>{title}</Typography>
          <Typography variant='b3' className='fcSecondary'>
            {subtitle}
          </Typography>
        </Stack>

        <Switch className={s.switch} {...switchProps} ref={ref} />
      </Group>
    )
  },
)

SwitchBoxField.displayName = 'SwitchBoxField'
