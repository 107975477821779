import { PaginationState, SortState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { paginationResponseSchema, updateModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

export type UpdatesRequestParams = Partial<PaginationState & SortState>

const responseSchema = paginationResponseSchema(updateModelSchema)
export type UpdatesResponse = yup.InferType<typeof responseSchema>

const getUpdates = async (params?: UpdatesRequestParams) =>
  apiRequest<UpdatesResponse>({
    method: 'GET',
    url: API_LIST.updates.root,
    params,
    responseSchema,
  })

export const useGetUpdates = (params?: UpdatesRequestParams) =>
  useQuery({
    queryKey: [API_TAGS.updates.root, params],
    queryFn: () => getUpdates(params),
    keepPreviousData: true,
  })

export const invalidateUpdates = () => queryClient.invalidateQueries([API_TAGS.updates.root])
