import { forwardRef } from 'react'
import { Children } from '@share/@types'
import cn from 'classnames'

import { Icon } from '../icon/icon'

import { Loading } from './loading'
import { BaseButtonProps } from './types'

import s from './button.module.scss'

export type ButtonUploadProps = BaseButtonProps &
  Children & {
    accept?: string
    onChange?: (file: File) => void
  }

export const ButtonUpload = forwardRef<HTMLInputElement, ButtonUploadProps>((props, inputRef) => {
  const {
    className,
    children,
    loading = false,
    disabled = false,
    fullWidth,
    size = 'medium',
    iconSize = 'medium',
    variant = 'filled',
    color = '',
    icon,
    iconPosition = 'before',
    onChange,
    ...rest
  } = props
  const rootClassName = cn(
    s.root,
    {
      [s[size]]: size,
      [s[variant]]: variant,
      [s[color]]: color,
      [s.fullWidth]: fullWidth,
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className,
  )

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!loading && onChange) {
      onChange(event.target.files![0])
    }
  }

  return (
    <label role='button' className={rootClassName}>
      <span className={s.inner}>
        {loading && <Loading />}

        {!loading && (
          <>
            {icon && iconPosition === 'before' && (
              <Icon size={iconSize} className={s.icon} icon={icon} />
            )}
            <span className={s.label}>{children}</span>
            {icon && iconPosition === 'after' && (
              <Icon size={iconSize} className={s.icon} icon={icon} />
            )}
          </>
        )}
      </span>

      <input
        type='file'
        ref={inputRef}
        className={s.inputFile}
        onChange={onChangeHandler}
        disabled={disabled || loading}
        {...rest}
      />
    </label>
  )
})

ButtonUpload.displayName = 'ButtonUpload'
