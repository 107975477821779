import ErrorIcon from '@assets/icons/error.svg'
import cn from 'classnames'

import { Group, Icon, Stack, Typography } from '../../atoms'

import s from './field.module.scss'

export interface TextFieldMessageProps {
  messageType?: 'error' | 'warning' | 'info' | 'success'
  message?: string
  assistiveText?: string
}

export const TextFieldMessage: React.FC<TextFieldMessageProps> = ({
  message,
  assistiveText,
  messageType = 'error',
}) => (
  <Stack gap={4}>
    {message && (
      <Group gap={4} className={cn([s[messageType]])}>
        <Icon icon={ErrorIcon} />
        <Typography variant='b3'>{message}</Typography>
      </Group>
    )}
    {assistiveText && (
      <Typography className={s.secondaryText} variant='b3'>
        {assistiveText}
      </Typography>
    )}
  </Stack>
)
