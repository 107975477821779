import { OrderSubtype } from '@share/schemas'

export const ORDER_PENDING_STATUSES = ['PROCESSING', 'PENDING_OFFER']

export const ORDER_SUBTYPE_SUBTITLES: Record<OrderSubtype, string> = {
  PURCHASE: 'Purchase',
  DELIVERY: 'Delivery',
  STOCKING: 'Warehousing',
  ONBOARDING: 'Onboarding',
  OFFBOARDING: 'Offboarding',
}

export const ORDER_DISPUTE_SUCCESS_ALERT_ID = 'order-dispute-success-alert'
