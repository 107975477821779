import cn from 'classnames'

import { useSidebarStore } from '../../../model'

import s from './bar.module.scss'

interface BarProps {
  children: React.ReactNode
}

export const Bar: React.FC<BarProps> = ({ children }) => {
  const { isCollapsed } = useSidebarStore()

  return <aside className={cn(s.root, { [s.open]: !isCollapsed })}>{children}</aside>
}
