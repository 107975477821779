import type { ApiResponseSuccessWithMessage } from '@share/@types'
import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const purchasePaymentInfoRequestSchema = yup
  .object({
    companyLegalEntityId: yup.string().defined(),
    paymentInformationId: yup.string().defined(),
  })
  .defined()
  .noUnknown()

export type PurchasePaymentInfoRequest = yup.InferType<typeof purchasePaymentInfoRequestSchema>

interface UpdatePurchaseOrderPaymentInfoRequest {
  id: string
  requestData: PurchasePaymentInfoRequest
}

const updatePurchaseOrderPaymentInfoRequest = ({
  id,
  requestData,
}: UpdatePurchaseOrderPaymentInfoRequest) =>
  apiRequest<ApiResponseSuccessWithMessage>({
    method: 'PATCH',
    url: API_LIST.order.purchase.slug.paymentInfo(id),
    data: requestData,
  })

export const useUpdatePurchaseOrderPaymentInfoRequest = () =>
  useMutation(updatePurchaseOrderPaymentInfoRequest)
