import { useOrderDisputeDialogControl } from '../hooks'
import { useOrderDisputeForm } from '../hooks/use-form'
import { OrderDisputeDialogProps } from '../libs'

export const useOrderDisputeController = ({
  issuesList,
  formValues,
  disabled,
  isSubmitting,
  isLoading,
  preview,
  onSubmit,
}: OrderDisputeDialogProps) => {
  const modalMethods = useOrderDisputeDialogControl()
  const formOrderDisputeMethods = useOrderDisputeForm(formValues)
  const handleOnSubmit = formOrderDisputeMethods.handleSubmit(onSubmit)

  return {
    isSubmitting,
    isLoading,
    preview,
    disabled,
    issuesList,
    modalVisible: modalMethods.visible,

    formControl: formOrderDisputeMethods.control,

    onCancel: modalMethods.remove,
    onSubmit: handleOnSubmit,
  }
}
