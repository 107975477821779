import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import type { AssetAttachment, FileUploadEntities } from '@share/@types'
import type { ButtonUploadProps } from '@share/components/ui'
import { ACCEPT_FILE_EXTENSIONS } from '@share/constants'
import { useDeleteFile, useUploadFile } from '@share/hooks/api'
import { getFileAcceptExtensions } from '@share/utils'

import { AttachmentFile } from './attachment-file/attachment-file'
import { AttachmentArea } from './attachment-area'
import { AttachmentButton } from './attachment-button'

type Item = AssetAttachment & {
  id: string
}

export interface AttachmentsProps {
  items: Item[]
  onAppend: (newItem: AssetAttachment) => void
  onRemove: (itemIndex: number) => void
  accept?: string[]
  preview?: boolean
  disabled?: boolean
  single?: boolean
  entityType?: FileUploadEntities
  buttonUploadProps?: Partial<ButtonUploadProps>
}

export const Attachments = forwardRef<HTMLInputElement, AttachmentsProps>(
  (
    {
      items,
      onAppend,
      onRemove,
      accept = ACCEPT_FILE_EXTENSIONS,
      preview,
      disabled,
      single = false,
      entityType = 'assetFile',
      buttonUploadProps,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const isHideAttachFile = single && Boolean(items.length)

    const convertedAccept = getFileAcceptExtensions(accept)

    const uploadMethods = useUploadFile()
    const deleteMethods = useDeleteFile()

    const onFileUpload = useCallback(
      async (file: File) => {
        if (!file) return

        const formData = new FormData()

        formData.append('entity', entityType)
        formData.append('file', file)

        const response = await uploadMethods.mutateAsync(formData)

        onAppend({
          name: file.name,
          url: response.file,
        })
      },
      [entityType, uploadMethods, onAppend],
    )

    const onFileDelete = useCallback(
      async (file: Item, itemIndex: number) => {
        await deleteMethods.mutateAsync({ url: file.url })
        onRemove(itemIndex)
      },
      [deleteMethods, onRemove],
    )

    return (
      <AttachmentArea>
        {items.map((item, index) => {
          const handleOnRemove = () => onFileDelete(item, index)

          return (
            <AttachmentFile
              key={item.id}
              title={item.name}
              fileUrl={item.url}
              preview={preview}
              onRemove={handleOnRemove}
              loading={deleteMethods.isLoading}
            />
          )
        })}

        {!isHideAttachFile && (
          <div>
            <AttachmentButton
              ref={ref}
              onChange={onFileUpload}
              accept={convertedAccept}
              disabled={disabled}
              loading={uploadMethods.isLoading}
              {...buttonUploadProps}
            >
              {t('file-attach.attach')}
            </AttachmentButton>
          </div>
        )}
      </AttachmentArea>
    )
  },
)

Attachments.displayName = 'Attachments'
