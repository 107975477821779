import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import cn from 'classnames'

import s from './scroll-area.module.scss'

type ScrollAreaProps = RadixScrollArea.ScrollAreaProps & {
  infiniteScrollId?: string
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({
  className,
  children,
  infiniteScrollId,
  ...rest
}) => (
  <RadixScrollArea.Root className={cn(s.root, className)} {...rest}>
    <RadixScrollArea.Viewport id={infiniteScrollId} className={s.viewport}>
      {children}
    </RadixScrollArea.Viewport>

    <RadixScrollArea.Scrollbar orientation='vertical' className={s.scrollbar}>
      <RadixScrollArea.Thumb className={s.thumb} />
    </RadixScrollArea.Scrollbar>
    <RadixScrollArea.Corner />
  </RadixScrollArea.Root>
)
