import * as yup from 'yup'

import { assetModelSchema } from '../asset/asset.schemas'

export const orderAssetModelSchema = yup.object({
  id: yup.string().required(),
  asset: assetModelSchema.defined(),
})

export const orderAssetsModelSchema = yup.array(orderAssetModelSchema).defined().nullable()
