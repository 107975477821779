import { Button, ButtonProps } from '../../../../atoms'

import s from './dialog.module.scss'

export interface FooterProps {
  buttonOkProps?: ButtonProps
  buttonCancelProps?: ButtonProps
}

export const Footer: React.FC<FooterProps> = ({
  buttonOkProps = {
    size: 'large',
  },
  buttonCancelProps = {
    size: 'large',
  },
}) => (
  <div className={s.footer}>
    {buttonCancelProps.children && (
      <Button
        className={s.buttonCancel}
        variant='transparent'
        color='secondary'
        {...buttonCancelProps}
      >
        {buttonCancelProps.children}
      </Button>
    )}

    <Button {...buttonOkProps}>{buttonOkProps.children}</Button>
  </div>
)
