import { ResponseWithResult } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { getResponseWithResultSchema } from '@share/schemas'
import { PaymentInformationModel, paymentInformationModelSchema } from '@share/schemas/payment'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

type GetPaymentInformationResponse = ResponseWithResult<PaymentInformationModel>

const responseSchema = getResponseWithResultSchema(paymentInformationModelSchema)

const getPaymentInformationRequest = () =>
  apiRequest<GetPaymentInformationResponse>({
    url: API_LIST.dictionaries.payment.information,
    responseSchema,
  })

export const useGetPaymentInformation = (
  options?: UseQueryOptions<GetPaymentInformationResponse>,
) =>
  useQuery({
    queryKey: API_TAGS.dictionaries.paymentInformation(),
    queryFn: getPaymentInformationRequest,
    ...options,
  })
