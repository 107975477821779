import * as yup from 'yup'

import {
  cityModelSchema,
  countryModelSchema,
  countryOfficeModelSchema,
  regionModelSchema,
  stateModelSchema,
} from '../country'

import { OfficeType, possibleOfficeType } from './office-type.schema'

export const officeModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  address: yup.string().defined(),
  companyId: yup.string().defined(),
  assetCount: yup.number().defined(),
  countryId: yup.string().defined(),
  cityId: yup.string().nullable().defined(),
  deletedAt: yup.date().nullable().defined(),
  image: yup.string().nullable().defined(),
  lat: yup.number().nullable().defined(),
  long: yup.number().nullable().defined(),
  notes: yup.string().nullable().defined(),
  assetsCount: yup.number(),
  city: cityModelSchema.defined().nullable(),
  country: countryModelSchema.defined(),
  region: regionModelSchema.defined(),
  assets: yup.array().defined(),
  spaces: yup.array().defined(),
  type: yup.mixed<OfficeType>().oneOf(possibleOfficeType).defined(),
  zipCode: yup.string().nullable().defined(),
  state: stateModelSchema.nullable().defined(),
})

export const officeCompanyModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  address: yup.string().defined(),
  companyId: yup.string().defined(),
  assetCount: yup.number().defined(),
  countryId: yup.string().defined(),
  cityId: yup.string().nullable().defined(),
  deletedAt: yup.date().nullable().defined(),
  image: yup.string().nullable().defined(),
  lat: yup.number().nullable().defined(),
  long: yup.number().nullable().defined(),
  notes: yup.string().nullable().defined(),
  city: cityModelSchema.defined().nullable(),
  country: countryOfficeModelSchema.defined(),
  region: regionModelSchema.defined(),
  assets: yup.array().defined(),
  spaces: yup.array().defined(),
})

export const officeCompanyUpdateModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  address: yup.string().defined(),
  companyId: yup.string().defined(),
  countryId: yup.string().defined(),
  cityId: yup.string().nullable().defined(),
  deletedAt: yup.date().nullable().defined(),
  image: yup.string().nullable().defined(),
  lat: yup.number().nullable().defined(),
  long: yup.number().nullable().defined(),
  notes: yup.string().nullable().defined(),
  assetCount: yup.number().defined(),
  assets: yup.array().defined(),
  spaces: yup.array().defined(),
  spaceCount: yup.number().defined(),
})

export const officeCompanySpaceModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  image: yup.string().defined().nullable(),
  notes: yup.string().defined().nullable(),
  address: yup.string().defined(),
  lat: yup.number().defined().nullable(),
  long: yup.number().defined().nullable(),
  countryId: yup.string().defined(),
  companyId: yup.string().defined(),
  cityId: yup.string().defined().nullable(),
  deletedAt: yup.string().nullable().defined(),
})

export const officesModelSchema = yup.array().of(officeModelSchema.defined()).defined()

export type OfficeModel = yup.InferType<typeof officeModelSchema>
export type OfficesModel = yup.InferType<typeof officesModelSchema>
export type OfficeCompanySpaceModel = yup.InferType<typeof officeCompanySpaceModelSchema>
export type OfficeCompanyModel = yup.InferType<typeof officeCompanyModelSchema>
export type OfficeCompanyUpdateModel = yup.InferType<typeof officeCompanyUpdateModelSchema>
