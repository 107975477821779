import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Separator } from '@dots/uikit'
import { create, useModal } from '@ebay/nice-modal-react'
import {
  adaptDeliveryCommercialOfferFormValuesToOffer,
  DeliveryCommercialOfferMutationForm,
  DeliveryCommercialOfferMutationFormValues,
  useDeliveryCommercialOfferForm,
} from '@entities/delivery/delivery-commercial-offer'
import {
  CreateCommercialOfferRequest,
  useCreateCommercialOfferRequest,
  useGetOrderLastCommentRequest,
} from '@share/hooks/api'

import {
  ADD_DELIVERY_ORDER_COMMERCIAL_OFFER_DIALOG_ID,
  AddDeliveryOrderCommercialOfferDialogProps,
} from '../../libs'

const AddDeliveryOrderCommercialOfferDialogRoot: React.FC<
  AddDeliveryOrderCommercialOfferDialogProps
> = ({ orderId, onAfterSave, addVendorSlot }) => {
  const { t } = useTranslation()
  const modalMethods = useModal(ADD_DELIVERY_ORDER_COMMERCIAL_OFFER_DIALOG_ID)
  const createDeliveryOrderCommercialOffer = useCreateCommercialOfferRequest()
  const getDeliveryOrderLastCommentRequest = useGetOrderLastCommentRequest({ id: orderId })

  const { control, handleSubmit } = useDeliveryCommercialOfferForm()

  const onSuccess = useCallback(
    async () => onAfterSave().then(modalMethods.remove),
    [modalMethods.remove, onAfterSave],
  )

  const onSubmit = useCallback(
    async (formValues: DeliveryCommercialOfferMutationFormValues) => {
      const requestData = {
        orderId,
        ...adaptDeliveryCommercialOfferFormValuesToOffer(formValues),
      } as unknown as CreateCommercialOfferRequest

      await createDeliveryOrderCommercialOffer.mutateAsync(requestData, { onSuccess })
    },
    [createDeliveryOrderCommercialOffer, onSuccess, orderId],
  )

  return (
    <Dialog.Root open={modalMethods.visible} onOpenChange={modalMethods.remove}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Add Delivery Order Commercial Offer</Dialog.Title>
        </Dialog.Header>
        <Separator />

        <Dialog.Body loading={getDeliveryOrderLastCommentRequest.isLoading} noPadding={false}>
          <DeliveryCommercialOfferMutationForm
            control={control}
            addVendorSlot={addVendorSlot}
            lastComment={getDeliveryOrderLastCommentRequest.data?.lastComment}
          />
        </Dialog.Body>
        <Separator />

        <Dialog.Footer position='end'>
          <Button
            onClick={handleSubmit(onSubmit)}
            loading={createDeliveryOrderCommercialOffer.isLoading}
          >
            {t('actions.save-changes')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export const AddDeliveryOrderCommercialOfferDialog = create(
  AddDeliveryOrderCommercialOfferDialogRoot,
)
