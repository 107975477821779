import { components, OptionProps } from 'react-select'

import { AssignPreview } from '../../assign-preview'
import { SelectOption } from '../types'

export const PreviewOption: React.FC<OptionProps<SelectOption>> = (props) => (
  <components.Option {...props}>
    <AssignPreview
      image={props.data.image}
      title={props.data.label}
      subtitle={props.data.address}
      type={props.data.type}
    />
  </components.Option>
)
