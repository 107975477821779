import { Slide, ToastContainer } from 'react-toastify'
import CloseIcon from '@assets/icons/close.svg'
import { Icon } from '@share/components/ui'

import s from './hint-container.module.scss'

export const HintContainer = () => (
  <ToastContainer
    position='top-right'
    autoClose={5000}
    hideProgressBar
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    theme='light'
    className={s.container}
    toastClassName={s.root}
    bodyClassName={s.body}
    icon={false}
    closeButton={<Icon icon={CloseIcon} />}
    transition={Slide}
  />
)
