import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { OrderActionDialog, OrderBox, OrderUpdateStatusFormsValues } from '@entities/order'
import { OrderCancelForm } from '@features/order/order-cancel/ui/molecules'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, MessageBox, toastSuccess } from '@share/components/ui'
import { API_TAGS } from '@share/constants'
import { queryClient } from '@share/helpers'
import { UpdatePurchaseOrderStatusRequest, useUpdatePurchaseOrderStatus } from '@share/hooks/api'

import {
  CANCELED_REASONS,
  ORDER_CANCEL_MODAL_ID,
  OrderCancelDialogProps,
  orderCancelValidationSchema,
} from '../../libs'

export const OrderCancelDialog = NiceModal.create<OrderCancelDialogProps>(
  ({ orderId, orderTitle, orderBreadcrumbs, lastComment }) => {
    const { t } = useTranslation()
    const modalMethods = useModal(ORDER_CANCEL_MODAL_ID)

    const { control, handleSubmit } = useForm<OrderUpdateStatusFormsValues>({
      defaultValues: { cancelReason: '', comment: { keepPrevious: false } },
      resolver: yupResolver(orderCancelValidationSchema(CANCELED_REASONS)),
    })

    const updateOrderStatus = useUpdatePurchaseOrderStatus()

    const onCancelOrder = useCallback(
      async (formValues: OrderUpdateStatusFormsValues) => {
        const requestData: UpdatePurchaseOrderStatusRequest = {
          orderId,
          requestData: {
            status: 'CANCELED',
            cancelReason: formValues.cancelReason,
            comment: formValues.comment,
          },
        }

        await updateOrderStatus.mutateAsync(requestData, {
          onSuccess: async () => {
            toastSuccess(t('orders.modals.cancel.toast-success', { returnObjects: true }))

            await queryClient.invalidateQueries([API_TAGS.orders.root.table])
            modalMethods.remove()
          },
        })
      },
      [modalMethods, orderId, t, updateOrderStatus],
    )

    const SlotFooter = useMemo(
      () => (
        <Dialog.Footer
          buttonOkProps={{
            children: t('orders.modals.cancel.actions.submit'),
            loading: updateOrderStatus.isLoading,
            onClick: handleSubmit(onCancelOrder),
          }}
        />
      ),
      [updateOrderStatus.isLoading, t, handleSubmit, onCancelOrder],
    )

    return (
      <OrderActionDialog
        open={modalMethods.visible}
        title={t('orders.modals.cancel.title')}
        onOpenChange={modalMethods.remove}
        withNoScroll
        slotFooter={SlotFooter}
        slotMessage={
          <MessageBox type='error' title={t('orders.modals.cancel.message-box.title')} />
        }
        slotSubheader={<OrderBox title={orderTitle} breadcrumbs={orderBreadcrumbs} />}
      >
        <OrderCancelForm control={control} reasons={CANCELED_REASONS} lastComment={lastComment} />
      </OrderActionDialog>
    )
  },
)
