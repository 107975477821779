/* eslint-disable import/no-cycle */
import * as yup from 'yup'

import {
  cityModelSchema,
  countryModelSchema,
  regionModelSchema,
  stateModelSchema,
} from '../country'
import { deliveryProofsSchema } from '../delivery/delivery-proofs.schemas'
import { officeModelSchema } from '../office'
import {
  possiblePurchaseOrderDeliveryInfoAssigneeType,
  PurchaseOrderDeliveryInfoAssigneeType,
} from '../purchase/purchase-order-assets-allocation.schemas'
import { staffModelSchema } from '../staff'
import { warehouseModelSchema } from '../warehouse'

export const orderDeliveryModelSchema = yup.object({
  addressLine1: yup.string().nullable().defined(),
  addressLine2: yup.string().nullable().defined(),
  city: cityModelSchema.nullable().defined(),
  cityId: yup.string().nullable().defined(),
  country: countryModelSchema.defined(),
  countryId: yup.string().defined(),
  dateEnd: yup.string().nullable().defined(),
  dateStart: yup.string().nullable().defined(),
  deliveryStartDate: yup.string().nullable().defined(),
  deliveryEndDate: yup.string().nullable().defined(),
  actualEta: yup.tuple([yup.string().defined(), yup.string().optional()]).nullable().defined(),
  id: yup.string().required().defined(),
  office: officeModelSchema.nullable().defined(),
  officeId: yup.string().nullable().defined(),
  orderId: yup.string().required().defined(),
  region: regionModelSchema.defined().nullable(),
  staff: staffModelSchema.defined().nullable(),
  staffId: yup.string().nullable().defined(),
  state: stateModelSchema.defined().nullable(),
  stateId: yup.string().nullable().defined(),
  updateEntity: yup.boolean().defined(),
  warehouse: warehouseModelSchema.nullable().defined(),
  warehouseId: yup.string().nullable().defined(),
  zipCode: yup.number().nullable().defined(),
  notes: yup.string().nullable().defined(),
  price: yup.number().nullable().defined(),
  trackingNumber: yup.string().nullable().defined(),
  trackingLink: yup.string().nullable().defined(),
  proofsOfDelivery: deliveryProofsSchema.defined(),
  assigneeType: yup
    .mixed<PurchaseOrderDeliveryInfoAssigneeType>()
    .oneOf(possiblePurchaseOrderDeliveryInfoAssigneeType)
    .defined(),
})

export const orderDeliveriesModelSchema = yup.array().of(orderDeliveryModelSchema).defined()

export type OrderDeliveryModel = yup.InferType<typeof orderDeliveryModelSchema>
export type OrderDeliveriesModel = yup.InferType<typeof orderDeliveriesModelSchema>
