import * as yup from 'yup'

import { CompanyAdmin } from '../../@types/company-admin'
import { countryModelSchema } from '../country'

export const possibleCompanyStatuses = ['ACTIVE', 'INACTIVE'] as const
export const possibleCompanyPaymentTermsDays = [
  'C_O_D',
  'PREPAID',
  'NET_10_DAYS',
  'NET_15_DAYS',
  'NET_20_DAYS',
  'NET_30_DAYS',
  'NET_45_DAYS',
  '30_DAYS',
] as const

export type CompanyStatuses = (typeof possibleCompanyStatuses)[number]
export type CompanyPaymentTermsDays = (typeof possibleCompanyPaymentTermsDays)[number]

export const companyModelSchema = yup.object({
  id: yup.string().defined(),
  shortId: yup.string().defined(),
  status: yup.mixed<CompanyStatuses>().oneOf(possibleCompanyStatuses).defined(),
  contactPersonName: yup.string().defined(),
  contactPersonEmail: yup.string().defined(),
  contactPersonPhone: yup.string().defined(),
  address: yup.string().defined(),
  website: yup.string().defined(),
  domain: yup.string().defined(),
  dotsAccountInfo: yup.string().defined(),
  notes: yup.string().defined(),
  creditLimit: yup.number().defined(),
  paymentTermsDaysCount: yup
    .mixed<CompanyPaymentTermsDays>()
    .oneOf(possibleCompanyPaymentTermsDays)
    .defined(),
  currencyId: yup.string().defined(),
  country: countryModelSchema.defined(),
  name: yup.string().defined(),
  scale: yup.string().defined(),
  activity: yup.string().defined(),
  createdAt: yup.date().defined(),
  updatedAt: yup.date().defined(),
  deletedAt: yup.date().nullable().defined(),
  margin: yup.number().nullable().defined(),
  orderAutoApprovalDays: yup.number().nullable().defined(),
  appleDepId: yup.string().nullable().defined(),
  isPoNeeded: yup.boolean().defined(),
  salesAdmin: yup.mixed<CompanyAdmin>().defined().nullable(),
  supportAdmin: yup.mixed<CompanyAdmin>().defined().nullable(),
})

export const companiesSchema = yup.array(companyModelSchema)

export type CompanyModel = yup.InferType<typeof companyModelSchema>
export type CompaniesModel = yup.InferType<typeof companiesSchema>
export type CompanyMutationRequest = Omit<
  CompanyModel,
  | 'id'
  | 'shortId'
  | 'country'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
  | 'salesAdmin'
  | 'supportAdmin'
> & {
  salesAdminId: string
  supportAdminId: string
}
