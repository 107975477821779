import { forwardRef, useCallback, useEffect, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import cn from 'classnames'

import { DropzoneError, DropzoneErrorProps } from './dropzone-error'
import { DropzoneInitial, DropzoneInitialProps } from './dropzone-initial'
import { DropzonePreview, DropzonePreviewProps } from './dropzone-preview'

import s from './dropzone.module.scss'

type OmitDropzoneError = Omit<DropzoneErrorProps, 'onRetry'>
type OmitDropzoneInitial = Omit<DropzoneInitialProps, 'onClick'>

export interface DropzoneProps extends OmitDropzoneError, OmitDropzoneInitial {
  showError?: boolean
  disabled?: boolean
  value?: DropzonePreviewProps['src']
  onEdit?: DropzonePreviewProps['onEdit']
  deleting?: DropzonePreviewProps['loading']
  accept?: DropzoneOptions['accept']
  onChange: (file: File) => void
}

export const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>((props, ref) => {
  const {
    title,
    subtitle,
    value,
    loading,
    accept = { 'image/*': [] },
    disabled,
    icon,
    showError,
    deleting,
    onChange,
    onCancel,
    onEdit,
  } = props
  const [hasPreview, setHasPreview] = useState(Boolean(value))

  const {
    getRootProps,
    getInputProps,
    open: onOpen,
    isDragAccept,
  } = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    disabled: disabled || hasPreview,
    onDropAccepted: (acceptedFiles) => onChange(acceptedFiles[0]),
  })

  const handleOnEdit = useCallback(() => {
    if (onEdit) onEdit()

    setHasPreview(false)
  }, [onEdit])

  useEffect(() => {
    setHasPreview(Boolean(value))
  }, [value])

  return (
    <div {...getRootProps()} className={cn(s.root, { [s.focused]: isDragAccept })}>
      <input {...getInputProps()} ref={ref} />
      {showError && <DropzoneError loading={loading} onRetry={onOpen} onCancel={onCancel} />}
      {hasPreview && <DropzonePreview src={value} loading={deleting} onEdit={handleOnEdit} />}

      {!hasPreview && !showError && (
        <DropzoneInitial
          loading={loading}
          title={title}
          subtitle={subtitle}
          icon={icon}
          disabled={disabled}
          onClick={onOpen}
        />
      )}
    </div>
  )
})

Dropzone.displayName = 'Dropzone'
