import * as yup from 'yup'

import { productAdditionalServicesSchema, productModelSchema } from '../product'

export const cartProductSchema = yup.object({
  id: yup.string().defined(),
  product: productModelSchema.defined(),
  quantity: yup.number().defined(),
  additionalServices: productAdditionalServicesSchema.defined(),
  powerSupplyType: yup.string().defined().nullable(),
  keyboardLanguage: yup.string().defined().nullable(),
})

export const cartProductsSchema = yup.array().of(cartProductSchema.defined())

export type CartProductModel = yup.InferType<typeof cartProductSchema>
export type CartProductsModel = yup.InferType<typeof cartProductsSchema>
