import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import FileIcon from '@assets/icons/large/file.svg'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Alert, Button, Icon, IconHolder, Stack, Typography } from '@share/components/ui'
import { ROUTES } from '@share/constants'
import { asHtml } from '@share/utils'

import { ORDER_DISPUTE_SUCCESS_ALERT_ID } from '../../../lib'

export const OrderDisputeSuccessAlert = NiceModal.create(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const modalMethods = useModal(ORDER_DISPUTE_SUCCESS_ALERT_ID)

  const onOkClick = useCallback(() => {
    modalMethods.remove()
    navigate(ROUTES.ORDERS.root)
  }, [modalMethods, navigate])

  return (
    <Alert size='large' open={modalMethods.visible} onOpenChange={modalMethods.remove}>
      <Stack justify='center' align='center' gap={24}>
        <IconHolder color='forestGreen' size='xlarge'>
          <Icon icon={FileIcon} size='large' />
        </IconHolder>

        <Stack justify='center' align='center' gap={8}>
          <Typography variant='h2'>{t('orders.modals.dispute-success.title')}</Typography>
          <Typography variant='b2' align='center' className='fcSecondary'>
            {asHtml(t('orders.modals.dispute-success.subtitle'))}
          </Typography>
        </Stack>

        <Button onClick={onOkClick}>{t('orders.modals.dispute-success.action')}</Button>
      </Stack>
    </Alert>
  )
})
