import * as yup from 'yup'

export const HRIntegrationModelSchema = yup.object({
  name: yup.string().defined(),
  description: yup.string().defined().nullable(),
  lastSync: yup.string().defined().nullable(),
  countOfSyncedStaff: yup.number().defined().nullable(),
  available: yup.boolean().defined(),
  image: yup.string().defined(),
})

export type HRIntegrationModel = yup.InferType<typeof HRIntegrationModelSchema>
