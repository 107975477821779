import { forwardRef } from 'react'
import DotIcon from '@assets/icons/dot.svg'
import { Nullable } from '@share/@types'
import cn from 'classnames'

import { Icon, IconProps } from '../icon'
import { Loader } from '../loader'

import s from './action-icon.module.scss'

type Variants = 'filled' | 'subtle' | 'transparent'
type Size = 'small' | 'medium' | 'large'
type Color = 'forestGreen' | 'lightGreen'
type Foreground = 'white' | 'green'

export interface ActionIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variants
  color?: Color
  size?: Size
  loading?: boolean
  icon: IconProps['icon']
  iconSize?: IconProps['size']
  foreground?: Nullable<Foreground>
  hasNotice?: boolean
}

export const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>((props, buttonRef) => {
  const {
    className,
    children,
    loading,
    disabled,
    size = 'medium',
    iconSize = 'medium',
    color = 'forestGreen',
    variant = 'filled',
    foreground = 'white',
    icon: ChildIcon,
    type = 'button',
    hasNotice,
    ...rest
  } = props

  const rootClassName = cn(
    s.root,
    {
      [s[size]]: size,
      [s[variant]]: variant,
      [s[color]]: color,
      [s.foregroundWhite]: foreground === 'white',
      [s.foregroundGreen]: foreground === 'green',
      [s.loading]: loading,
      [s.disabled]: disabled,
    },
    className,
  )

  return (
    <button type={type} ref={buttonRef} className={rootClassName} disabled={disabled} {...rest}>
      <span className={s.inner}>
        {loading ? <Loader size={iconSize} /> : <Icon size={iconSize} icon={ChildIcon} />}
        {!loading && hasNotice && <DotIcon className={s.notice} />}
      </span>
    </button>
  )
})

ActionIcon.displayName = 'ActionIcon'
