import { forwardRef } from 'react'
import CheckboxIcon from '@assets/icons/checkbox.svg'
import CheckboxActiveIcon from '@assets/icons/checkbox-active.svg'
import CheckboxIndeterminateIcon from '@assets/icons/checkbox-indeterminate.svg'
import { CheckboxProps as RadixCheckboxProps, Indicator, Root } from '@radix-ui/react-checkbox'
import { ClassName } from '@share/@types'
import cn from 'classnames'

import { Icon } from '../icon/icon'
import { Typography } from '../typography'

import s from './checkbox.module.scss'

export interface CheckboxProps extends ClassName {
  label?: React.ReactNode
  invalid?: boolean
  disabled?: RadixCheckboxProps['disabled']
  checked?: RadixCheckboxProps['checked']
  value: RadixCheckboxProps['value']
  onCheckedChange: RadixCheckboxProps['onCheckedChange']
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ className, label, checked, invalid, ...rest }, ref) => (
    <Root
      {...rest}
      checked={checked}
      ref={ref}
      className={cn(s.root, { [s.invalid]: invalid }, className)}
    >
      <div className={s.element}>
        {!checked && <Icon size='medium' icon={CheckboxIcon} />}
        {checked && (
          <Indicator>
            {checked === 'indeterminate' && <Icon size='medium' icon={CheckboxIndeterminateIcon} />}
            {checked === true && <Icon size='medium' icon={CheckboxActiveIcon} />}
          </Indicator>
        )}
      </div>

      {label && (
        <Typography variant='b2' className={s.label}>
          {label}
        </Typography>
      )}
    </Root>
  ),
)

Checkbox.displayName = 'Checkbox'
