import * as yup from 'yup'

export const orderPONumberModelSchema = yup
  .object()
  .shape({
    purchaseOrderNumber: yup.string().nullable().defined(),
    purchaseQuote: yup
      .object({
        id: yup.string().defined(),
        name: yup.string().defined(),
        url: yup.string().defined(),
        size: yup.string().defined().optional(),
        orderId: yup.string().defined(),
      })
      .nullable()
      .defined(),
  })
  .noUnknown()

export type OrderPONumberModel = yup.InferType<typeof orderPONumberModelSchema>
