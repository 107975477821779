import * as yup from 'yup'

import { productModelSchema } from '../product'

import { orderAlternativeProductsModelSchema } from './order-alternative-product.schemas'
import { orderProductAdditionalServiceSchema } from './order-product-additional-service.schemas'

export const orderProductModelSchema = yup.object({
  id: yup.string().defined(),
  quantity: yup.number().defined(),
  additionalServices: yup.array(orderProductAdditionalServiceSchema).defined(),
  product: productModelSchema.defined(),
  totalPrice: yup.number().defined(),
  createdAt: yup.string().defined(),
  deletedAt: yup.string().defined().nullable(),
  orderId: yup.string().defined(),
  productId: yup.string().defined(),
  updatedAt: yup.string().defined(),
  powerSupplyType: yup.string().defined().nullable(),
  keyboardLanguage: yup.string().defined().nullable(),
  productCodeDpn: yup.string().defined().nullable(),
  productCodeDpnId: yup.string().defined().nullable(),
  notes: yup.string().defined().nullable(),
  alternativeOrderProducts: orderAlternativeProductsModelSchema.defined(),
  serialNumbers: yup.array().of(yup.string()).defined(),
  packageIndex: yup.number().defined().nullable(),
  vendorId: yup.string().defined().nullable(),
})
export const orderProductsModelSchema = yup.array(orderProductModelSchema).defined()
export type OrderProductModel = yup.InferType<typeof orderProductModelSchema>
export type OrderProductsModel = yup.InferType<typeof orderProductsModelSchema>
