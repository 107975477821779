import { forwardRef } from 'react'
import cn from 'classnames'

import s from './body-item.module.scss'

interface BodyItemProps {
  className?: string
  children: React.ReactNode
  align?: 'left' | 'center' | 'right'
  valign?: 'top' | 'middle' | 'bottom'
  size?: number
  minSize?: number
  maxSize?: number
  asButton?: boolean
}

export const BodyItem: React.FC<BodyItemProps> = forwardRef<HTMLDivElement, BodyItemProps>(
  ({ className, children, size, align, valign = 'middle', asButton, maxSize, minSize }, ref) => {
    const width = size ? `${size}px` : undefined
    const minWidth = minSize ? `${minSize}px` : undefined
    const maxWidth = maxSize ? `${maxSize}px` : undefined

    return (
      <div
        ref={ref}
        className={cn(
          s.root,
          { [s.right]: align === 'right', [s.center]: align === 'center' },
          { [s.middle]: valign === 'middle', [s.bottom]: valign === 'bottom' },
          className,
        )}
        style={{
          width: minWidth ? undefined : width,
          minWidth: minWidth || width,
          maxWidth: maxWidth || asButton ? width : undefined,
        }}
      >
        {children}
      </div>
    )
  },
)

BodyItem.displayName = 'BodyItem'
