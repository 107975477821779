import cn from 'classnames'

type FormProps = React.FormHTMLAttributes<HTMLFormElement>

export const Root: React.FC<FormProps> = ({ children, className = '', ...rest }) => (
  <form className={cn(className)} autoComplete='off' onSubmit={(e) => e.preventDefault()} {...rest}>
    {children}
  </form>
)

export * from './fieldset'
export * from './legend'
export * from './content'
export * from './divider'
export * from './row'
export * from './label-col'
export * from './control-col'
export * from './container'
