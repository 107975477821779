import { create, useModal } from '@ebay/nice-modal-react'
import { AssetBox, Dialog } from '@share/components/ui'

import {
  SELECTED_DELIVERY_ASSETS_DIALOG_ID,
  SelectedDeliveryAssetsDialogProps,
} from '../../../libs'

import s from './selected-delivery-assets-dialog.module.scss'

export const SelectedDeliveryAssetsDialog = create<SelectedDeliveryAssetsDialogProps>(
  ({ title, children, assetsInfo, buttonOkProps, buttonCancelProps }) => {
    const modalMethods = useModal(SELECTED_DELIVERY_ASSETS_DIALOG_ID)

    return (
      <Dialog.Root open={modalMethods.visible} onOpenChange={modalMethods.remove}>
        <Dialog.Container size='xlarge'>
          <Dialog.Header title={title}>
            <Dialog.Subheader>
              <AssetBox {...assetsInfo} titleClassname={s.title} />
            </Dialog.Subheader>
          </Dialog.Header>

          <Dialog.Content>{children}</Dialog.Content>

          <Dialog.Footer buttonOkProps={buttonOkProps} buttonCancelProps={buttonCancelProps} />
        </Dialog.Container>
      </Dialog.Root>
    )
  },
)
