import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@assets/icons/delete.svg'
import UploadIcon from '@assets/icons/upload.svg'
import { FileUploadEntities } from '@share/@types'
import { useDeleteFile, useUploadFile } from '@share/hooks/api'

import { Button, ButtonUpload, Group } from '../../atoms'

import { FileUploadPreview } from './file-upload-preview'

export type FileUploadProps = {
  invalid?: boolean
  disabled?: boolean
  value?: string
  accept?: string
  entityType?: FileUploadEntities
  onChange: (file: string) => void
  onDeleteCallback?: () => void
}

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ value, entityType = 'asset', accept = 'image/*', onChange, onDeleteCallback }, ref) => {
    const { t } = useTranslation()
    const { isLoading, mutateAsync: uploadFile } = useUploadFile()
    const { isLoading: isDeleting, mutateAsync: deleteFile } = useDeleteFile()

    const onUpload = useCallback(
      async (file: File) => {
        const formData = new FormData()

        formData.append('entity', entityType)
        formData.append('file', file)

        const response = await uploadFile(formData)

        onChange(response.file)
      },
      [entityType, uploadFile, onChange],
    )

    const onDelete = useCallback(async () => {
      if (value) {
        await deleteFile({ url: value })
      }

      if (onDeleteCallback) {
        onDeleteCallback()
      }

      onChange('')
    }, [value, onDeleteCallback, onChange, deleteFile])

    const hasValue = Boolean(value)

    return (
      <Group gap={16} align='middle'>
        <FileUploadPreview src={value} entityType={entityType} />
        <ButtonUpload
          ref={ref}
          icon={UploadIcon}
          accept={accept}
          loading={isLoading}
          onChange={onUpload}
        >
          {t(`image-upload.${hasValue ? 'edit' : 'upload'}`)}
        </ButtonUpload>
        {hasValue && (
          <Button
            type='button'
            icon={DeleteIcon}
            variant='subtle'
            onClick={onDelete}
            loading={isDeleting}
          >
            {t('image-upload.delete')}
          </Button>
        )}
      </Group>
    )
  },
)

FileUpload.displayName = 'FileUpload'
