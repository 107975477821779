import { Link, LinkProps } from 'react-router-dom'
import { Nullable } from '@share/@types'
import cn from 'classnames'

import { Icon } from '../icon/icon'

import { BaseButtonProps } from './types'

import s from './button.module.scss'

export type ButtonLinkProps = Omit<BaseButtonProps, 'disabled'> &
  Omit<LinkProps, 'to'> & { to?: Nullable<string> }

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  className,
  children,
  loading = false,
  fullWidth,
  size = 'medium',
  iconSize = 'medium',
  variant = 'filled',
  color = '',
  icon,
  iconPosition = 'before',
  to,
  ...rest
}) => {
  const isDisabled = !to

  const rootClassName = cn(
    s.root,
    {
      [s[size]]: size,
      [s[variant]]: variant,
      [s[color]]: color,
      [s.fullWidth]: fullWidth,
      [s.loading]: loading,
      [s.disabled]: isDisabled,
    },
    className,
  )

  const Component = !isDisabled ? Link : 'span'

  return (
    <Component className={rootClassName} {...rest} to={to as string}>
      <span className={s.inner}>
        {icon && iconPosition === 'before' && (
          <Icon size={iconSize} className={s.icon} icon={icon} />
        )}
        <span className={s.label}>{children}</span>
        {icon && iconPosition === 'after' && (
          <Icon size={iconSize} className={s.icon} icon={icon} />
        )}
      </span>
    </Component>
  )
}

ButtonLink.displayName = 'ButtonLink'
