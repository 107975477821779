import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import NiceModal from '@ebay/nice-modal-react'
import { OrderActionDialog } from '@entities/order'
import { Dialog } from '@share/components/ui'

import { OrderDisputeDialogProps } from '../../libs'
import { useOrderDisputeController } from '../../model/use-order-dispute-controller'
import { OrderDisputeForm } from '../molecules/form/form'

const OrderDisputeRoot: React.FC<OrderDisputeDialogProps> = (props) => {
  const { t } = useTranslation()
  const controller = useOrderDisputeController(props)

  const SlotFooter = useMemo(
    () => (
      <Dialog.Footer
        buttonCancelProps={{
          variant: 'transparent',
          color: 'secondary',
          children: t('orders.modals.dispute.actions.cancel'),
          disabled: controller.disabled,
          onClick: controller.onCancel,
        }}
        buttonOkProps={{
          children: t('orders.modals.dispute.actions.submit'),
          loading: controller.isSubmitting,
          disabled: controller.disabled,
          onClick: controller.onSubmit,
        }}
      />
    ),
    [t, controller.disabled, controller.isSubmitting, controller.onCancel, controller.onSubmit],
  )

  return (
    <OrderActionDialog
      open={controller.modalVisible}
      slotFooter={!controller.preview && SlotFooter}
      onOpenChange={controller.onCancel}
      title={t('orders.modals.dispute.title')}
      loading={props.isLoading}
    >
      <OrderDisputeForm
        control={controller.formControl}
        disabled={controller.disabled}
        issuesList={controller.issuesList}
        lastComment={props.lastComment}
      />
    </OrderActionDialog>
  )
}

export const OrderDisputeDialog = NiceModal.create(OrderDisputeRoot)
