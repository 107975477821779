import * as yup from 'yup'

const possibleAssetsStatus = [
  'PENDING',
  'IN_USE',
  'IN_STORAGE',
  'MALFUNCTIONING',
  'IN_TRANSIT',
  'ARCHIVED',
] as const

export type AssetStatus = (typeof possibleAssetsStatus)[number]

export const assetStatusSchema = yup.mixed<AssetStatus>().defined().oneOf(possibleAssetsStatus)

export type AssetStatusType = yup.InferType<typeof assetStatusSchema>
